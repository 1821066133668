import React, { useEffect, useState } from "react";
import Card from "components/card";
import {
  MdFileDownload,
  MdArrowDropUp,
  MdArrowDropDown,
  MdMail,
} from "react-icons/md";
import { reportModal } from "models/wizard/wizard-modals";
import { fetchData, sendReportToMail, updatetDataById } from "api";
import { reports } from "api/collection_names";
import { GeneratePDF } from "components/generatePdf/generatePdf";
import { getUser, saveUser } from "auth/Authentication";
import AppSnackbar from "components/snakbar";
import {  SnackbarState } from "models/user-models";
import TextField from '@mui/material/TextField';

import { Autocomplete, Box, Button, Pagination } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import AlreadyLoggedInModal from "components/AlreadyLoggedInModal/AlreadyLoggedInModal";

const Report = () => {
  const [user, setUser] = React.useState(null);
  const [dropDownUsers, setDropDownUsers] = useState<any[]>([]);
  const [reportList, setReportList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [noReportFound, setNoReportFound] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<SnackbarState>({
    open: false,
    message: "",
    severity: "info",
  });

  const [filteredReports,setFilteredReports] = useState<any[]>([])
  const [localUser,setLocalUser] = useState(null)
  const [isExpired,setIsExpired] = useState(null)
  const [isChecked, setIsChecked] = useState(false);
  const [strictExpiry,setStrictExpiry] = useState(false)
  const navigate = useNavigate()
  // useEffect(() => {
  //   const isTokenExpired = () => {
  //       const expirationTime = localStorage.getItem('expirationTime');
  //       if (!expirationTime) return true;
  //       return new Date().getTime() > Number(expirationTime);
  //     };
  //     console.log("isTokenExpired++++++",isTokenExpired())
      
  //     if (isTokenExpired()) {
  //       localStorage.removeItem('expirationTime');
  //    navigate('/auth/sign-in'); 
  //     } 
  // }, [navigate]);

  const [sortedReports, setSortedReports] = useState<any[]>([]);

  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "ascending" | "descending";
  }>({
    key: "",
    direction: "ascending",
  });

  const [page, setPage] = useState(0);
  const rowsPerPage = 10; 

  const totalPages = Math.ceil(sortedReports.length / rowsPerPage);

  const handleNextPage = () => {
    setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1));
  };

  const handlePreviousPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const fetchReportList = async () => {
    try {
      setLoading(true);
      const user = getUser()
      const res = await fetchData(reports);
      if (res.success) {
      const limitedReports = res.data.sort((a:any, b:any) => new Date(b.added_on).getTime() - new Date(a.added_on).getTime()).slice(0,10)
        if(user && user?.type==="1"){
          setReportList(res.data);
          setSortedReports(res.data);
          setFilteredReports(res.data);
        }
        else{
         let reportList =  res?.data?.filter((reports:any)=>(reports.added_by==user._id))
         setReportList(reportList);
         setSortedReports(reportList);
         setFilteredReports(reportList);
        }
      } else {
        alert("No Users Found!");
        navigate("/auth/sign-in")
      }
      setLoading(false);
      
    } catch (error:any) {
      const alreadyLoggedIn = error.message.endsWith("401")
      if(alreadyLoggedIn){
          setSnackbar({ open: true, message: "logged in from some other device", severity: 'error' });
          setTimeout(()=>{
              navigate('/auth/sign-in'); 
          },3000)
      }
      setLoading(false);
  }
  };

  const handleSort = (key: keyof reportModal | string) => {
    let direction: "ascending" | "descending" = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    const sorted = [...sortedReports].sort((a, b) => {
      const valueA = key.includes(".")
        ? a[key.split(".")[0]][key.split(".")[1]]
        : a[key];
      const valueB = key.includes(".")
        ? b[key.split(".")[0]][key.split(".")[1]]
        : b[key];

      const firstCharA =
        typeof valueA === "string" ? valueA.charAt(0).toLowerCase() : "";
      const firstCharB =
        typeof valueB === "string" ? valueB.charAt(0).toLowerCase() : "";

      if (firstCharA < firstCharB) {
        return direction === "ascending" ? -1 : 1;
      }
      if (firstCharA > firstCharB) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setSortedReports(sorted);
    setSortConfig({ key, direction });
  };

  const handleSortByDate = () => {
    let direction: "ascending" | "descending" = "ascending";

    if (sortConfig.key === "date" && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    const sorted = [...sortedReports].sort((a, b) => {
      const dateA = new Date(a.added_on);
      const dateB = new Date(b.added_on);
      return direction === "ascending"
        ? dateA.getTime() - dateB.getTime()
        : dateB.getTime() - dateA.getTime();
    });

    setSortedReports(sorted);
    setSortConfig({ key: "date", direction });
  };

  function handleReportView(report: reportModal) {
    const printWindow = window.open("", "_blank");
    printWindow?.document.write(`
      <html>
      <head>
        <title>Report</title>
        <style>
          body {
            font-family: DM Sans, sans-serif;
            margin: 20px;
          }
          h1 {
            text-align: center;
          }
          .section {
            margin-bottom: 20px;
          }
          .section-title {
            font-weight: bold;
          }
          .section-content {
            margin-left: 20px;
          }
        </style>
      </head>
      <body>
        <h1>Patient Report</h1>
        <div class="section">
          <div class="section-title">Complaints:</div>
          <div class="section-content">${
            report.compliants && report.compliants.length > 0
              ? `Patient with History of ${report.compliants[0].description} for ${report.duration} ${report.durationType}`
              : ""
          }</div>
          <div class="section-content">${
            report.subComplaints && report.subComplaints.length > 0
              ? report.subComplaints
                  .map((sub_complaint, index) => {
                    const isLast = index === report.subComplaints.length - 1;
                    const separator = isLast
                      ? ""
                      : index === report.subComplaints.length - 2
                      ? " and "
                      : ", ";
                    if (index === 0) {
                      return (`\nPatient also has ${sub_complaint.description}${separator} <br /> <div >SubComplaintRemark: ${report.subComplaintRemark}</div>`);
                    } else {
                      return `${sub_complaint.description}${separator}`;
                    }
                  })
                  .join("")
              : ""
          }
          </div>
          <br /> <div>Remarks: ${report.complaintRemark ?? ""}</div>
        </div>
        <div class="section">
          <div class="section-title">On Examination:</div>
          <div class="section-content">${
            report.examinations && report.examinations.length > 0
              ? report.examinations
                  .map((item) => {
                    return (
                        `
                        ${item.description}${item.value && item.value.inputValue && item.value.unitValue ? `: ${item.value.inputValue} ${item.value.unitValue}\n` : ''}
                        ${item.scroll_downs && item.scroll_downs.length ? ": " : ""}
                        ${item.scroll_downs ? item.scroll_downs.map((scroll_down, index) => {
                            if (index < item.scroll_downs.length - 1) {
                                return `${scroll_down}, `;
                            } else {
                                return scroll_down;
                            }
                        }).join('') : ''} 
                        <br />
                        `
                    )
                    
        })
                  .join("")
              : ""
          }</div>
          <br /> <div>Remarks: ${report.examinationRemark ?? ""}</div>
        </div>
        <div class="section">
          <div class="section-title">Impression:</div>
          <div class="section-content">${
            report.impressions && report.impressions.length > 0
              ? report.impressions
                  .map((item) => item.description + "<br>")
                  .join("")
              : ""
          }</div>
          <br /> <div>Remarks: ${report.impressionRemark ?? ""}</div>
        </div>
        <div class="section">
          <div class="section-title">Treatment Plan:</div>
          <div class="section-content">${
            report.treatment_plans && report.treatment_plans.length > 0
              ? report.treatment_plans
                  .map((item) => item.description + "<br>")
                  .join("")
              : ""
          }</div>
        </div>
      </body>
      </html>
    `);
    printWindow?.focus();
  }
  

 
  // async function handleDownloadButton(userReport: reportModal) {
  //     const styles = StyleSheet.create({
  //         h1: {
  //             textAlign: 'center',
  //         },
  //         page: {
  //             flexDirection: 'column',
  //             backgroundColor: '#ffffff',
  //             padding: 20,
  //         },
  //         section: {
  //             marginBottom: 10,
  //         },
  //         sectionTitle: {
  //             fontSize: 16,
  //             fontWeight: 'bold',
  //             marginBottom: 5,
  //         },
  //         sectionContent: {
  //             fontSize: 12,
  //         },
  //     });

  //     const ReportDocument = (
  //         <Document>
  //             <Page size="A4" style={styles.page}>
  //                 <View style={styles.section}>
  //                     <Text style={styles.h1}>Patient Report</Text>
  //                 </View>
  //                 {/* <View style={styles.section}>
  //                     <Text style={styles.sectionTitle}>User:</Text>
  //                     <Text style={styles.sectionContent}>First Name: {userReport.user?.fname}</Text>
  //                     <Text style={styles.sectionContent}>Last Name: {userReport.user?.lname}</Text>
  //                     <Text style={styles.sectionContent}>Email: {userReport.user?.email}</Text>
  //                     <Text style={styles.sectionContent}>Date of Birth: {userReport.user.dob ? userReport.user.dob.toString() : ""}</Text>
  //                 </View> */}
  //                 <View style={styles.section}>
  //                     <Text style={styles.sectionTitle}>Complaints:</Text>
  //                     {userReport.compliants &&
  //                         <Text style={styles.sectionContent} key={userReport.compliants[0]._id}>{`Patient with History of ${userReport.compliants[0].description} for ${userReport.duration} ${userReport.durationType}`}</Text>
  //                     }
  //                     {userReport.subComplaints && userReport.subComplaints.length > 0 &&
  //                         <Text style={styles.sectionContent} key={userReport.compliants[0]._id}>{userReport.subComplaints?.map((sub_complaint, index) => {
  //                             const isLast = index === userReport.subComplaints.length - 1;
  //                             const separator = isLast ? '' : (index === userReport.subComplaints.length - 2 ? ' and ' : ', ');
  //                             if (index === 0) {
  //                                 return `Patient also has ${sub_complaint.description}${separator}`;
  //                             }
  //                             else {
  //                                 return `${sub_complaint.description}${separator}`;
  //                             }
  //                         }).join("")}</Text>
  //                     }
  //                 </View>
  //                 {/* <View style={styles.section}>
  //                     <Text style={styles.sectionTitle}>Duration:</Text>
  //                     {userReport.duration?.map(item => (
  //                         <Text style={styles.sectionContent} key={item.id}>{item.description}</Text>
  //                     ))}
  //                 </View> */}
  //                 <View style={styles.section}>
  //                     <Text style={styles.sectionTitle}>On Examination:</Text>
  //                     {userReport.examinations?.map(item => (
  //                         <Text style={styles.sectionContent} key={item._id}>{`${item.description}${item.scroll_downs.length ? ": " : ""}${item.scroll_downs.map((scroll_down, index) => {
  //                             if (index < item.scroll_downs.length - 1) {
  //                                 return (
  //                                     scroll_down + ", "
  //                                 )
  //                             }
  //                             else {
  //                                 return (
  //                                     scroll_down
  //                                 )
  //                             }
  //                         }).join('')}\n`}</Text>
  //                     ))}
  //                 </View>
  //                 <View style={styles.section}>
  //                     <Text style={styles.sectionTitle}>Impression:</Text>
  //                     {userReport.impressions?.map(item => (
  //                         <Text style={styles.sectionContent} key={item._id}>{item.description}</Text>
  //                     ))}
  //                 </View>
  //                 <View style={styles.section}>
  //                     <Text style={styles.sectionTitle}>Treatment Plan:</Text>
  //                     {userReport.treatment_plans?.map(item => (
  //                         <Text style={styles.sectionContent} key={item._id}>{item.description}</Text>
  //                     ))}
  //                 </View>
  //             </Page>
  //         </Document>
  //     );
  //     const pdfBlob = await pdf(ReportDocument).toBlob();
  //     const url = URL.createObjectURL(pdfBlob);

  //     const a = document.createElement('a');
  //     a.href = url;
  //     a.download = 'report.pdf';
  //     document.body.appendChild(a);
  //     a.click();
  //     document.body.removeChild(a);
  //     URL.revokeObjectURL(url);
  // }
  const hasTrialExpired = (expirationTimeUTC:any) => {
    const currentTimeUTC = new Date().toISOString(); // Get current time in UTC
    return currentTimeUTC > expirationTimeUTC; // Compare current time with expiration time
  };
  async function handleDownloadButton(userReport: reportModal) {

    const user = getUser() 
    const hasTrialExpired = (expirationTimeUTC:any) => {
        const currentTimeUTC = new Date().toISOString(); // Get current time in UTC
        return currentTimeUTC > expirationTimeUTC; // Compare current time with expiration time
      };
      
     
      
    
    if (user.type === "2" && !isChecked && hasTrialExpired(user.trial_expiry) && !user.hasOwnProperty("expiryTime")) {
      // setIsChecked(true); 
        // alert("Your trial has expired. Please switch to the subscription mode.");
        setSnackbar({ open: true, message: "Your trial has expired. Please switch to the subscription mode.", severity: 'error' });
        return;
    }





    
    if(user.type === "2" && (isExpired || localUser.package_name === null)){
      // localStorage.setItem("user",JSON.stringify())
      
      setSnackbar({ open: true, message: "Your trial has ended. Please renew your subscription.", severity: 'error' });
      await    updatetDataById("Users",{
          package_name: null,
          phone_number:null,
          session_id:null,
          expiryTime:null,
          randomCode:null,
          subscriptionOwnerId:null,
          subscriptionOwner:null,
          
      },localUser._id)
      const updatedUser = localUser

updatedUser.package_name = null;
updatedUser.phone_number =null;
updatedUser.session_id =null;
updatedUser.expiryTime =null;
updatedUser.subscription="inactive";
updatedUser.subscriptionOwner=false;
updatedUser.subscriptionOwnerId=null;
updatedUser.randomCode=null;



localStorage.setItem('user', JSON.stringify(updatedUser));
return
  }
    const pdfBlob = await GeneratePDF(userReport);
    const url = URL.createObjectURL(pdfBlob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "report.pdf";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }
  React.useEffect(()=>{
    const userr = getUser()
 const expiredTrial =   hasTrialExpired(userr.trial_expiry)
 if(expiredTrial){
   setStrictExpiry(true)

 }
  },[navigate])
  async function handleSendPDFButton(userReport: reportModal) {
    const user = getUser() 
    const hasTrialExpired = (expirationTimeUTC:any) => {
        const currentTimeUTC = new Date().toISOString(); // Get current time in UTC
        return currentTimeUTC > expirationTimeUTC; // Compare current time with expiration time
      };
      
     
      
    
    if (user.type === "2" && !isChecked && hasTrialExpired(user.trial_expiry) && !user.hasOwnProperty("expiryTime")) {
      // setIsChecked(true); 
        // alert("Your trial has expired. Please switch to the subscription mode.");
        setSnackbar({ open: true, message: "Your trial has expired. Please switch to the subscription mode.", severity: 'error' });
        return;
    }





    
    if(user.type === "2" && (isExpired || localUser.package_name === null)){
      // localStorage.setItem("user",JSON.stringify())
      
      setSnackbar({ open: true, message: "Your trial has ended. Please renew your subscription.", severity: 'error' });
      await    updatetDataById("Users",{
          package_name: null,
          phone_number:null,
          session_id:null,
          expiryTime:null,
          randomCode:null,
          subscriptionOwnerId:null,
          subscriptionOwner:null,
          
      },localUser._id)
      const updatedUser = localUser

updatedUser.package_name = null;
updatedUser.phone_number =null;
updatedUser.session_id =null;
updatedUser.expiryTime =null;
updatedUser.subscription="inactive";
updatedUser.subscriptionOwner=false;
updatedUser.subscriptionOwnerId=null;
updatedUser.randomCode=null;



localStorage.setItem('user', JSON.stringify(updatedUser));
return
  }
    // const user = getUser();
    if (user) {
      const pdfBlob = await GeneratePDF(userReport);
      const file = new File([pdfBlob], "report.pdf", {
        type: pdfBlob.type,
        lastModified: new Date().getTime(),
      });
      const formData = new FormData();
      formData.append("email", user.email);
      formData.append("file", file);
      try {
        const res = await sendReportToMail(formData);
        if (res.success) {
          setSnackbar({
            open: true,
            message: "Email sent successfully",
            severity: "success",
          });
        } else {
          setSnackbar({
            open: true,
            message: "Error sending email",
            severity: "error",
          });
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  }

  useEffect(() => {
    fetchReportList();
    const updateUser = async () => {
                // localStorage.removeItem('user');
               const currentUser = getUser()
          const user =   await fetchData("Users")
          
      const matchedUser =     user.data.filter((e:any)=>{
            return e.email === currentUser.email
          })
          
          saveUser(matchedUser)
          
      
              }
              // updateUser()
  }, []);

  useEffect(() => {
    setSortedReports(filteredReports);
  }, [filteredReports]);

  useEffect(() => {
    let local_user = getUser();
    setUser(local_user.type);
  }, []);

  useEffect(() => {
    try {
      fetchData("Users")
        .then((res) => {
          
          setDropDownUsers(res.data)
          
        })
        .catch((e) => console.log(e));
      
    } catch (error:any) {
      const alreadyLoggedIn = error.message.endsWith("401")
      if(alreadyLoggedIn){
          setSnackbar({ open: true, message: "logged in from some other device", severity: 'error' });
          setTimeout(()=>{
              navigate('/auth/sign-in'); 
          },3000)
      }
  }
  }, []);



  useEffect(() => {
    
    const fetchUser = async () => {
        const user = await getUser();  // Fetch or retrieve the user explicitly
        
        setLocalUser(user)
        if (user && user.expiryTime) {
            const expirationDate = new Date(user.expiryTime); // Convert to Date
            const currentDate = new Date(); // Current date

          

            if (currentDate > expirationDate) {
                
                setIsExpired(true);
                // Redirect or notify the user
            } else {
                
                setIsExpired(false);
                // Allow user to continue
            }
        } else {
            
        }
    };

    fetchUser();
}, [navigate]); // Only include `navigate` if necessary

 
  const allOption = { _id: "all", email: "All" };
  const options = [allOption, ...dropDownUsers];
  const [selectedUser, setSelectedUser] = useState(allOption);




const rows = sortedReports.map((report, index) => ({
  id: index + 1,
  description: report.system.description,
  added_on: report.added_on,
  report:report
}));

const columns: GridColDef[] = [
  { 
    field: 'description', 
    headerName: 'Report', 
    width: 300, 
    sortable: false ,
    disableColumnMenu: true , 
    renderCell: (params) => (
      <div onClick={() => handleRowClick(params.row?.report)} className="cursor-pointer">
        {params.value}
      </div>
    ),
    renderHeader: () => (
      <div className="flex items-center dark:text-white">
        Report
        <button onClick={() => handleSort("system.description")} className="ml-2">
          {sortConfig.key === "system.description" ? (
            sortConfig.direction === "ascending" ? (
              <MdArrowDropUp />
            ) : (
              <MdArrowDropDown />
            )
          ) : (
            <MdArrowDropUp />
          )}
        </button>
      </div>
    ) 
  },
  { 
    field: 'added_on', 
    headerName: 'Date', 
    width: 300, 
    sortable: false ,
    disableColumnMenu: true ,
    valueGetter: (params) => new Date(params.row.added_on).toLocaleDateString(),
    renderHeader: () => (
      <div className="flex items-center dark:text-white" >
        Date
        <button onClick={() => handleSortByDate()} className="ml-2">
          {sortConfig.key === "date" ? (
            sortConfig.direction === "ascending" ? (
              <MdArrowDropUp />
            ) : (
              <MdArrowDropDown />
            )
          ) : (
            <MdArrowDropUp />
          )}
        </button>
      </div>
    )
  },
  { 
    field: 'actions', 
    headerName: 'Action', 
    width: 300,
    sortable: false ,
    disableColumnMenu: true ,
    renderCell: (params:any) =>{ 
        return (
      <div className="flex items-center gap-2">
        <button  onClick={() => handleDownloadButton(params.row.report)} className="text-xl text-[#ADB5BD]">
          <MdFileDownload />
        </button>
        <button onClick={() => handleSendPDFButton(params.row.report)} className="text-xl text-[#ADB5BD]">
          <MdMail />
        </button>
      </div>
    )}
  },
];


  const handleSelectChange = (e: any,selectedUser: any) => {
    // const selectedUser = e.target.value;  

setSelectedUser(selectedUser)
    if(selectedUser){
      setNoReportFound(false)
      if(selectedUser._id !== "all"){
        let specificUserReport =  reportList.filter((e,i)=>{
           return selectedUser._id === e.added_by
         })
         
         if(specificUserReport.length < 1){
          setNoReportFound(true)
        }
  //        if(specificUserReport.length>10){
  //          specificUserReport.sort((a, b) => new Date(b.added_on).getTime() - new Date(a.added_on).getTime());
  //          let limitedReports = specificUserReport.slice(0,10)
  //          console.log("aaaaa+++++++++++++",limitedReports.length)
  //          setFilteredReports(limitedReports)
  // return
  //        }
         setFilteredReports(specificUserReport)
      }else{
        setFilteredReports(reportList)
      }
    }
    else{
      
      let limitedReportList = reportList.sort((a, b) => new Date(b.added_on).getTime() - new Date(a.added_on).getTime()).slice(0,10)
      setFilteredReports(reportList)
    }
    
  };

  const handleRowClick = (report:any) => {
    
    handleReportView(report);
    

  };


  return (
    <>
      {user === "1" ? (
        <Box display={"flex"} justifyContent={"end"}>
          <Box className="mt-4 w-[100%] max-w-[300px]  px-[32px] md:w-72">
       <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={options}
      value={selectedUser}
      onChange={handleSelectChange}
      getOptionLabel={(option) => `${option.email} `}
      
      sx={{ width:"100%",maxWidth: 300 }}
      renderInput={(params) => <TextField {...params} label="Select User" />}
    />
        </Box>
        </Box>
      ) : (
        ""
      )}

      {!loading ? (
        <Card extra={"w-full h-full  sm:overflow-auto py-8 px-8 mt-5"}>
          <div className="overflow-auto h-[700px]">
          {/* <table className="my-16 w-full text-left text-sm text-gray-500 dark:text-gray-400 rtl:text-right">
            <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th
                  scope="col"
                  className="text-black px-6 py-3 text-[14px] capitalize dark:text-white"
                >
                  Report
                  <button
                    onClick={() => handleSort("system.description")}
                    className="ml-2"
                  >
                    {sortConfig.key === "system.description" ? (
                      sortConfig.direction === "ascending" ? (
                        <MdArrowDropUp />
                      ) : (
                        <MdArrowDropDown />
                      )
                    ) : (
                      <MdArrowDropUp />
                    )}
                  </button>
                </th>
                <th
                  scope="col"
                  className="text-black px-6 py-3 text-[14px] capitalize dark:text-white"
                >
                  Date
                  <button onClick={() => handleSortByDate()} className="ml-2">
                    {sortConfig.key === "date" ? (
                      sortConfig.direction === "ascending" ? (
                        <MdArrowDropUp />
                      ) : (
                        <MdArrowDropDown />
                      )
                    ) : (
                      <MdArrowDropUp />
                    )}
                  </button>
                </th>
                <th
                  scope="col"
                  className="text-black px-6 py-3 text-[14px] capitalize dark:text-white"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
            {noReportFound ? (
    <tr>
      <td colSpan={3}  className="whitespace-nowrap px-6 py-4 font-medium text-gray-900 dark:text-white text-center" >
        No reports found.
      </td>
    </tr>
  ) : (
    <>
  {sortedReports.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((report, index) => (
  <tr
    key={index}
    className={`${
      index % 2 === 0
        ? "even:bg-gray-50 even:dark:bg-gray-800"
        : "odd:bg-white odd:dark:bg-gray-900"
    } border-b dark:border-gray-700`}
  >
    <th
      scope="row"
      className="whitespace-nowrap px-6 py-4 font-medium text-gray-900 dark:text-white"
    >
   
      <button
        className="cursor-pointer"
        onClick={() => handleReportView(report)}
      >
        {report.system.description}
      </button>
    </th>
    <td className="px-6 py-4">
      {new Date(report.added_on).toLocaleDateString()}
    </td>
    <td className="flex items-center gap-2 px-6 py-4">
    
      <button
        onClick={() => handleDownloadButton(report)}
        className="text-xl"
      >
        <MdFileDownload />
      </button>
      <button
        onClick={() => handleSendPDFButton(report)}
        className="text-xl"
      >
        <MdMail />
      </button>
    </td>
  </tr>
))}
    </>
  )
}
            </tbody>
          </table> */}
<DataGrid
sx={{bgColor:"red",width:"100%",height:"700px"}}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10]}
        // onRowClick={handleRowClick}
      />
          </div>

          {/* <div className="flex justify-center items-center p-4">
          <div className="flex gap-10 items-center p-4">
        <Button variant="outlined" onClick={handlePreviousPage} disabled={page === 0}>
          Previous
        </Button>
        <span className="font-medium">
          Page {page + 1} of {totalPages}
        </span>
        <Button variant="outlined" onClick={handleNextPage} disabled={page === totalPages - 1}>
          Next
        </Button>
      </div>
      </div> */}
      {/* <AlreadyLoggedInModal/> */}
        </Card>
      ) : (
        <div className="mt-6 flex items-center justify-center">
          <div className="h-16 w-16 animate-spin rounded-full border-4 border-dashed border-blue-500"></div>
        </div>
      )}
      <AppSnackbar
        isOpen={snackbar.open}
        title={snackbar.message}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        severity={snackbar.severity}
      />
    </>
  );
};

export default Report;
