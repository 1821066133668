import { Fragment } from 'react';
import Card from "components/card";

const AdminAccess = () => {

    return (
        <Fragment>
            <div>
                <div className="mt-5">
                    <Card extra={"w-full h-full sm:overflow-auto px-8"}>
                        <header className="relative items-center justify-between py-5">
                            <h3 className="text-[18px] font-semibold w-[100%] sm:w-[100%]" style={{ textTransform: "uppercase", color: "red" }}>You dont have access to the url!</h3>
                            <h1 className="text-[26px] w-[100%] sm:w-[100%]">Please select option from the side menu.</h1>
                        </header>
                    </Card>
                </div>
            </div>
        </Fragment>
    );
}

export default AdminAccess;