import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';

interface Props {
    rows: []
    columns: GridColDef[]
    loading: boolean
}

function CustomToolbar() {
    return (
        <GridToolbar
            sx={{
                "& .MuiButtonBase-root": {
                    color: "rgb(66, 42, 251)"
                },
            }}
        />
    );
}

const getRowId = (row: any) => row._id;

export default function DataTable(props: Props) {

    return (
        <div style={{ height: 'calc(100vh - 270px)', width: '100%', marginBottom: "30px" }}>
            <DataGrid
                sx={{ padding: 2 }}
                // rowHeight={30}
                rows={props.rows ? props.rows : []}
                columns={props.columns ? props.columns : []}
                loading={props.loading}
                components={{
                    Toolbar: CustomToolbar,
                }}
                getRowId={getRowId}
            // initialState={{
            //     pagination: {
            //         paginationModel: { page: 0, pageSize: 5 }
            //     },
            // }}
            // pageSizeOptions={[5, 10]}
            // checkboxSelection
            />
        </div>
    );
}
