import React, { Fragment, useEffect, useState } from 'react'
import Card from "components/card";
import { BsFillNodePlusFill } from "react-icons/bs";
import { Disclosure } from '@headlessui/react'
import CardMenu from "components/card/CardMenu";
import { FAQ, FAQResponseData, UpdateFAQ } from 'models/faq-models';
import { deleteDataById, fetchData, updatetDataById } from 'api';
import GeneralDialog from '../general/Dialog';
import { faqs } from 'api/collection_names';
import { useNavigate } from 'react-router-dom';
import AppSnackbar from 'components/snakbar';
import { SnackbarState } from 'models/user-models';
import { getUser } from 'auth/Authentication';

const FrequentlyAskedQuestions = () => {
    const navigate = useNavigate()
    const [isChecked, setIsChecked] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [ifAdd, setIfAdd] = React.useState(false);
    const [ifUpdate, setIfUpdate] = React.useState(false);
    const [dialogTitle, setDialogTitle] = React.useState("");
    const [rows, setRows] = React.useState<FAQResponseData[]>([])
    const [localUser,setLocalUser] = useState(null)
    const [isExpired,setIsExpired] = useState(null)
    const [faq, setFaq] = React.useState<FAQ>({
        description: "",
        title: ""
    });
    const [data, setData] = React.useState<FAQ | UpdateFAQ>();
    const [snackbar, setSnackbar] = useState<SnackbarState>({
        open: false,
        message: '',
        severity: 'info', 
    });

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFaq({ ...faq, [e.target.name]: e.target.value });
        setData({ ...data, [e.target.name]: e.target.value });
    }

    const memoizedSetFaqs = React.useCallback(
        (newFaqs: FAQResponseData[]) => {
            setRows(newFaqs);
        },
        []
    );

    const onEditClick = async (id: string, description: string, title: string) => {

        const user = getUser() 
        const hasTrialExpired = (expirationTimeUTC:any) => {
            const currentTimeUTC = new Date().toISOString(); // Get current time in UTC
            return currentTimeUTC > expirationTimeUTC; // Compare current time with expiration time
          };
          
         
          
        
        if (user.type === "2" && !isChecked && hasTrialExpired(user.trial_expiry) && !user.hasOwnProperty("expiryTime")) {
          // setIsChecked(true); 
            // alert("Your trial has expired. Please switch to the subscription mode.");
            setSnackbar({ open: true, message: "Your trial has expired. Please switch to the subscription mode.", severity: 'error' });
            return;
        }

        if(user.type === "2" && (isExpired || localUser.package_name === null)){
            setSnackbar({ open: true, message: "Your trial has ended. Please renew your subscription.", severity: 'error' });
            await    updatetDataById("Users",{
                package_name: null,
                phone_number:null,
                session_id:null,
                expiryTime:null,
                randomCode:null,
                subscriptionOwnerId:null,
                subscriptionOwner:null,
            },localUser._id)
            const updatedUser = localUser
      
      updatedUser.package_name = null;
      updatedUser.phone_number =null;
      updatedUser.session_id =null;
      updatedUser.expiryTime =null;
      updatedUser.subscription="inactive";
      updatedUser.subscriptionOwner=false;
      updatedUser.subscriptionOwnerId=null;
updatedUser.randomCode=null;
      
      
      localStorage.setItem('user', JSON.stringify(updatedUser));
      return
        }
        if(user.type === "1"){
            setIfAdd(false);
            setIfUpdate(true);
            setDialogTitle("Update FAQ")
            setData({ _id: id, description: description, title: title })
        }else{
            setSnackbar({ open: true, message: 'User is not admin', severity: 'error' });
        }
    }

    const deleteFaq = async (id: any) => {
        try {
            await deleteDataById("FAQs", id); // replace with your API call to delete
            setRows((prevRows) => prevRows.filter((faq) => faq._id !== id));
            setSnackbar({ open: true, message: 'FAQ deleted successfully', severity: 'success' });
        } catch (error) {
            setSnackbar({ open: true, message: 'Failed to delete FAQ', severity: 'error' });
        }
    };


    React.useEffect(() => {
        const fetchFaqs = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const res = await fetchData(faqs);
                    if (res?.data) {
                        memoizedSetFaqs(res.data as FAQResponseData[]);
                    }
                    if(res.message === "Token is not valid"){
                        alert("Token is not valid")
                        navigate("/auth/sign-in")
                    }
                    
                } catch (error:any) {
                    const alreadyLoggedIn = error.message.endsWith("401")
                    if(alreadyLoggedIn){
                        setSnackbar({ open: true, message: "logged in from some other device", severity: 'error' });
                        setTimeout(()=>{
                            navigate('/auth/sign-in'); 
                        },3000)
                    }
                }
            }
        };
        fetchFaqs();
    }, [open, memoizedSetFaqs]);



    useEffect(() => {
    
        const fetchUser = async () => {
            const user = await getUser();  // Fetch or retrieve the user explicitly
            
            setLocalUser(user)
            if (user && user.expiryTime) {
                const expirationDate = new Date(user.expiryTime); // Convert to Date
                const currentDate = new Date(); // Current date
    
                
                
    
                if (currentDate > expirationDate) {
                    
                    setIsExpired(true);
                    // Redirect or notify the user
                } else {
                    
                    setIsExpired(false);
                    // Allow user to continue
                }
            } else {
                
            }
        };
    
        fetchUser();
    }, [navigate]); // Only include `navigate` if necessary
    



    return (
        <Card extra={"w-full h-full sm:overflow-auto px-8 mt-5"}>
            <header className="relative flex items-center justify-between py-5">
                <h1 className="text-[26px] text-black font-semibold w-[100%] sm:w-[70%]">Frequently Asked Questions</h1>

                <button onClick={async() => {
const user = getUser() 
if(user.type === "2" && (isExpired || localUser.package_name === null)){
    setSnackbar({ open: true, message: "Your trial has ended. Please renew your subscription.", severity: 'error' });
    return
}
      

const hasTrialExpired = (expirationTimeUTC:any) => {
    const currentTimeUTC = new Date().toISOString(); // Get current time in UTC
    return currentTimeUTC > expirationTimeUTC; // Compare current time with expiration time
  };
  
 
  

if (user.type === "2" && (!isChecked && hasTrialExpired(user.trial_expiry) && !user.hasOwnProperty("expiryTime"))) {
  // setIsChecked(true); 
    // alert("Your trial has expired. Please switch to the subscription mode.");
    setSnackbar({ open: true, message: "Your trial has expired. Please switch to the subscription mode.", severity: 'error' });
    return;
}   
    // if (!isChecked && hasTrialExpired(user.trial_expiry) && !user.hasOwnProperty("expiryTime")) {
    //   // setIsChecked(true); 
    //     // alert("Your trial has expired. Please switch to the subscription mode.");
    //     setSnackbar({ open: true, message: "Your trial has expired. Please switch to the subscription mode.", severity: 'error' });
    //     return;
    // }


    if(user.type === "2" && (isExpired || localUser.package_name === null)){
        setSnackbar({ open: true, message: "Your trial has ended. Please renew your subscription.", severity: 'error' });
        await    updatetDataById("Users",{
            package_name: null,
            phone_number:null,
            session_id:null,
            expiryTime:null,
            randomCode:null,
            subscriptionOwnerId:null,
            subscriptionOwner:null,
        },localUser._id)
        const updatedUser = localUser
  
  updatedUser.package_name = null;
  updatedUser.phone_number =null;
  updatedUser.session_id =null;
  updatedUser.expiryTime =null;
  updatedUser.subscription="inactive";
  updatedUser.subscriptionOwner=false;
  updatedUser.subscriptionOwnerId=null;
updatedUser.randomCode=null;
  
  
  localStorage.setItem('user', JSON.stringify(updatedUser));
  return
    }


if(user.type === "1"){
    setDialogTitle("Add a new FAQ")
    setIfAdd(true);
    setIfUpdate(false);
    
    setOpen(true)
}else{
    setSnackbar({ open: true, message: 'User is not admin', severity: 'error' });
}
                }} className={`flex items-center text-sm hover:cursor-pointer bg-lightPrimary p-3 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10" linear justify-center rounded-lg font-bold transition duration-200`}>
                    Add FAQ &nbsp;&nbsp; <BsFillNodePlusFill />
                </button>
            </header>

            <div className="overflow-x-scroll xl:overflow-x-hidden">
                {/* cursor-pointer border-b-[1px] border-gray-200 pt-4 pb-2 pr-4 text-start */}
                {/* className="!border-px !border-gray-400 !mb-6" */}
                <div className="w-full pt-6">
                    {rows?.map((x, i) => {
                        return <>
                            <div className="flex justify-between items-center flex-wrap">
                                <div key={i} className="mb-4 w-[94%] max-w-4xl bg-transparent">
                                    <Disclosure>
                                        {({ open }) => (
                                            <>
                                                <Disclosure.Button className="flex text-start text-sm w-full justify-between rounded-2xl hover:cursor-pointer bg-lightPrimary p-6 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10 linear transition duration-200">
                                                    <span><b>+</b> &nbsp; {x.title}</span>
                                                </Disclosure.Button>
                                                <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-600">
                                                    {x.description}
                                                </Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure>
                                </div>
                                <div key={i} className="mb-4 w-[5%] max-w-4xl bg-transparent 0">
                                    {localUser.type === "1" && (
                                        <>
                                    <CardMenu onButtonClick={() => { onEditClick(x._id.toString(), x.description, x.title)   }}
                                     onDeleteClick={() => deleteFaq(x._id.toString()) }
                                    setOpenDialog={localUser.type === "1"?setOpen:undefined} />
                                        </>
                                    )}
                                </div>
                            </div>
                        </>
                    })}
                </div>
            </div>
            <GeneralDialog open={open} onClose={setOpen} isAdd={ifAdd} isUpdate={ifUpdate} title={dialogTitle} dialogFor={"faq"} onChange={onChange} data={data} />
            <AppSnackbar
                isOpen={snackbar.open}
                title={snackbar.message}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                severity={snackbar.severity}
            />
        </Card>
    )
}

export default FrequentlyAskedQuestions