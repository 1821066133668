import Doctor from "assets/img/auth/doctor.png";
import { Routes, Route, Navigate } from "react-router-dom";
import routes from "routes";

export default function Auth() {

  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  return (
    // <div className="container-fluid mx-auto">
    <div className="g-0 lg:flex lg:flex-wrap">
      {/* Sign-in/Register Forms */}
      <div className="px-4 md:px-0 lg:w-6/12">
        <div className="mx-auto min-h-screen flex flex-col justify-center pt-12 px-4 lg:max-w-[75%]  lg:pl-12 lg:pr-0 xl:max-w-full">
          <div className="flex flex-col space-y-8">
            <Routes>
              {getRoutes(routes)}
              <Route
                path="/"
                element={<Navigate to="/auth/sign-in" replace />}
              />
            </Routes>
          </div>
        </div>
      </div>
      {/* Medical Portal Content */}
      <div className="hidden md:block md:w-100% lg:w-6/12 bg-gradient-to-tr from-blue-700 via-violet-600 to-purple-400 p-8">
        <div className="flex flex-col justify-center h-full">
          <h1 className="text-3xl sm:text-4xl text-white font-semibold mb-4">Medical Portal</h1>
          <p className="text-gray-100 leading-6 text-base sm:text-md xl:text-lg font-poppins mb-8">We provide you with a comprehensive medical portal offering questionnaires that streamlines communication and enhances patient engagement aiming for improved healthcare outcomes while ensuring data security.</p>
          <div className="flex justify-center">
            <img src={Doctor} className="w-3/4 sm:w-2/3 h-auto" alt="Doctor" />
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}
