import { FAQ, FAQResponseData, UpdateFAQ } from "./faq-models";
import { SuggestionResponseModel } from "./suggestion-models";
import { UserResponseData } from "./user-models";

export const toastStatus = {
    Success: 'success',
    Error: 'error',
    Warning: 'warning',
    Info: 'info'
}

export interface AdminProps {
    [x: string]: any;
}

export interface ResponseData {
    success: boolean; 
    message: string;
    data: FAQResponseData[] | SuggestionResponseModel[] | UserResponseData[];
}

export interface FetchData {
    table: string;
}

export interface DialogProps {
    open: boolean;
    dialogFor: string; //faq, subscription, suggestion etc...
    title: string;
    onClose: React.Dispatch<React.SetStateAction<boolean>>;
    isAdd: boolean;
    isUpdate: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    data: any;
}

export const doctorRestrictPaths = [
    { path: "dashboard" },
    { path: "add-systems" },
    { path: "add-compliants" },
    { path: "examination" },
    { path: "impression" },
    { path: "treatment_plans" }
]

export interface appRoles {
    1: "Admin",
    2: "Doctor"
}