import { getUser } from "auth/Authentication";
import { doctorRestrictPaths } from "models/general-models";
import { User } from "models/user-models";

export interface SelectModel {
    value: string
    label: string
}

export function calculateAge(birthDate: Date) {
    const today = new Date();
    const birthDateObj = new Date(birthDate);
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDiff = today.getMonth() - birthDateObj.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
        age--;
    }
    return age;
}

export function handleRouteRestriction(userType: string, path: string) {
    const foundPath = doctorRestrictPaths.find((x) => (x.path === path))
    if (userType === "2" && foundPath && Object.keys(foundPath).length > 0)
        return false
    else
        return true
}