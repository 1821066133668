import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'

interface ChildComponentProps {
    children: React.ReactNode;
    title?: string;
    saveButtonText?: string;
    cancelButtonText?: string;
    openModal?: boolean;
    isValid?: boolean;
    onClose?: React.Dispatch<React.SetStateAction<boolean>>;
    onSave?: () => void;
}

const GeneralModal: React.FC<ChildComponentProps> = ({ children, title, saveButtonText, cancelButtonText, openModal, isValid, onClose, onSave }) => {
    const cancelButtonRef = React.useRef(null)

    return (
        <Transition.Root show={openModal} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-100 w-2xl overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg !bg-white dark:!bg-navy-900 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl" style={{ overflow: "visible" }}>
                                <div className="!bg-white dark:!bg-navy-900 px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                            <Dialog.Title as="h2" className="text-2xl font-semibold leading-6 text-navy-700 dark:text-white">
                                                {title}
                                            </Dialog.Title>
                                            {children}
                                        </div>
                                    </div>
                                </div>
                                <div className="!bg-white dark:!bg-navy-900 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                    <button
                                        disabled={isValid ? !isValid : false}
                                        onClick={onSave}
                                        type="button"
                                        className={`linear w-[100px] rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200  ${isValid ? !isValid : false ? "disabledButton" : ""}`} >
                                        {saveButtonText ? saveButtonText : "Save"}
                                    </button>
                                    <button
                                        onClick={() => onClose(false)}
                                        type="button"
                                        className="flex w-[100px] items-center text-sm hover:cursor-pointer bg-lightPrimary p-3 mr-4 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10 linear justify-center rounded-lg font-bold transition duration-200" >
                                        {cancelButtonText ? cancelButtonText : "Cancel"}
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

export default GeneralModal;