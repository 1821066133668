import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import OTP from 'components/otp';
import AppSnackbar from 'components/snakbar';
import { sendOtp, authenticateOtp, resendOtp, updatePassword } from 'api';
import { MdArrowBack } from 'react-icons/md';
import {useNavigate } from "react-router-dom";
import { SnackbarState } from 'models/user-models';




const ForgotPassword = () => {

    const navigate = useNavigate();

    const [step, setStep] = useState(1);
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [password, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState<SnackbarState>({
        open: false,
        message: '',
        severity: 'info',
    });
    

    const handleSendOtp = async () => {
        setLoading(true)
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailRegex.test(email)) {
            setSnackbar({ open: true, message: 'Invalid email format', severity: 'error' });
            setLoading(false)
            return;
        }
        try {
            await sendOtp(email);
            setTimeout(()=>{
                setStep(2);
                setSnackbar({ open: true, message: 'OTP sent successfully', severity: 'success' });
                setLoading(false)
            },2000)
        } catch (error) {
            setTimeout(()=>{
                setLoading(false)
                setSnackbar({ open: true, message: 'Invalid Email', severity: 'error' });
            },1000)
        } 
  
};


  const handleVerifyOtp = async () => {
        setLoading(true);
        try {
            const res = await authenticateOtp({ email, otp });
            if (res.success) {
                setTimeout(()=>{
                    setStep(3);
                    setSnackbar({ open: true, message: res.message, severity: 'success' });
setLoading(false)
                },2000)
            } else {
                setSnackbar({ open: true, message: 'Invalid OTP', severity: 'error' });
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            setSnackbar({ open: true, message: 'Invalid OTP', severity: 'error' });
        } 
    };

    const updateUserPassword = async () => {
        setLoading(true)
        if (password !== confirmPassword) {
            setTimeout(()=>{
                setSnackbar({ open: true, message: 'Passwords do not match', severity: 'error' });
                setLoading(false)
            },1000)
            return;
        }else{
            
            setSnackbar({ open: true, message: 'Password changed successfully', severity: 'success' });
        }
        
        try {
         const data =   await updatePassword({ email, password });
         
         if(data.success){
            setTimeout(()=>{
                setSnackbar({ open: true, message: 'Password changed successfully', severity: 'success' });
                navigate("/auth/sign-in");
                setLoading(false)
            setStep(1);
            setEmail('');
            setOtp('');
            setNewPassword('');
            setConfirmPassword('');
            setLoading(false)
            },2000)
         }
          
        } catch (error) {
            setSnackbar({ open: true, message: 'Error changing password', severity: 'error' });
            setLoading(false)
        } 
    };

    const handleOTPComplete = async (otp: string) => {
        setOtp(otp);
    }

    const handleResendOTP = async () => {
        const data = {
            email: email,
        };
        try {
            await resendOtp(data);
            setSnackbar({ open: true, message: 'OTP resent successfully', severity: 'info' });
        } catch (error) {
            console.error('Error resending OTP:', error);
            setSnackbar({ open: true, message: 'Error resending OTP', severity: 'error' });
        }
    };




    return (
        <div className=" w-[60%] m-auto">
            {step === 1 && (
                <div>
                     <button onClick={() =>navigate("/auth/sign-in")} className=" absolute top-5 left-5 text-lg dark:text-white cursor-pointer flex self-center" title='Back'>
                        <MdArrowBack style={{ margin: 5 }} /> Back
                    </button>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email address</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        
                        
                        className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-brand-500 focus:border-brand-500 sm:text-sm"
                        placeholder="Enter your email"
                    />
                    <button
                        className="linear mt-4 w-full rounded-xl bg-brand-500 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                        onClick={handleSendOtp}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
                    </button>
                </div>
            )}

            {step === 2 && (
                <div>
                    <button onClick={() => setStep(1)} className=" absolute top-5 left-5 text-lg dark:text-white cursor-pointer flex self-center" title='Back'>
                        <MdArrowBack style={{ margin: 5 }} /> Back
                    </button>
                    <OTP title="Verify OTP" descriptionMessage="Enter OTP to verify your email!" onComplete={handleOTPComplete} onVerifyOTP={() => { }} onResendOTP={handleResendOTP} />
                    <button
                        className="linear mt-4 w-full rounded-xl bg-brand-500 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                        onClick={handleVerifyOtp}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} color="inherit" /> : 'Verify OTP'}
                    </button>
                </div>
            )}

            {step === 3 && (
                <div>
                    <button onClick={() => navigate("/auth/sign-in")} className="absolute top-5 left-5 text-lg dark:text-white cursor-pointer flex self-center" title='Back'>
                        <MdArrowBack style={{ margin: 5 }} /> Back
                    </button>
                    <label htmlFor="password" className="block ml-2 mt-3 text-sm font-medium text-gray-700">New Password</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-brand-500 focus:border-brand-500 sm:text-sm"
                        placeholder="Enter new password"
                    />
                    <label htmlFor="confirmPassword" className="block ml-2 mt-3 text-sm font-medium text-gray-700">Confirm New Password</label>
                    <input
                        type="password"
                        id="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-brand-500 focus:border-brand-500 sm:text-sm"
                        placeholder="Confirm new password"
                    />
                    <button
                        className="linear mt-4 w-full rounded-xl bg-brand-500 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                        onClick={updateUserPassword}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} color="inherit" /> : 'Change Password'}
                    </button>
                </div>
            )}

            <AppSnackbar
                isOpen={snackbar.open}
                title={snackbar.message}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                severity={snackbar.severity}
            />
        </div>
    );
};

export default ForgotPassword;
