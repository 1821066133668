/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import routes from "routes";
import { getUser } from "auth/Authentication";
import { handleRouteRestriction } from "utils/general-utils";
import { User } from "models/user-models";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Sidebar = (props: {
  open: boolean;
  onClose: React.MouseEventHandler<HTMLSpanElement>;
}) => {



  const { open, onClose } = props;
  const [user, setUser] = useState(null);
  const [trialExpire,setTrialExpire] = useState(false)
const navigate = useNavigate()

  useEffect(() => {
    let local_user = getUser()
    setUser(local_user)
    const hasTrialExpired = (expirationTimeUTC:any) => {
      const currentTimeUTC = new Date().toISOString(); // Get current time in UTC
      return currentTimeUTC > expirationTimeUTC; // Compare current time with expiration time
    };
    if(hasTrialExpired(local_user.trial_expiry)){
      setTrialExpire(true)
    }
  }, [navigate])


  return (
    <div className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${open ? "translate-x-0" : "-translate-x-96"}`} >
      <span className="absolute top-4 right-4 block cursor-pointer xl:hidden" onClick={onClose} >
        <HiX />
      </span>

      <div className={`mx-[32px] mt-[50px] `}>
        <div className="mt-1 ml-1 h-2.5 font-poppins text-[26px]  font-bold text-navy-700 dark:text-white">
        Express Chart Note
        </div>
      </div>
      <div className="mt-[58px] mb-7 h-px bg-gray-300 dark:bg-white/30" />
      {/* Nav item */}

      <ul className="mb-auto pt-1">
        <Links routes={routes.filter(x => (x.path !== "add-subcategory/:id" && x.path !== "register" && x.path !== "success" && x.path !== "sign-in" && x.path !== "changePassword"&& x.path !== "forgotpass" && x.path !== "AccessDenied" && x.path !== "404")
        )} />
      </ul>

      {/* Free Horizon Card */}
      {/* <div className="flex justify-center">
        <SidebarCard />
      </div> */}

      {/* Nav item end */}
      <div className="mt-[58px] mb-7 h-px bg-gray-300 dark:bg-white/30 pl-5 " >
      

      {/* <h1 className=" h-2.5 font-poppins text-[26px]  font-bold text-navy-700 ml-5 dark:text-white">hello</h1> */}

       { user?.type === "2" && user?.hasOwnProperty("expiryTime") ? user.expiryTime?<h1 className="text-[#422AFB] font-poppins pt-3 font-medium">Subscription is Active</h1>:<h1 className="text-[#422AFB] font-poppins pt-3 font-medium">Subscription is Finsihed</h1>: (
        user?.type === "2" &&
  trialExpire ? <h1 className="text-[#422AFB] font-poppins pt-3 font-medium">7 day trial is finished</h1> :user?.type === "2" && <h1 className="text-[#422AFB] font-poppins pt-3 font-medium">7 day trial is active</h1>

)}

      </div>
    </div>
  );
};

export default Sidebar;
