import { Document, Page, Text, View, StyleSheet, pdf } from '@react-pdf/renderer';
import { Key, ReactElement, JSXElementConstructor, ReactNode, ReactPortal } from 'react';


export async function GeneratePDF(userReport: any) {
    const styles = StyleSheet.create({
        h1: {
            textAlign: 'center',
        },
        page: {
            flexDirection: 'column',
            backgroundColor: '#ffffff',
            padding: 20,
        },
        section: {
            marginBottom: 10,
        },
        sectionUnit:{
            display:"flex",
            flexDirection:"row",
            alignItems:"center",
            fontSize: 11,
            marginTop:2,
            fontWeight: 'bold',

        },
        units:{
            fontWeight: 'extrabold',
            fontSize: 9,
            marginTop:2
        },
        sectionTitle: {
            fontSize: 16,
            fontWeight: 'bold',
            marginBottom: 5,
        },
        sectionContent: {
            fontSize: 12,
        },
        remarks: {
            fontSize: 9,
            fontWeight: "bold"
        },
        remarksdetail: {
            fontSize: 8,
        }
    });

    const ReportDocument = (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <Text style={styles.h1}>Patient Report</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.sectionTitle}>Complaints:</Text>
                    {userReport?.compliants &&
                        <Text style={styles.sectionContent} key={userReport.compliants[0]?._id}>{`Patient with History of ${userReport.compliants[0]?.description} for ${userReport.duration} ${userReport.durationType}`}</Text>

                    }
                    <View style={{ display: "flex" }}>
                        {userReport.complaintRemark && <Text style={styles.remarks}>Remarks: <Text style={styles.remarksdetail}>{userReport.complaintRemark}</Text></Text>}
                    </View>
                    {userReport.subComplaints && userReport.subComplaints.length > 0 &&
                        <Text style={styles.sectionContent} key={userReport.compliants[0]?._id}>{userReport.subComplaints?.map((sub_complaint: { description: any; }, index: number) => {
                            const isLast = index === userReport.subComplaints.length - 1;
                            const separator = isLast ? '' : (index === userReport.subComplaints.length - 2 ? ' and ' : ', ');
                            if (index === 0) {
                                return `Patient also has ${sub_complaint.description}${separator} \n subComplaintRemark: ${userReport.subComplaintRemark}`;
                            }
                            else {
                                return `${sub_complaint.description}${separator}`;
                            }
                        }).join("")}</Text>
                    }
                </View>
                <View style={styles.section}>
                    <Text style={styles.sectionTitle}>On Examination:</Text>
                    {userReport.examinations?.map((item: any) => (
                        <div style={styles.sectionUnit} key={item?._id}>
                      <Text style={styles.sectionUnit} >
                      {`${item.description}${item.scroll_downs && item.scroll_downs.length ? ": " : ""}${
                          item.scroll_downs
                              ? item.scroll_downs
                                    .filter((scroll_down: string) => scroll_down !== undefined)
                                    .map((scroll_down: string, index: number) => {
                                        if (index < item.scroll_downs.length - 1) {
                                            return scroll_down + ", ";
                                        } else {
                                            return scroll_down;
                                        }
                                    })
                                    .join('')
                              : ''
                      }\n`}
                   
                  </Text>
                  <Text  style={styles.units}>   {item.value && `: ${item.value.inputValue} ${item.value.unitValue}\n`}</Text>
                  </div>
                    ))}
                    {userReport.examinationRemark && <Text style={styles.remarks}>Remarks: <Text style={styles.remarksdetail}>{userReport.examinationRemark}</Text></Text>}
                </View>
                <View style={styles.section}>
                    <Text style={styles.sectionTitle}>Impression:</Text>
                    {userReport.impressions?.map((item: { _id: Key; description: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal; }) => (
                        <Text style={styles.sectionContent} key={item?._id}>{item.description}</Text>
                    ))}
                    {userReport.impressionRemark && <Text style={styles.remarks}>Remarks: <Text style={styles.remarksdetail}>{userReport.impressionRemark}</Text></Text>}
                </View>
                <View style={styles.section}>
                    <Text style={styles.sectionTitle}>Treatment Plan:</Text>
                    {userReport.treatment_plans?.map((item: { _id: Key; description: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal; }) => (
                        <Text style={styles.sectionContent} key={item?._id}>{item.description}</Text>
                    ))}
                </View>
            </Page>
        </Document>
    );

    const pdfBlob = await pdf(ReportDocument).toBlob();
    return pdfBlob;
}