import axios from 'axios';
import { ResponseData } from 'models/general-models';
import { OTP, ResendOTP, UpdatePassword, UserAuthentication, UserPasswordChange, UserRegister, UserSignIn, UserUpdate, VerifyOTP } from 'models/user-models';
import { API_EndPoints } from './endpoints';
import { getToken } from 'auth/Authentication';
import { ContactUsResponseModel } from 'models/contactus-models';
import { OrderData } from 'models/suggestion-models';

export const getUrl = () => {
    // const url = "http://localhost:7000/"
    const url = "https://api.expresschartnote.com/"
    return url;
}

export const registerUser = async (body: UserRegister) =>
    axios.post<UserAuthentication>(`${getUrl() + API_EndPoints.Users.RegisterUser}`, body, {
        headers: {
            'Content-Type': 'application/json',
        }
    }).then((res: any) => {
        return res.data
    });



    export const authenticateUser = async (body: UserSignIn): Promise<UserAuthentication> => {
    
          const response = await axios.post<UserAuthentication>(`${getUrl() + API_EndPoints.Users.AuthenticateUser}`, body, {
            headers: {
              'Content-Type': 'application/json',
            }
          });
      
          console.log("Response from authenticateUser:", response);
          if(response){
              return response.data;
          }else{
          
          }
     
        }

export const authenticateOtp = (data: any) =>
    axios.post<UserAuthentication>(`${getUrl() + API_EndPoints.Users.AuthenticateOTP}`, data, {
        headers: {
            'Content-Type': 'application/json',
        }
    }).then((res: any) => {
        return res.data
    })

export const sendOtp = (email: string) =>

    axios.post<OTP>(`${getUrl() + API_EndPoints.Users.SendOTP}`, { email: email }, {
        headers: {
            'Content-Type': 'application/json',
        }
    }).then((res: any) => {
        return res.success
    })

    export const sendSignUpOtp = (email: string) =>

        axios.post<OTP>(`${getUrl() + API_EndPoints.Users.SendSignupOTP}`, { email: email }, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res: any) => {
            return res.success
        })

export const resendOtp = (data: any) =>
    axios.post<UserAuthentication>(`${getUrl() + API_EndPoints.Users.ResendOTP}`, data, {
        headers: {
            'Content-Type': 'application/json',
        }
    }).then((res: any) => {
        return res.data
    })

export const verifyOTP = (body: VerifyOTP) =>
    axios.post<UserAuthentication>(`${getUrl() + API_EndPoints.Users.AuthenticateUser}`, body, {
        headers: {
            'Content-Type': 'application/json',
        }
    }).then((res: any) => {
        return res.data
    })
export const passwordChanged = (body: UserPasswordChange) => {
    const token = getToken()
    return axios.put(`${getUrl() + API_EndPoints.Users.changePassword}`, body, {
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': token
        }
    }).then((res: any) => {
        return res.data
    })
}
export const updatePassword = (body: UpdatePassword) => {
    const data = {
        email: body.email,
        password: body.password
    }
    const token = getToken()
    return axios.put(`${getUrl() + API_EndPoints.Users.updatePassword}`, data, {
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': token
        }
    }).then((res: any) => {
        return res.data
    })
}


export const stripeApi = async (data:any) =>{
    let response = await axios.post(`${getUrl()}payment`, data);
    if(response){
        return response
    }
}

export const insertData = (collection: string, body: any) => {
    const token = getToken()
    const data = {
        collection: collection,
        data: body
    }
    return axios.post<ResponseData>(`${getUrl() + API_EndPoints.General.InsertData}`, data, {
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': token
        }
    }).then((res: any) => {
        if (res.data.mesessage === "Token is not valid") {
            window.location.href = "/logout"
        }
        return res.data
    })
}



export const logoutUser = (email: string) => {
    return axios.post<ResponseData>(`${getUrl() + API_EndPoints.Users.logoutUser}`, { email })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.error('Logout error:', error);
            throw error;
        });
};

export const fetchData = (collection: string, body?: any) => {
    const token = getToken()
    const data = {
        collection: collection,
        data: body ? body : {}
    }
    return axios.post<ResponseData>(`${getUrl() + API_EndPoints.General.FetchData}`, data, {
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': token
        }
    }).then((res: any) => {
        if (res.data.mesessage === "Token is not valid") {
            window.location.href = "/logout"
        }
        return res.data
    })
}


export const subscriptionCodeVerification = (data:any) => {
    const token = getToken();
    return axios.post(`${getUrl()}custom/subscriptionCode`, 
data , // Wrap the data in an object
      {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token
        }
      }
    ).then((res: any) => {
      return res;
    });
  }

export const updatetDataById = (collection: string, body: any, id: string) => {
    const token = getToken()
    const data = {
        collection: collection,
        data: body,
        filters: {
            _id: id
        }
    }
    return axios.put<ResponseData>(`${getUrl() + API_EndPoints.General.UpdateData}`, data, {
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': token
        }
    }).then((res: any) => {
        if (res.data.mesessage === "Token is not valid") {
            window.location.href = "/logout"
        }
        return res.data
    })
}

export const deleteDataById = (collection: string, id: string) => {
    const token = getToken()
    const data = {
        collection: collection,
        _id: id
    }
    return axios.post<ResponseData>(`${getUrl() + API_EndPoints.General.DelteDataById}`, data, {
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': token
        }
    }).then((res: any) => {
        if (res.data.mesessage === "Token is not valid") {
            window.location.href = "/logout"
        }
        return res.data
    })
}

export const contactUs = (data: ContactUsResponseModel) => {
    const token = getToken()
    return axios.post(`${getUrl() + API_EndPoints.General.ContactUs}`, data, {
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': token
        }
    }).then((res: any) => {
        return res.data
    })
}

export const sendReportToMail = (data: any) => {
    console.log("data", data)
    const token = getToken()
    return axios.post(`${getUrl() + API_EndPoints.Report.sendReport}`, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'x-access-token': token
        }
    }).then((res: any) => {
        return res.data
    })
}
