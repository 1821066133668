import React, { ChangeEvent, useEffect, useState } from "react";
import Card from "components/card";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { BsFillNodePlusFill, BsArrowDown, BsArrowUp } from "react-icons/bs";
import InputField from "components/fields/InputField";
import GeneralModal from "usefulComponents/Modal/Modal";
import DataTable from "components/Datatable/Datatable";
import { GridColDef } from "@mui/x-data-grid";
import { deleteDataById, fetchData, insertData, updatetDataById } from "api";
import { treatment_plans, symptoms, systems } from "api/collection_names";
import { Button } from "@mui/material";
import { SelectModel } from "utils/general-utils";
import Select from 'react-select'
import { useNavigate } from "react-router-dom";
import AppSnackbar from "components/snakbar";
import { SnackbarState } from "models/user-models";

interface TreatmentPlansType {
    description: string
    system_id: string[]
    priority: number
    _id?: string
}

const TreatmentPlans = () => {
    const navigate = useNavigate()
    const [open, setOpen] = React.useState(false)
    const [isEdit, setIsEdit] = React.useState(false)
    const [editId, setEditId] = React.useState("0")
    const [rows, setRows] = useState<[]>([])
    const [dataObj, setDataObj] = useState<TreatmentPlansType>({
        description: "",
        system_id: [],
        priority: 0
    })
    const [options, setOptions] = useState<SelectModel[]>([{ value: 'null', label: 'None' }])
    const [loading, setLoading] = useState<boolean>(false)
    const [snackbar, setSnackbar] = useState<SnackbarState>({
        open: false,
        message: '',
        severity: 'info', 
    });

    useEffect(() => {
        getTreatmentPlans()
        getSystems()
    }, [])

    const initObj = () => {
        setDataObj({
            description: "",
            system_id: [],
            priority: 0
        })
    }

   
    const getSystems = async () => {
        try {
            setLoading(true)
            const res = await fetchData(systems)
            if (res.success) {
                const arr: SelectModel[] = [{ value: "null", label: "None" }]
                for (let index = 0; index < res.data.length; index++) {
                    const x = res.data[index];
                    arr.push({ value: x._id, label: x.description })
                }
                setOptions(arr)
            }
            else {
                // const isTokenExpired = () => {
                //     const expirationTime = localStorage.getItem('expirationTime');
                //     if (!expirationTime) return true;
                //     return new Date().getTime() > Number(expirationTime);
                //   };
                //   console.log("isTokenExpired++++++",isTokenExpired())
                  
                //   if (isTokenExpired()) {
                //     localStorage.removeItem('expirationTime');
                //  navigate('/auth/sign-in'); 
                //   } 
                alert("No Systems found...!")
                navigate('/auth/sign-in'); 
            }
            setLoading(false)
            
        } catch (error:any) {
            const alreadyLoggedIn = error.message.endsWith("401")
            if(alreadyLoggedIn){
                setSnackbar({ open: true, message: "logged in from some other device", severity: 'error' });
                setTimeout(()=>{
                    navigate('/auth/sign-in'); 
                },3000)
            }
        }
    }


    const getTreatmentPlans = async () => {
        try {
            setLoading(true)
            const res = await fetchData(treatment_plans)
            if (res.success) {
                let sortData = res.data.sort((a: any, b: any) => a.priority - b.priority);
                setRows(sortData)
            }
            else {
                setRows([])
                // const isTokenExpired = () => {
                //     const expirationTime = localStorage.getItem('expirationTime');
                //     if (!expirationTime) return true;
                //     return new Date().getTime() > Number(expirationTime);
                //   };
                //   console.log("isTokenExpired++++++",isTokenExpired())
                  
                //   if (isTokenExpired()) {
                //     localStorage.removeItem('expirationTime');
                //  navigate('/auth/sign-in'); 
                //   } 
                alert("No TreatmentPlan found...!")
                navigate('/auth/sign-in'); 
            }
            setLoading(false)
            
        } catch (error:any) {
            const alreadyLoggedIn = error.message.endsWith("401")
            if(alreadyLoggedIn){
                setSnackbar({ open: true, message: "logged in from some other device", severity: 'error' });
                setTimeout(()=>{
                    navigate('/auth/sign-in'); 
                },3000)
            }
        }
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setDataObj((prev) => ({
            ...prev,
            [event.target.name]: event.target.value
        }))
    }

    const columns: GridColDef[] = [
        { field: "description", headerName: "Description", flex: 9 },
        {
            field: "actions",
            headerName: "Actions",
            flex: 2,
            sortable: false,
            renderCell: (params: any) => (
                <>
                 <Button
                        title="up"
                        variant="contained"
                        sx={{ width: '30px', height: '30px', minWidth: '30px', mr: '5px', padding: 0, background: "#e5e5e5", color: "black" }}
                        onClick={async () => {
                            if (params.row.priority > 0) {

                                let data: TreatmentPlansType[] = rows
                                let row1 = data.find(row => row.priority === params.row.priority)
                                let row2 = data.find(row => row.priority === params.row.priority - 1)


                                await updatetDataById(treatment_plans, {
                                    description: row1?.description,
                                    system_id: row1?.system_id,
                                    priority: row1?.priority - 1
                                }, row1._id).then(res => console.log(res)).catch(e => console.log(e))

                                await updatetDataById(treatment_plans, {
                                    description: row2?.description,
                                    system_id: row2?.system_id,
                                    priority: row2?.priority + 1
                                }, row2._id).then(res => console.log(res)).catch(e => console.log(e))

                                getTreatmentPlans()
                            }

                        }}>
                        <BsArrowUp />

                    </Button>
                    <Button
                        title="down"
                        variant="contained"
                        sx={{ width: '30px', height: '30px', minWidth: '30px', mr: '5px', padding: 0, background: "#e5e5e5", color: "black" }}
                        onClick={async () => {
                            if ((params.row.priority <= 0) || (params.row.priority !== rows.length - 1)) {
                                let data: TreatmentPlansType[] = rows
                                let row1 = data.find(row => row.priority === params.row.priority)
                                let row2 = data.find(row => row.priority === params.row.priority + 1)

                                await updatetDataById(treatment_plans, {
                                    description: row1?.description,
                                    system_id: row1?.system_id,
                                    priority: row1?.priority + 1
                                }, row1._id).then(res => console.log(res)).catch(e => console.log(e))

                                await updatetDataById(treatment_plans, {
                                    description: row2?.description,
                                    system_id: row2?.system_id,
                                    priority: row2?.priority - 1
                                }, row2._id).then(res => console.log(res)).catch(e => console.log(e))
                                getTreatmentPlans()
                            }
                        }}>
                        <BsArrowDown />
                    </Button>

                    <Button
                        title="Edit"
                        variant="contained"
                        sx={{ width: '30px', height: '30px', minWidth: '30px', mr: '5px', padding: 0, background: "#eed202", color: "white" }}
                        onClick={() => {
                            setDataObj((prev) => ({
                                ...prev,
                                description: params.row.description,
                                parent_ids: params.row.parent_ids,
                            }))
                            setOpen(true)
                            setIsEdit(true)
                            setEditId(params.row._id)
                        }}>
                        <EditIcon fontSize="small" />
                    </Button>
                    <Button
                        title="Delete"
                        sx={{ width: '30px', height: '30px', minWidth: '30px', mr: '5px', padding: 0, background: "red", color: "white" }}
                        variant="contained"
                        onClick={async () => {
                            /* eslint-disable no-restricted-globals */
                            if (confirm(`Are you sure to delete ${params.row.description}`)) {
                                const res = await deleteDataById(treatment_plans, params.row._id)
                                if (res.success) {
                                    getTreatmentPlans()
                                }
                                else {
                                    alert(res.message)
                                }
                            }
                        }}>
                        <DeleteIcon fontSize="small" />
                    </Button>
                </>
            ),
        }
    ];

    return (
        <div>
            <div className="mt-5">
                <Card extra={"w-full h-full sm:overflow-auto px-8"}>
                    <header className="relative flex items-center justify-between py-5">
                        <h1 className="text-[26px] text-white font-semibold w-[100%] sm:w-[70%]">Questions</h1>

                        <button onClick={() => {
                            setOpen(true)
                            setIsEdit(false)
                            initObj()
                        }} className={`flex items-center text-sm hover:cursor-pointer bg-lightPrimary p-3 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10" linear justify-center rounded-lg font-bold transition duration-200`}>
                            Add New Treatment Plan &nbsp;&nbsp; <BsFillNodePlusFill />
                        </button>
                    </header>
                    <GeneralModal openModal={open} title="Add TreatmentPlans" saveButtonText="Submit" cancelButtonText="Close" onClose={setOpen} onSave={async () => {
                        if (!dataObj.description || dataObj.description.trim() === "") {
                            alert("Description required...!")
                            return
                        }
                        // if (dataObj.parent_ids.length) {
                        if (!isEdit) {
                            let postdata = dataObj
                            postdata.priority = rows.length
                            const res = await insertData(treatment_plans, postdata)
                            if (res.success) {
                                getTreatmentPlans()
                                setOpen(false)
                            }
                            else {
                                alert(res.message)
                            }
                        }
                        else {
                            const res = await updatetDataById(treatment_plans, dataObj, editId)
                            if (res.success) {
                                getTreatmentPlans()
                                setOpen(false)
                            }
                            else {
                                alert(res.message)
                            }
                        }
                        // }
                        // else {
                        //     alert("At least one parent should be selected...!")
                        // }
                    }} >
                        <div className="mt-10 flex justify-between items-center w-[100%]">
                            <div className="w-[450px] mr-5">
                                <InputField
                                    name="description"
                                    variant="auth"
                                    extra="mb-3"
                                    label="Description"
                                    placeholder="Description"
                                    type="text"
                                    id="description"
                                    value={dataObj.description.replace("  ", " ")}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="w-[230px] mt-[-8px]">
                                <label htmlFor="selectionType" className={`font-poppins text-sm text-navy-700 dark:text-white ml-1.5 font-medium`} >
                                    System
                                </label>
                                <Select
                                    name="filters"
                                    placeholder="Filters"
                                    value={options.filter(option => dataObj.system_id.includes(option.value))}
                                    options={options}
                                    onChange={(selectedOptions: any) => {
                                        if (selectedOptions) { }
                                        const selectedValues = selectedOptions.map((option: any) => option.value);
                                        const filteredValues = selectedValues.filter((value: string) => value !== 'null');
                                        setDataObj((prev) => ({
                                            ...prev,
                                            system_id: filteredValues
                                        }));
                                    }}
                                    isMulti={true}
                                    styles={{
                                        valueContainer: (base) => ({
                                            ...base,
                                            overflowX: 'auto',
                                            flexWrap: 'unset',

                                            marginTop: 5,
                                            marginLeft: 10,
                                            '&::-webkit-scrollbar': {
                                                height: '12px'
                                            }
                                        }),
                                        multiValue: (base) => ({
                                            ...base,
                                            flex: '1 0 auto',
                                        })
                                    }}

                                />
                            </div>

                            {/* <div className="w-[230px] mb-3">
                                <label htmlFor="selectionType" className={`font-poppins text-sm text-navy-700 dark:text-white ml-1.5 font-medium parentLabel`} >
                                    Parent
                                </label>
                                <Select isMulti={true} onChange={(selectedOptions: any) => {
                                    setDataObj((prev) => ({
                                        ...prev,
                                        parent_ids: selectedOptions.map((x: any) => x.value)
                                    }))
                                }} className={"custom-select"} options={options} value={options.filter(option => dataObj.parent_ids.includes(option.value))} />
                            </div> */}
                        </div>
                    </GeneralModal>

                    <div>
                        <DataTable columns={columns} rows={rows} loading={loading} />
                    </div>
                </Card>
            </div>
            <AppSnackbar
                isOpen={snackbar.open}
                title={snackbar.message}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                severity={snackbar.severity}
            />
        </div>
    );
};

export default TreatmentPlans;
