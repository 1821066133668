import { fetchData, insertData, logoutUser, updatetDataById } from "api";
import { examinations, impressions, reports, symptoms, systems, treatment_plans } from "api/collection_names";
import React, { useEffect, useState } from "react";
import { categoryModal } from "models/complaints/complaint-modals";
import Card from "components/card";
import { EITModal, ExaminationModal, UpdatedReportModal, WizardOptionModal, WizardProps, durationListArray, wizardDisplayModal } from "models/wizard/wizard-modals";
import "assets/css/App.css"
import WizardManagement from "./wizardManagement";
import WizardReport from "./wizardReport";
import completeAnimation from '../../../assets/animations/Animation - 1710686330018.json';
import Lottie from 'react-lottie';
import { MdAddCircle } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { SnackbarState } from "models/user-models";
import AppSnackbar from "components/snakbar";
import { getUser } from "auth/Authentication";

const Wizard: React.FC<WizardProps> = ({ selectedUser }) => {
    const navigate = useNavigate();
    const [screenHeight, setScreenHeight] = useState(0);
    const [systemList, setSystemList] = useState<categoryModal[]>([])
    const [complaintList, setComplaintList] = useState<categoryModal[]>([])
    const [examinationList, setExaminationList] = useState<ExaminationModal[]>([])
    const [impressionList, setImpressionList] = useState<EITModal[]>([])
    const [treatmentList, setTreatmentList] = useState<EITModal[]>([])
    const [durationList] = useState<wizardDisplayModal[]>(durationListArray)
    const [loading, setLoading] = useState<boolean>(false)
    const [duration, setDuration] = useState(1)
    const [durationType, setDurationType] = useState("Seconds")
    const [isCompleted, setIsCompleted] = useState<boolean>(false)
    const [complaintRemark, setComplaintRemark] = useState('');
    const [subComplaintRemark, setSubComplaintRemark] = useState('');
    const [impressionRemark, setImpressionRemark] = useState('');
    const [examinationRemark, setExaminationRemark] = useState('');
    const [data,setData] = React.useState<{ description: string; value: { inputValue: string, unitValue: string } }[]>([]);
    const [subscription,setSubscription] = useState(false)
    const [localUser,setLocalUser] = useState(null)
    const [isExpired,setIsExpired] = useState(null)

const [isChecked, setIsChecked] = useState(false);
    const [report, setReport] = useState<UpdatedReportModal>({
        system: null,
        compliants: [],
        subComplaints: [],
        examinations: [],
        impressions: [],
        treatment_plans: []
    })

    const [snackbar, setSnackbar] = useState<SnackbarState>({
        open: false,
        message: '',
        severity: 'info', 
    });



    const getData = async () => {
        try {
            
            setLoading(true)
            const systemsDB = await fetchData(systems)
            const complaintsDB = await fetchData(symptoms)
            const examinationDB = await fetchData(examinations)
            const impressionDB = await fetchData(impressions)
            const treatmentDB = await fetchData(treatment_plans)
           
            if (systemsDB.success) {
                const systemsArray: categoryModal[] = []
                systemsDB.data.map((x: categoryModal) => systemsArray.push(x))
                setSystemList(systemsArray)
            } else {
                alert("System list not found!")
                navigate('/auth/sign-in'); 
                // const isTokenExpired = () => {
                //     const expirationTime = localStorage.getItem('expirationTime');
                //     if (!expirationTime) return true;
                //     return new Date().getTime() > Number(expirationTime);
                //   };
                //   console.log("isTokenExpired++++++",isTokenExpired())
                  
                //   if (isTokenExpired()) {
                //     localStorage.removeItem('expirationTime');
                //  navigate('/auth/sign-in'); 
                //   } 
            }
    
            if (complaintsDB.success) {
                const complaintArray: categoryModal[] = []
                complaintsDB.data.map((x: categoryModal) => complaintArray.push(x))
                setComplaintList(complaintArray)
            } else {
                // alert("Complaint list not found!")
            }
    
            if (examinationDB.success) {
                const examinationArray: ExaminationModal[] = []
                examinationDB.data.map((x: ExaminationModal) => examinationArray.push(x))
                setExaminationList(examinationArray)
            } else {
                // alert("Examination list not found!")
            }
    
            if (impressionDB.success) {
                const impressionArray: EITModal[] = []
                impressionDB.data.map((x: EITModal) => impressionArray.push(x))
                setImpressionList(impressionArray)
            } else {
                // alert("Impression list not found!")
            }
    
            if (treatmentDB.success) {
                const treatmentArray: EITModal[] = []
                treatmentDB.data.map((x: EITModal) => treatmentArray.push(x))
                setTreatmentList(treatmentArray)
            } else {
                // alert("Treatment list not found!")
            }
    
            setLoading(false)
        }catch (error:any) {
            const alreadyLoggedIn = error.message.endsWith("401")
            if(alreadyLoggedIn){
                setSnackbar({ open: true, message: "logged in from some other device", severity: 'error' });
                setTimeout(()=>{
                    navigate('/auth/sign-in'); 
                },3000)
            }
        }
    }

    const staticExaminations = [...data]
    const userExamination:any[] = []
    const updateReportList = (data: WizardOptionModal[], selectedSubExams: wizardDisplayModal[]) => {
        let updatedReport: UpdatedReportModal = {
            system: null,
            compliants: [],
            subComplaints: [],
            examinations: [],
            impressions: [],
            treatment_plans: []
        };
        if (data && data.length) {
 
            for (let i = 0; i < data.length; i++) {
                const x = data[i];
                if (x.page_name === "Systems") {
                    updatedReport.system = systemList.filter(item => item._id === x.id)[0]
                }
                else if (x.page_name === "Complaints") {
                    updatedReport.compliants.push(complaintList.filter(item => item._id === x.id)[0])
                }
                else if (x.page_name === "Sub Complaints") {
                    updatedReport.subComplaints.push(complaintList.filter(item => item._id === x.id)[0])
                }
                else if (x.page_name === "Examinations") {
                    let exam = { ...examinationList.filter(item => item._id === x.id)[0] }
                    let arr = selectedSubExams.filter(sub_exam => sub_exam.id === x.id)
                    exam.scroll_downs = []
                    arr.map(x => exam.scroll_downs.push(x.description))
                    userExamination.push(exam)
                }
                else if (x.page_name === "Impressions") {
                    updatedReport.impressions.push(impressionList.filter(item => item._id === x.id)[0])
                }
                else if (x.page_name === "Treatment Plans") {
                    updatedReport.treatment_plans.push(treatmentList.filter(item => item._id === x.id)[0])
                }
            }
        }


        const allExaminations = [...userExamination, ...staticExaminations];
    updatedReport.examinations.push(...allExaminations);
        setReport(updatedReport)
    }

    const printReport = async() => {
        const user = getUser()

        const hasTrialExpired = (expirationTimeUTC:any) => {
            const currentTimeUTC = new Date().toISOString(); // Get current time in UTC
            return currentTimeUTC > expirationTimeUTC; // Compare current time with expiration time
          };
          
         
          
        
        if (user.type === "2" && !isChecked && hasTrialExpired(user.trial_expiry) && !user.hasOwnProperty("expiryTime")) {
            
            setSnackbar({ open: true, message: "Your trial has expired. Please switch to the subscription mode.", severity: 'error' });
            // setIsChecked(true); 
            return
        }
        
        if (user.type === "2" && (isExpired || localUser.package_name === null)) {
            setSnackbar({ open: true, message: "Your trial has ended. Please renew your subscription.", severity: 'error' });
        await    updatetDataById("Users",{
            package_name: null,
            phone_number:null,
            session_id:null,
            expiryTime:null,
            
        },localUser._id)
        const updatedUser = localUser
        
        updatedUser.package_name = null;
        updatedUser.phone_number =null;
        updatedUser.session_id =null;
        updatedUser.expiryTime =null;
        updatedUser.subscription="inactive";
        updatedUser.subscriptionOwner=false;
        updatedUser.subscriptionOwnerId=null;
        updatedUser.randomCode=null;
        localStorage.setItem('user', JSON.stringify(updatedUser)); 
                return; 
           
        }






        const printWindow = window.open('', '_blank');
        printWindow?.document.write(`
        <html>
        <head>
            <title>Report</title>
            <style>
                body {
                    font-family: DM Sans, sans-serif;
                    margin: 20px;
                }
                h1 {
                    text-align: center;
                }
                .section {
                    margin-bottom: 20px;
                }
                .section-title {
                    font-weight: bold;
                }
                .section-content {
                    margin-left: 20px;
                }
            </style>
        </head>
        <body>
            <h1>Patient Report</h1>
            <div class="section">
                <div class="section-title">Complaints:</div>
                <div class="section-content">${report.compliants && report.compliants.length > 0 ? `Patient with History of ${report.compliants[0].description} for ${duration} ${durationType}` : ""}</div>
               <br /> <div >Remarks: ${complaintRemark}</div>
                <div class="section-content section">${report.subComplaints?.map((sub_complaint, index) => {
            const isLast = index === report.subComplaints.length - 1;
            const separator = isLast ? '' : (index === report.subComplaints.length - 2 ? ' and ' : ', ');
           
            if (index === 0) {
                return (`\nPatient also has ${sub_complaint.description}${separator} <br /> <div >SubComplaintRemark: ${subComplaintRemark}</div>`);
            }
            else {
                return `${sub_complaint.description}${separator}`;
            }
       
        }).join("")}</div>
            </div>
            <div class="section">
                <div class="section-title">On Examination:</div>
                <div class="section-content">${report.examinations?.map((item) => {
                    return (
                        `
                        ${item.description}${item.value && item.value.inputValue && item.value.unitValue ? `: ${item.value.inputValue} ${item.value.unitValue}\n` : ''}
                        ${item.scroll_downs && item.scroll_downs.length ? ": " : ""}
                        ${item.scroll_downs ? item.scroll_downs.map((scroll_down, index) => {
                            if (index < item.scroll_downs.length - 1) {
                                return `${scroll_down}, `;
                            } else {
                                return scroll_down;
                            }
                        }).join('') : ''} 
                        <br />
                        `
                    )
                    
        }).join('')} </div>
        <div >Remarks: ${examinationRemark}</div>
            </div>
            <div class="section">
                <div class="section-title">Impression:</div>
                <div class="section-content">${report.impressions?.map(item => item.description + "<br>").join('')}</div>
                <div >Remarks: ${impressionRemark}</div>
            </div>
            <div class="section">
                <div class="section-title">Treatment Plan:</div>
                <div class="section-content">${report.treatment_plans?.map(item => item.description + "<br>").join('')}</div>
            </div>
        </body>
        </html>
    `);
        printWindow?.focus();
        printWindow?.print();
    };

    const handleSaveReport = async () => {
const allReports = await fetchData(reports)
const user = getUser()       
const userReports = allReports.data.filter((report:any)=>{
    return report.added_by === user._id
})

const hasTrialExpired = (expirationTimeUTC:any) => {
    const currentTimeUTC = new Date().toISOString(); // Get current time in UTC
    return currentTimeUTC > expirationTimeUTC; // Compare current time with expiration time
  };
  
 
  

if (user.type === "2" && !isChecked && hasTrialExpired(user.trial_expiry) && !user.hasOwnProperty("expiryTime")) {
    
    setSnackbar({ open: true, message: "Your trial has expired. Please switch to the subscription mode.", severity: 'error' });
    // setIsChecked(true); 
    return
}

if (user.type === "2" && (isExpired || localUser.package_name === null)) {
    setSnackbar({ open: true, message: "Your trial has ended. Please renew your subscription.", severity: 'error' });
await    updatetDataById("Users",{
    package_name: null,
    phone_number:null,
    session_id:null,
    expiryTime:null,
    
},localUser._id)
const updatedUser = localUser

updatedUser.package_name = null;
updatedUser.phone_number =null;
updatedUser.session_id =null;
updatedUser.expiryTime =null;
updatedUser.subscription="inactive";
updatedUser.subscriptionOwner=false;
updatedUser.subscriptionOwnerId=null;
updatedUser.randomCode=null;
localStorage.setItem('user', JSON.stringify(updatedUser)); 
        return; 
   
}


if(subscription?user.type === "2" &&  userReports.length >= 10:user.type === "2" && userReports.length >= 1){
    
    setSnackbar({ open: true, message:subscription? "Your Report Limit has succeed last report is replaced by new report":"Your trial limit has succeed last report is replaced by new report", severity: 'error' });
    const lastReport = userReports[userReports.length - 1];
    

    
    const res = await updatetDataById(reports, report, lastReport._id);
    setTimeout(()=>{
        if (res.success) {
            navigate("/admin/reports")
        }
        
    },2000)



}else{
    if (report) {
        let saveReport: any = { ...report }
        saveReport.duration = duration
        saveReport.durationType = durationType
        saveReport.complaintRemark = complaintRemark
        saveReport.subComplaintRemark = subComplaintRemark
        saveReport.impressionRemark = impressionRemark
        saveReport.examinationRemark = examinationRemark
        try {
            
            const res = await insertData(reports, saveReport)
            if (res.success) {
                navigate("/admin/reports")
            }
            else {
                // const isTokenExpired = () => {
                //     const expirationTime = localStorage.getItem('expirationTime');
                //     if (!expirationTime) return true;
                //     return new Date().getTime() > Number(expirationTime);
                //   };
                //   console.log("isTokenExpired++++++",isTokenExpired())
                  
                //   if (isTokenExpired()) {
                //     localStorage.removeItem('expirationTime');
                //  navigate('/auth/sign-in'); 
                //   } 
                alert(res.message)
                localStorage.removeItem("user")
                navigate('/auth/sign-in'); 
            }
            
        } catch (error:any) {
            const alreadyLoggedIn = error.message.endsWith("401")
            if(alreadyLoggedIn){
                setSnackbar({ open: true, message: "logged in from some other device", severity: 'error' });
                setTimeout(()=>{
                    navigate('/auth/sign-in'); 
                },3000)
            }
        }
    }

}
    }

    

    useEffect(() => {
        const user = getUser()  
        setLocalUser(user)
        const screenHeight = window.innerHeight;
        const eightyPercentHeight = screenHeight * 0.8;
        setScreenHeight(eightyPercentHeight);
        getData()
        user.package_name?setSubscription(true):setSubscription(false)

    




    }, [])


   



    useEffect(() => {
        const fetchUser = async () => {
            const user = await getUser();  // Fetch or retrieve the user explicitly
            
            
            if (user && user.expiryTime) {
                const expirationDate = new Date(user.expiryTime); // Convert to Date
                const currentDate = new Date(); // Current date
    
     
    
                if (currentDate > expirationDate) {
                    
                    setIsExpired(true);
                    // Redirect or notify the user
                } else {
                    
                    setIsExpired(false);
                    // Allow user to continue
                }
            } else {
                
            }
        };
    
        fetchUser();
    }, [navigate]); // Only include `navigate` if necessary


    

    return (
        <>
        {!loading ? <div className="relative flex justify-between" style={{ height: screenHeight }}>
            {!isCompleted ? < Card extra={"w-full h-full  px-8 mt-5 py-5 sm:w-[75%]"} >
                <h1 className="text-[26px] text-black font-semibold w-[100%] sm:w-[70%] text-left">Medical Note Wizard</h1>
                <WizardManagement duration={duration} setDuration={setDuration} durationType={durationType} setDurationType={setDurationType} systemList={systemList} complaintList={complaintList} durationList={durationList} examinationList={examinationList} impressionList={impressionList} treatmentList={treatmentList} updateReportList={updateReportList} setIsCompleted={setIsCompleted}
                    complaintRemark={complaintRemark}
                    setComplaintRemark={setComplaintRemark}
                    subComplaintRemark={subComplaintRemark}
                    setSubComplaintRemark={setSubComplaintRemark}
                    impressionRemark={impressionRemark}
                    setImpressionRemark={setImpressionRemark}
                    examinationRemark={examinationRemark}
                    setExaminationRemark={setExaminationRemark}
                    setData={setData}
                    data={data}

                />
            </Card > :
                // <div className="relative flex justify-between">
                <Card extra={"w-full h-full sm:overflow-auto px-8 mt-5 py-5 sm:w-[69%]"}>
                    <Lottie style={{ pointerEvents: "none", margin: 0, alignSelf: "center" }} width={200} options={{
                        loop: true,
                        autoplay: true,
                        animationData: completeAnimation,
                    }} />
                    <h1 className="mt-3 text-[26px] text-black font-semibold w-[100%] sm:w-[100%] text-center">Process Completed</h1>

                    <div className="mt-5 relative flex justify-center py-5">
                        <button
                            key={"print090"}
                            onClick={printReport}
                            className={`flex items-center w-40 text-sm hover:cursor-pointer bg-lightPrimary p-3 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10" linear justify-center rounded-lg font-bold transition duration-200 mr-3 buttonSelected`}>
                            Print Report
                        </button>

                        <button
                            key={"save090"}
                            onClick={handleSaveReport}
                            className={`flex items-center w-40 text-sm hover:cursor-pointer bg-lightPrimary p-3 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10" linear justify-center rounded-lg font-bold transition duration-200 mr-3 finishButton`}>
                            Save Report
                        </button>

                        <button
                            key={"new090"}
                            onClick={() => {
                                window.location.reload();
                            }}
                            className={`flex items-center w-50 text-sm hover:cursor-pointer bg-lightPrimary p-3 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10" linear justify-center rounded-lg font-bold transition duration-200 mr-3`}>
                            Create New Report &nbsp;&nbsp; <MdAddCircle />
                        </button>
                    </div>
                </Card>
                // </div>
            }
            <Card extra={"w-full h-full sm:overflow-auto px-8 mt-5 py-5 sm:w-[25%]"}>
                <WizardReport
                    userReport={report}
                    duration={duration}
                    durationType={durationType}
                    complaintRemark={complaintRemark}
                    subComplaintRemark={subComplaintRemark}
                    impressionRemark={impressionRemark}
                    data={data}
                    examinationRemark={examinationRemark} />
                    
            </Card>
        </div > : <> LOADING</>}

        <AppSnackbar
                isOpen={snackbar.open}
                title={snackbar.message}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                severity={snackbar.severity}
            />
        </>
    );

}

export default Wizard;