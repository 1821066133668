export const API_EndPoints = {
    Users: {
        RegisterAdmin: "users/create_admin",
        RegisterUser: "users/create_user",
        AuthenticateUser: "users/authenticate_user",
        AuthenticateOTP: "users/authenticate_otp",
        SendOTP: "users/send_otp",
        SendSignupOTP: "users/send_signup_otp",
        ResendOTP: "users/resend_otp",
        UpdateUser: "users/update_data",
        changePassword: "users/change_password",
        updatePassword: "users/update_password",
        logoutUser:"users/logout"
    },
    General: {
        InsertData: "general/insert_data",
        UpdateData: "general/update_data",
        DelteDataById: "general/delete_data_by_id",
        FetchData: "general/get_data",
        ContactUs:"contact/send_mail",
    },
    Report:{
        sendReport:"reports/send_report"
    }
}