import InputField from "components/fields/InputField";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { SnackbarState, UserSignIn } from "models/user-models";
import { authenticateOtp, authenticateUser, resendOtp } from "api";
import { saveToken, saveUser } from "auth/Authentication";
import CircularProgress from "@mui/material/CircularProgress";

import OTP from "components/otp";
import AppSnackbar from "components/snakbar";
import { v4 as uuidv4 } from 'uuid';
import AlreadyLoggedInModal from "components/AlreadyLoggedInModal/AlreadyLoggedInModal";

export default function SignIn() {
  const navigate = useNavigate();

  const [userCredentials, setUserCredentials] = React.useState<UserSignIn>({
    email:"",
    password:"",
  } as UserSignIn);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const [showOTPInput, setShowOTPInput] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>('');
  const [tempToken, setTempToken] = useState<any>();
  const [tempUser, setTempUser] = useState<any>();
  const [snackbar, setSnackbar] = useState<SnackbarState>({
    open: false,
    message: '',
    severity: 'info', 
});
const [deviceId,setDeviceId] = useState("")
const [pushed,setPushed] = useState(false)




 const getDeviceId = (): string => {
  let deviceId = localStorage.getItem('device_id');
  if (!deviceId) {
    deviceId = uuidv4();
    localStorage.setItem('device_id', deviceId);
  }
  return deviceId;
};



  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    
    setUserCredentials({ ...userCredentials, [e.target.name]: e.target.value });
  };

  const validateFields = () => {
    for (const key of Object.keys(userCredentials)) {
      const fieldValue = userCredentials[key as keyof UserSignIn];
      if (fieldValue === "") {
     
        return true;
      }
    }
    return false;
  };

 


  useEffect(() => {
    const id = getDeviceId();
    setDeviceId(id);
 
  }, [])

  useEffect(() => {

 
  }, [deviceId])

  useEffect(() => {
    if (pushed) {
        loginUser()
        setOpen(false);
        setPushed(false); // Reset pushed state
    }
}, [pushed]);


  const loginUser = async () => {
     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(userCredentials.email)) {
        setSnackbar({ open: true, message: 'Invalid email ', severity: 'error' });
        return;
    }

    if(validateFields()){
      setSnackbar({ open: true, message: "please fill all the fields", severity: 'error' });
      return
    }

    setLoading(true);
    
    try {
      
      if(pushed){
        const myObj = {...userCredentials,pushed}
        console.log("myObj++++++",myObj)
        const res = await authenticateUser(myObj);
        console.log("response apiiiiiii",res)
        if (res?.tokenCode) {
          setTempToken(res?.tokenCode)
          setTempUser(res?.user)
          setShowOTPInput(true)
          setLoading(false);
              } else {
                setLoading(false);
                setSnackbar({ open: true, message: res?.message, severity: 'error' });
              }
        
      }else{
        const res = await authenticateUser(userCredentials);
        console.log("response apiiiiiii",res)
        if (res?.tokenCode) {
    setTempToken(res?.tokenCode)
    setTempUser(res?.user)
    setShowOTPInput(true)
    setLoading(false);
        } else {
          setLoading(false);
          setSnackbar({ open: true, message: res?.message, severity: 'error' });
        }

      }
      
    } catch (error:any) {
      setLoading(false);
      const alreadyLoggedIn = error.message.endsWith("409")
      if(alreadyLoggedIn){
        setOpen(true)
        setSnackbar({ open: true, message: "Error occured while signing in", severity: 'error' });
        setLoading(false);

      }
      console.log("errorrrr---------",error)
      const userNotFound = error.message.endsWith("404")
      if(userNotFound){
        setSnackbar({ open: true, message: "User not found", severity: 'error' });

      }

      console.error('Error logging in:', error.message.endsWith("409"));
if(error.response.data.message === "Wrong Password!"){
  setSnackbar({ open: true, message: "Emai or Password is Invalid", severity: 'error' });
}
    }
  };

  const handleOTPComplete = async (otp: string) => {
   
    setLoading(false)
    setOtp(otp);
    
  };




  const handleResendOTP = async () => {
    try {
      const data = {
        email: tempUser.email,
      }
      await resendOtp(data);
      setSnackbar({ open: true, message: "OTP resent successfully", severity: 'info' });
    } catch (error) {
      console.error('Error resending OTP:', error);
    }
  };

  const handleVerifyOTP = async() => {
    setLoading(true)
    try {
      
      const data = {
        email: tempUser.email,
        otp: otp,
      }
      const res = await authenticateOtp(data);
      
      if (res?.success) {
        saveToken(tempToken);
        saveUser(tempUser);
        
          if (res?.user?.type === "1"){
            setTimeout(()=>{
              navigate("/admin/dashboard");
            },1000)
          }
          else
            
            setSnackbar({ open: true, message: res?.message, severity: 'success' });
            setTimeout(()=>{

              navigate("/admin/wizard");
      
            },1000)

      } else {
        setLoading(false)
        setSnackbar({ open: true, message: res?.message, severity: 'error' });
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      setLoading(false);
      setSnackbar({ open: true, message: "Incorrect OTP", severity: 'error' });
    } finally {
      
    }
  };

  return (
    <div className="mb-10 mt-10 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:items-center">
      <AlreadyLoggedInModal setPushed={setPushed} setUserCredentials={setUserCredentials} loginUser={loginUser} userCredentials={userCredentials} setOpen={setOpen} open={open}  setShowOTPInput={setShowOTPInput}/>
      <form className="w-full max-w-[420px] flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]" onSubmit={(e) => { e.preventDefault() }}>
        {showOTPInput ? (
          <>
            <OTP  title="Sign In" descriptionMessage="Enter your OTP to sign in!" onComplete={handleOTPComplete} onResendOTP={handleResendOTP} onVerifyOTP={handleVerifyOTP} />
            <button
              className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              onClick={handleVerifyOTP}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Verify OTP'}
            </button>
          </>
        ) : (
          <>
            <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
              Sign In
            </h4>
            <p className="mb-9 ml-1 text-[15px] text-gray-600">
              Enter your email and password to sign in!
            </p>

            <InputField
              variant="auth"
              extra="mb-3"
              label="Email"
              placeholder="mail@simmmple.com"
              id="email"
              type="text"
              name="email"
              value={userCredentials.email}
              onChange={(e) => { onChange(e) }}
              importantField
            />

            <InputField
              variant="auth"
              extra="mb-5"
              label="Password"
              placeholder="Min. 8 characters"
              id="password"
              type="password"
              name="password"
              value={userCredentials.password}
              onChange={(e) => { onChange(e) }}
              importantField
            />
            <button className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200" onClick={() => {
              if (validateFields()) {
                setSnackbar({ open: true, message: "please fill all the fields", severity: 'error' });
                return;
              }
              loginUser()
            }}>
              {loading ? <CircularProgress size={24} color="inherit" /> : "Sign In"}
            </button>
          
            <div className="mt-4">
              <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
                Forgot you password?
              </span>
              <Link to="/auth/forgotpass" className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white">
                Click here
              </Link>
            </div>

            <div className="mt-4">
              <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
                Not registered yet?
              </span>
              <Link to="/auth/register" className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white">
                Create an account
              </Link>
            </div>
          </>)}
      </form>
         <AppSnackbar
                isOpen={snackbar.open}
                title={snackbar.message}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                severity={snackbar.severity}
            />
    </div>
  );
}
