import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import SignIn from "views/auth/SignIn";
import Register from "views/auth/Register";
import {
  MdHome,
  MdOutlineShoppingCart,
  MdPerson,
  MdLock,
  MdLockOpen,
  MdLogin,
  MdQuestionMark,
  MdFileCopy,
  MdMessage,
  MdMedication,
  MdAssignmentAdd
} from "react-icons/md";
import Suggestions from "views/admin/suggestion";
import Report from "views/admin/report";
import FrequentlyAskedQuestions from "views/admin/faq";
import Compliants from "views/admin/compliants";
import Examinations from "views/admin/examinations";
import Impressions from "views/admin/impressions";
import TreatmentPlans from "views/admin/treatment_plans";
import Systems from "views/admin/systems";
import Wizard from "views/admin/wizard/wizard";
import ChangePassword from "views/admin/changePassword";
import AdminAccess from "usefulComponents/adminAccess";
import Page404 from "usefulComponents/404";
import ContactUs from "views/admin/contactus";
import ForgotPassword from "views/auth/ForgotPassword";
import StripeSuccess from "components/StripeSuccess";

const routes: RoutesType[] = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "dashboard",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
    // component: <ProtectedRoute component={MainDashboard} />,
  },
  {
    name: "Wizard",
    layout: "/admin",
    icon: <MdMedication className="h-6 w-6" />,
    path: "wizard",
    component: <Wizard />,
    // component: <ProtectedRoute component={DataTables} />,
  },
  {
    name: "Add Systems",
    layout: "/admin",
    icon: <MdAssignmentAdd className="h-6 w-6" />,
    path: "add-systems",
    component: <Systems />,
  },
  {
    name: "Add Compliants",
    layout: "/admin",
    icon: <MdAssignmentAdd className="h-6 w-6" />,
    path: "add-compliants",
    component: <Compliants />,
  },
  {
    name: "Add Examinations",
    layout: "/admin",
    icon: <MdAssignmentAdd className="h-6 w-6" />,
    path: "examination",
    component: <Examinations />,
  },
  {
    name: "Add Impressions",
    layout: "/admin",
    icon: <MdAssignmentAdd className="h-6 w-6" />,
    path: "impression",
    component: <Impressions />,
  },
  {
    name: "Add Treatment Plans",
    layout: "/admin",
    icon: <MdAssignmentAdd className="h-6 w-6" />,
    path: "treatment_plans",
    component: <TreatmentPlans />,
  },
  // {
  //   name: "Add Subcategory",
  //   layout: "/admin",
  //   icon: <MdBarChart className="h-6 w-6" />,
  //   path: "add-subcategory/:id",
  //   component: <AddSubCategory />,
  // },
  {
    name: "Subscriptions",
    layout: "/admin",
    path: "subscriptions",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <NFTMarketplace />,
    secondary: true,
    // component: <ProtectedRoute component={NFTMarketplace} />,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "profile",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Profile />,
    // component: <ProtectedRoute component={Profile} />,
  },
  {
    name: "Change Password",
    layout: "/admin",
    path: "changePassword",
    icon: <MdLock className="h-6 w-6" />,
    component: <ChangePassword />,
    // component: <ProtectedRoute component={Register} />,
  },
  {
    name: "Reports",
    layout: "/admin",
    path: "reports",
    icon: <MdFileCopy className="h-6 w-6" />,
    component: <Report />,
    // component: <ProtectedRoute component={Report} />,
  },
  {
    name: "FAQs",
    layout: "/admin",
    path: "faqs",
    icon: <MdQuestionMark className="h-6 w-6" />,
    component: <FrequentlyAskedQuestions />,
    // component: <ProtectedRoute component={FAQ} />,
  },
  // {
  //   name: "Contact us",
  //   layout: "/admin",
  //   path: "suggestion",
  //   icon: <MdMessage className="h-6 w-6" />,
  //   component: <Suggestions />,
  //   // component: <ProtectedRoute component={Suggestion} />,
  // },
  {
    name: "Contact Us",
    layout: "/admin",
    path: "suggestion",
    icon: <MdMessage className="h-6 w-6" />,
    component: <ContactUs />,
    // component: <ProtectedRoute component={Suggestion} />,
  },
  {
    name: "StripeSuccess",
    layout: "/admin",
    path: "success",
    icon: <MdMessage className="h-6 w-6" />,
    component: <StripeSuccess />,
    // component: <ProtectedRoute component={Suggestion} />,
  },
  {
    name: "Logout",
    layout: "/auth",
    path: "logout",
    icon: <MdLockOpen className="h-6 w-6" />,
    component: <SignIn />,
    // component: <ProtectedRoute component={SignIn} />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLogin className="h-6 w-6" />,
    component: <SignIn />,
    // component: <ProtectedRoute component={SignIn} />,
  },
  {
    name: "Register",
    layout: "/auth",
    path: "register",
    icon: <MdLock className="h-6 w-6" />,
    component: <Register />,
    // component: <ProtectedRoute component={Register} />,
  },
  {
    name: "ForgotPassword",
    layout: "/auth",
    path: "forgotpass",
    icon: <MdLock className="h-6 w-6" />,
    component: <ForgotPassword />,
    // component: <ProtectedRoute component={Register} />,
  },
  {
    name: "Access Denied",
    layout: "/admin",
    path: "AccessDenied",
    icon: <MdLock className="h-6 w-6" />,
    component: <AdminAccess />,
    // component: <ProtectedRoute component={Register} />,
  },

  {
    name: "Page Not Found",
    layout: "/auth",
    path: "404",
    icon: <MdLock className="h-6 w-6" />,
    component: <Page404 />,
    // component: <ProtectedRoute component={Register} />,
  },

];
export default routes;
