import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { IconButton, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { IoClose } from "react-icons/io5";
import DeleteIcon from '@mui/icons-material/Delete';
import { SnackbarState } from "models/user-models";
import AppSnackbar from "components/snakbar";
import { DefaultBtnModelProps } from "models/wizard/wizard-modals";


const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 400,
  width: "100%",
  height: 300,
  bgcolor: "background.paper",
borderRadius:"10px",
  boxShadow: 24,
  p: 4,
};






const DefaultBtnModel: React.FC<DefaultBtnModelProps> = ({ desc, data,isSelected,onSubmit,isInData,onRemove }) => {




  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [inputValue,setInputValue] = React.useState("")
  const [unitValue,setUnitValue] = React.useState("")
  const [snackbar, setSnackbar] = React.useState<SnackbarState>({
    open: false,
    message: '',
    severity: 'info', 
});



  const validateFields = () => {
    
    if (!inputValue || !unitValue ) {
   
      return false;
    }
    return true;
  };



  

const handleRemove = () => {
  onRemove(desc)
  // handleClose();
}
const handleSubmit = () => {
  
  if (!validateFields()) {
    setSnackbar({ open: true, message: "Please fill all the fields", severity: 'error' });
    return;
  }

  onSubmit(desc,{inputValue, unitValue} )
  setInputValue("");
  setUnitValue("");
  handleClose();
}


  return (
    <div>
        {desc && (
                <button
                    onClick={() => {
                      const alreadExists = data.some((e:any) => e.description ===  desc);
                        if (desc === "Body Mass Index") {
                            onSubmit(desc, { inputValue: "", unitValue: "" });
                            if(alreadExists){
                              handleRemove()}
                        } else {
                          if(alreadExists){
                            handleRemove()
                          }else{
                            handleOpen();
                          }
                        }
                    }}
                    className={`staticViewButton linear m-1 mr-3 flex items-center justify-center rounded-lg bg-lightPrimary p-1 text-sm font-bold text-brand-500 transition duration-200 hover:cursor-pointer hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10  ${isInData ? "buttonSelected" : ""}`}
                >
                    {desc}
                </button>
            )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        
        <Box sx={style}>
        <Box sx={{cursor:"pointer"}} onClick={handleClose} display={"flex"} justifyContent={"end"} alignItems={"center"}>
        <IoClose color="red"/>
        </Box>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {desc}
          </Typography>
          
          {desc !== "Body Mass Index" && (

            <Box mt={"5px"} width={"100%"}>
            <TextField
           value={inputValue}
           onChange={(e)=>setInputValue(e.target.value)}
              fullWidth
              id="outlined-basic"
              size="small"
              label={`Enter ${desc}`}
              variant="outlined"
              />
          </Box>
            )}

          <Box sx={{ minWidth: 120 }} mt={"20px"}>
            <FormControl fullWidth>
              {desc == "Temperature" && (
                <>
                  <InputLabel id="demo-simple-select-label">Units</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                 
                    label="Age"
                    onChange={(e:any)=>setUnitValue(e.target.value)}
                  >
                    <MenuItem value={"Celsius"}>Celsius</MenuItem>
                    <MenuItem value={"Fahrenheit"}>Fahrenheit</MenuItem>
                  </Select>
                </>
              )}
              {desc == "Blood Pressure" && (
                <>
                  <InputLabel id="demo-simple-select-label">Units</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                   
                    label="Age"
                    onChange={(e:any)=>setUnitValue(e.target.value)}
                  >
                    <MenuItem value={"mmHg"}>mmHg</MenuItem>
                    {/* <MenuItem value={"Hg"}>Hg</MenuItem> */}
                  </Select>
                </>
              )}
              {desc == "Heart Rate" && (
                <>
                  <InputLabel id="demo-simple-select-label">Units</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
         
                    label="Age"
                    onChange={(e:any)=>setUnitValue(e.target.value)}
                  >
                    <MenuItem value={"BPM"}>BPM</MenuItem>
                  </Select>
                </>
              )}
              {desc == "Weight" && (
                <>
                  <InputLabel id="demo-simple-select-label">Units</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    //   value={age}
                    label="Age"
                    onChange={(e:any)=>setUnitValue(e.target.value)}
                  >
                    <MenuItem value={"Kilos"}>Kilos</MenuItem>
                    <MenuItem value={"Pounds"}>Pounds</MenuItem>
                  </Select>
                </>
              )}
              {desc == "Height" && (
                <>
                  <InputLabel id="demo-simple-select-label">Units</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    //   value={age}
                    label="Age"
                    onChange={(e:any)=>setUnitValue(e.target.value)}
                  >
                    <MenuItem value={"Feets"}>Feets</MenuItem>
                    <MenuItem value={"Meters"}>Meters</MenuItem>
                  </Select>
                </>
              )}
            </FormControl>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={"5px"} mt={"20px"}>

          <Button  onClick={()=>{handleSubmit()}}  variant="contained">Submit</Button>
          {/* <Button
          onClick={()=>{handleRemove()}}
  component="label"
  role={undefined}
  variant="contained"
  color="error"
  tabIndex={-1}
  startIcon={  <DeleteIcon fontSize="inherit" />}
>
  Remove
</Button>           */}
</Box>
        </Box>
      </Modal>
        <AppSnackbar
                isOpen={snackbar.open}
                title={snackbar.message}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                severity={snackbar.severity}
            />
    </div>
  );
}
export default DefaultBtnModel;