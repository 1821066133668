import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { IoIosCloseCircle } from "react-icons/io";
import { TextField } from "@mui/material";
import { fetchData, insertData, updatetDataById } from "api";
import { SnackbarState } from "models/user-models";
import AppSnackbar from "components/snakbar";
import { useNavigate } from "react-router-dom";
import { RectProps } from "@react-pdf/renderer";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

type PerkType =
  | "singleMonthly"
  | "singleYearly"
  | "groupMonthly"
  | "groupYearly";

interface GenericModalInter {
  name: string;
  isAnnual: boolean;
  essentialMonthlyPrice?: string;
  setEssentialMonthlyPrice?: React.Dispatch<React.SetStateAction<string>>;
  essentialYearlyPrice?: string;
  setEssentialYearlyPrice?: React.Dispatch<React.SetStateAction<string>>;
  performMonthlyPrice?: string;
  setPerformMonthlyPrice?: React.Dispatch<React.SetStateAction<string>>;
  performYearlyPrice?: string;
  setPerformYearlyPrice?: React.Dispatch<React.SetStateAction<string>>;
  numberOfUsers?: number;
  setNumberOfUsers?: React.Dispatch<React.SetStateAction<number>>;
  monthlyUsers?: number;
  setMonthlyUsers?: React.Dispatch<React.SetStateAction<number>>;
  yearlyUsers?: number;
  setYearlyUsers?: React.Dispatch<React.SetStateAction<number>>;
  groupPerks?: string;
  setGroupPerks?: React.Dispatch<React.SetStateAction<string>>;
  singlePerks?: string;
  setSinglePerks?: React.Dispatch<React.SetStateAction<string>>;
  setAllPerks?: React.Dispatch<React.SetStateAction<any[]>>;
  perks?: Record<PerkType, string>; // Add this line
  setPerks?: React.Dispatch<React.SetStateAction<Record<PerkType, string>>>; // And this line
  setPerkId?: React.Dispatch<React.SetStateAction<string>>;
  perkId?: string;
  update?: boolean;
  setUpdate?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface User {
  type: string;
}

interface subDataInter {
  added_by: string;
  added_on: string;
  duration: string;
  price: string;
  subscriptionPlan: string;
  _id: string;
}

const GenericModal: React.FC<GenericModalInter> = ({
  name,
  isAnnual,
  essentialMonthlyPrice,
  setEssentialMonthlyPrice,
  essentialYearlyPrice,
  setEssentialYearlyPrice,
  setPerformYearlyPrice,
  performYearlyPrice,
  setPerformMonthlyPrice,
  performMonthlyPrice,
  numberOfUsers,
  setNumberOfUsers,
  monthlyUsers,
  setMonthlyUsers,
  yearlyUsers,
  setYearlyUsers,
  groupPerks,
  setGroupPerks,
  singlePerks,
  setSinglePerks,
  setAllPerks,
  setPerks,
  perks,
  perkId,
  setPerkId,
  update,
  setUpdate,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    setUpdate(false);
  };
  const [subscriptionData, setSubscriptionData] = React.useState<any[]>([]);
  const [snackbar, setSnackbar] = React.useState<SnackbarState>({
    open: false,
    message: "",
    severity: "info",
  });

  let user: User | null = null;
  const storedUser = localStorage.getItem("user");
  if (storedUser) {
    try {
      user = JSON.parse(storedUser);
    } catch (e) {
      console.error("Failed to parse user data from localStorage", e);
    }
  }

  const handleOpen = () => {
    if (user && user.type === "1") {
      setOpen(true);
    } else {
      alert("user is not admin");
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (name === "essential") {
      isAnnual
        ? setEssentialMonthlyPrice && setEssentialMonthlyPrice(e.target.value)
        : setEssentialYearlyPrice && setEssentialYearlyPrice(e.target.value);
    }
    if (name === "Perform") {
      isAnnual
        ? setPerformMonthlyPrice && setPerformMonthlyPrice(e.target.value)
        : setPerformYearlyPrice && setPerformYearlyPrice(e.target.value);
    }
  };

  const getSubscriptions = async () => {
    try {
      const res = await fetchData("subscriptions");
      if (res.success) {
        setSubscriptionData(res.data);

        res.data.forEach((e: any) => {
          if (e.subscriptionPlan === "solo") {
            if (e.duration === "monthly") {
              setEssentialMonthlyPrice && setEssentialMonthlyPrice(e.price);
            } else if (e.duration === "yearly") {
              setEssentialYearlyPrice && setEssentialYearlyPrice(e.price);
            }
          } else if (e.subscriptionPlan === "group") {
            if (e.duration === "monthly") {
              setPerformMonthlyPrice && setPerformMonthlyPrice(e.price);
              setMonthlyUsers && setMonthlyUsers(e.users);
              setNumberOfUsers && setNumberOfUsers(e.users);
            } else if (e.duration === "yearly") {
              setPerformYearlyPrice && setPerformYearlyPrice(e.price);
              setNumberOfUsers && setNumberOfUsers(e.users);
              setYearlyUsers && setYearlyUsers(e.users);
            }
            // if (e.users) {
            //     setNumberOfUsers && setNumberOfUsers(e.users);
            // }
          }
        });
      } else {
        alert("No subscriptions found");
      }
    } catch (error: any) {
      const alreadyLoggedIn = error.message.endsWith("401");
      if (alreadyLoggedIn) {
        setSnackbar({
          open: true,
          message: "logged in from some other device",
          severity: "error",
        });
        setTimeout(() => {
          navigate("/auth/sign-in");
        }, 3000);
      }
    }
  };

  const getPerks = async () => {
    try {
      const res = await fetchData("Perks");
      if (res.success) {
        setAllPerks(res.data);
      }
    } catch (error: any) {
      console.log("error in fetching data----------", error);
      alert("error in fetching Perks");
    }
  };

  React.useEffect(() => {
    getSubscriptions();
  }, []);

  const handleSubmit = async () => {
    const isMonthly = isAnnual ? "monthly" : "yearly";
    const soloPlan = "solo";
    const groupPlan = "group";

    const checkAndUpdateSubscription = async (
      plan: string,
      duration: string,
      price: string,
      customId: string,
      users?: number
    ) => {
      const existingSubscription = subscriptionData.find(
        (e: any) => e.subscriptionPlan === plan && e.duration === duration
      );
      
      if (existingSubscription) {
        const res = await updatetDataById(
          "subscriptions",
          { subscriptionPlan: plan, duration, price, customId, users },
          existingSubscription._id
        );
        if (res.success) {
          console.log(`${plan} ${duration} updated successfully`, res.data);
          getSubscriptions();
        } else {
          alert(res.message);
        }
      } else {
        const res = await insertData("subscriptions", {
          subscriptionPlan: plan,
          duration,
          price,
          customId,
          users,
        });
        if (res.success) {
          getSubscriptions();
          setOpen(false);
        } else {
          alert(res.message);
        }
      }
    };

    if (name === "essential") {
      const price = isAnnual ? essentialMonthlyPrice : essentialYearlyPrice;
      const customId = isAnnual ? "soloMonthly123" : "soloYearly456";
      if (
        update === false &&
        (isAnnual ? perks.singleMonthly !== "" : perks.singleYearly !== "")
      ) {
        const res = await insertData("Perks", {
          package_name: isAnnual ? "singleMonthly" : "singleYearly",
          perk: isAnnual ? perks.singleMonthly : perks.singleYearly,
        });

        if (res.success) {
          setPerks((prevPerks) => ({
            ...prevPerks,
            [isAnnual ? "singleMonthly" : "singleYearly"]: "",
          }));
          getPerks();
        } else {
          alert("error in inserting Perks");
        }
      }

      if (update && perkId) {
        await updatetDataById(
          "Perks",
          { perk: isAnnual ? perks.singleMonthly : perks.singleYearly },
          perkId
        );
        setPerks((prevPerks) => ({
          ...prevPerks,
          [isAnnual ? "singleMonthly" : "singleYearly"]: "",
        }));
        getPerks();
      }
      if (price !== "") {
        checkAndUpdateSubscription(soloPlan, isMonthly, price, customId);
      }
    }

    if (name === "Perform") {
      const price = isAnnual ? performMonthlyPrice : performYearlyPrice;
      const customId = isAnnual ? "groupMonthly123" : "groupYearly456";
      const users = isAnnual ? monthlyUsers : yearlyUsers;
      const validateFields = () => {
        if (!price || !users) {
          return false;
        }
        return true;
      };

      if (!validateFields()) {
        setSnackbar({
          open: true,
          message: !price
            ? "Price is required"
            : "Number of Users are required",
          severity: "error",
        });
        return;
      }

      if (
        update === false &&
        (isAnnual ? perks.groupMonthly !== "" : perks.groupYearly !== "")
      ) {
        const res = await insertData("Perks", {
          package_name: isAnnual ? "groupMonthly" : "groupYearly",
          perk: isAnnual ? perks.groupMonthly : perks.groupYearly,
        });

        if (res.success) {
          setPerks((prevPerks) => ({
            ...prevPerks,
            [isAnnual ? "groupMonthly" : "groupYearly"]: "",
          }));
          getPerks();
        } else {
          alert("error in inserting Perks");
        }
      }
      if (update && perkId) {
        await updatetDataById(
          "Perks",
          { perk: isAnnual ? perks.groupMonthly : perks.groupYearly },
          perkId
        );
        setPerks((prevPerks) => ({
          ...prevPerks,
          [isAnnual ? "groupMonthly" : "groupYearly"]: "",
        }));
        getPerks();
      }
      
      if (price && numberOfUsers) {
        checkAndUpdateSubscription(
          groupPlan,
          isMonthly,
          price,
          customId,
          users
        );
      }
    }

    handleClose();
  };
  // if(update){
  //     handleOpen
  // }
  // React.useEffect(()=>{
  //     handleOpen()
  // },[update])
  return (
    <div>
      {user && user.type === "1" && (
        <Button
          onClick={handleOpen}
          sx={{ bgcolor: "#6366F1", "&:hover": { bgcolor: "#4F46E5" } }}
          size="small"
          variant="contained"
        >
          Edit
        </Button>
      )}
      <Modal
        open={update ? update : open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box sx={style}>
            {name === "essential" && (
              <Box>
                <div className="text-slate-900 dark:text-slate-200 mb-4 text-3xl font-semibold">
                  Essential
                </div>
                <h4 className=" text-md  mb-4">Change the price:</h4>
                <Box width={"100%"}>
                  <TextField
                    sx={{
                      // Hide stepper (spinner) using CSS
                      "& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button":
                        {
                          WebkitAppearance: "none",
                          margin: 0,
                        },
                    }}
                    type="number"
                    value={
                      isAnnual ? essentialMonthlyPrice : essentialYearlyPrice
                    }
                    onChange={handleInputChange}
                    fullWidth
                    id="outlined-basic"
                    label="Enter the price"
                    variant="outlined"
                  />
                  <TextField
                    disabled
                    sx={{ mt: "20px" }}
                    value={1}
                    onChange={handleInputChange}
                    fullWidth
                    id="outlined-basic"
                    label="Number of users"
                    variant="outlined"
                  />

                  <TextField
                    sx={{ mt: "20px" }}
                    value={isAnnual ? perks.singleMonthly : perks.singleYearly}
                    onChange={(e) => {
                      const value = e.target.value;
                      setPerks((prevPerks) => ({
                        ...prevPerks,
                        [isAnnual ? "singleMonthly" : "singleYearly"]: value,
                      }));
                    }}
                    fullWidth
                    id="outlined-basic"
                    label="Enter Perks"
                    variant="outlined"
                  />
                </Box>
              </Box>
            )}

            {name === "Perform" && (
              <Box>
                <div className="text-slate-900 dark:text-slate-200 mb-4 text-3xl font-semibold">
                  Perform
                </div>
                <h4 className=" text-md  mb-4">Change the price:</h4>
                <Box width={"100%"}>
                  <TextField
                    sx={{
                      // Hide stepper (spinner) using CSS
                      "& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button":
                        {
                          WebkitAppearance: "none",
                          margin: 0,
                        },
                    }}
                    type="number"
                    value={isAnnual ? performMonthlyPrice : performYearlyPrice}
                    onChange={handleInputChange}
                    fullWidth
                    id="outlined-basic"
                    label="Enter the price"
                    variant="outlined"
                  />
                  {/* <TextField sx={{
                mt:"20px",
    // Hide stepper (spinner) using CSS
    '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      
    }}} type='number'  value={numberOfUsers} onChange={(e:any)=>setNumberOfUsers && setNumberOfUsers(e.target.value)} fullWidth id="outlined-basic" label="Number of users" variant="outlined" /> */}

                  <TextField
                    sx={{
                      mt: "20px",
                      "& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button":
                        {
                          WebkitAppearance: "none",
                        },
                    }}
                    type="number"
                    value={isAnnual ? monthlyUsers : yearlyUsers}
                    onChange={(e: any) => {
                      // const value = e.target.value
                      //   ? Number(e.target.value)
                      //   : undefined;
                      // isAnnual ? setMonthlyUsers(value) : setYearlyUsers(value);
                      const value = e.target.value ? e.target.value : "";
                      setNumberOfUsers(value);
                      isAnnual ? setMonthlyUsers(value) : setYearlyUsers(value);
                    }}
                    fullWidth
                    id="outlined-basic"
                    label="Number of users"
                    variant="outlined"
                  />

                  <TextField
                    sx={{ mt: "20px" }}
                    value={isAnnual ? perks.groupMonthly : perks.groupYearly}
                    onChange={(e) => {
                      const value = e.target.value;
                      setPerks((prevPerks) => ({
                        ...prevPerks,
                        [isAnnual ? "groupMonthly" : "groupYearly"]: value,
                      }));
                    }}
                    fullWidth
                    id="outlined-basic"
                    label="Enter Perks"
                    variant="outlined"
                  />
                </Box>
              </Box>
            )}
            <Box mt={"10px"}>
              <Button
                onClick={handleSubmit}
                sx={{ bgcolor: "#6366F1", "&:hover": { bgcolor: "#4F46E5" } }}
                variant="contained"
              >
                Submit
              </Button>
            </Box>
          </Box>
        </>
      </Modal>
      <AppSnackbar
        isOpen={snackbar.open}
        title={snackbar.message}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        severity={snackbar.severity}
      />
    </div>
  );
};

export default GenericModal;
