import React, { ChangeEvent, useState } from "react";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";

function InputField(props: {
  id: string;
  name?: string;
  label: string;
  extra: string;
  placeholder: string;
  variant: string;
  state?: string;
  disabled?: boolean;
  type?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  value?: string;
  importantField?: boolean;
  hideLabel?: boolean;
}) {
  const { label, id, extra, type, placeholder, variant, state, disabled, value, importantField, hideLabel } =
    props;

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className={`${extra}`}>
      {!hideLabel && <label
        htmlFor={id}
        className={`font-poppins xl:text-[16px] text-sm text-navy-700 dark:text-white ${variant === "auth" ? "ml-1.5 font-medium" : "ml-3 font-bold"
          }`}
      >
        {label} {importantField && <span className="!text-[#ff0000]">*</span>}
      </label>}
      <div className="relative">
        <input
          name={props.name ? props.name : ""}
          disabled={disabled}
          type={type === "password" && !showPassword ? "password" : "text"}
          id={id}
          placeholder={placeholder}
          onChange={(e: ChangeEvent<HTMLInputElement>) => props.onChange(e)}
          value={value}
          className={`mt-2 w-full flex h-12 items-center justify-center rounded-xl border bg-white/0 p-3 xl:text-[16px] text-sm outline-none ${disabled === true
            ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
            : state === "error"
              ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
              : state === "success"
                ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
                : "border-gray-200 dark:!border-white/10 dark:text-white"
            }`}
        />
        {type === "password" && (
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className="absolute top-0 right-0 mt-3 mr-3 p-1 rounded-lg text-gray-400 hover:text-gray-700 dark:text-white dark:hover:text-gray-300"
          >
            {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
          </button>
        )}
      </div>
    </div>
  );
}

export default InputField;
