import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';

interface Props {
    isOpen: boolean,
    onClose: () => void;
    title: string
    severity: 'error' | 'warning' | 'info' | 'success'; // Specify the exact string values for better type safety
}

const AppSnackbar = ({ isOpen, onClose, title,severity  }: Props) => {
    return (
        <Snackbar
            open={isOpen}
            autoHideDuration={3000}
            onClose={onClose}
            
        >
            <Alert
                onClose={onClose}
                severity={severity }
                variant="filled"
                sx={{ width: '100%' }}
            >
                {title}
            </Alert>

        </Snackbar>
    )
}

export default AppSnackbar