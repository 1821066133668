import React, { Fragment, useEffect, useState } from "react";
// import Banner from "./components/Banner";
// import NFt2 from "assets/img/nfts/Nft2.png";
// import NFt4 from "assets/img/nfts/Nft4.png";
// import NFt3 from "assets/img/nfts/Nft3.png";
// import NFt5 from "assets/img/nfts/Nft5.png";
// import NFt6 from "assets/img/nfts/Nft6.png";
// import avatar1 from "assets/img/avatars/avatar1.png";
// import avatar2 from "assets/img/avatars/avatar2.png";
// import avatar3 from "assets/img/avatars/avatar3.png";

// import tableDataTopCreators from "views/admin/marketplace/variables/tableDataTopCreators";
// import HistoryCard from "./components/HistoryCard";
// import TopCreatorTable from "./components/TableTopCreators";
// import NftCard from "components/card/NftCard";
import Card from "components/card";
import { BsFillNodePlusFill } from "react-icons/bs";
import { Dialog, Transition } from '@headlessui/react'
import InputField from "components/fields/InputField";
import GenericModal from "components/Modal/Modal";
import { Button } from "@mui/material";
import axios from "axios";
import { deleteDataById, fetchData, stripeApi, updatetDataById } from "api";
import SubscriptionCode from "components/SubscriptionCode/SubscriptionCode";
import PerkManager from "components/Perks";
import { MdDeleteSweep } from "react-icons/md";
import { FaEdit } from "react-icons/fa";


interface User {
  email: string;
  contact?: string; // Add optional properties as needed
}


const plan = [
  {link:"https://buy.stripe.com/test_7sI28K3pCcI80vKdQQ",priceId:"price_1PlUYZP8lnHYI0H4UFdYPr3C",price:"",duration:"",plan:"sinleMonthly"},
  {link:"https://buy.stripe.com/test_6oEaFg2lycI82DS3cd",priceId:"price_1PlUfEP8lnHYI0H4fz8pKbfc",price:"",duration:"",plan:"sinleYearly"},
  {link:"https://buy.stripe.com/test_eVafZA4tGfUkdiwfZ0",priceId:"price_1PlUYZP8lnHYI0H4UFdYPr3C",price:"",duration:"",plan:"groupMonthly"},
  {link:"https://buy.stripe.com/test_cN2eVwgco23ua6k3cf",priceId:"price_1PlUfEP8lnHYI0H4fz8pKbfc",price:"",duration:"",plan:"groupYearly"},
]
type PerkType = 'singleMonthly' | 'singleYearly' | 'groupMonthly' | 'groupYearly';
const Marketplace = () => {
  const [isAnnual, setIsAnnual] = React.useState(true);
  const [open, setOpen] = React.useState(false)
  const cancelButtonRef = React.useRef(null)
  const [essentialMonthlyPrice,setEssentialMonthlyPrice] = React.useState("29")
  const [essentialYearlyPrice,setEssentialYearlyPrice] = React.useState("65")
  const [performMonthlyPrice,setPerformMonthlyPrice] = React.useState("222")
  const [performYearlyPrice,setPerformYearlyPrice] = React.useState("333")
  const [numberOfUsers,setNumberOfUsers] = React.useState<number | undefined>(undefined);
  const [monthlyUsers, setMonthlyUsers] = React.useState<number | undefined>(undefined);
const [yearlyUsers, setYearlyUsers] = React.useState<number | undefined>(undefined);
const [singlePerks, setSinglePerks] = useState<string>("");
const [groupPerks,setGroupPerks] = useState<string>("")
const [singleMonthly,setSingleMonthly] = useState<any[]>()
const [singleYearlyPerks,setSingleYearlyPerks] = useState<any[]>()
const [groupMonthly,setGroupMonthly] = useState<any[]>()
const [groupYearlyPerks,setGroupYearlyPerks] = useState<any[]>()

const [perks, setPerks] = useState<Record<PerkType, string>>({
  singleMonthly: "",
  singleYearly: "",
  groupMonthly: "",
  groupYearly: ""
});
const [allPerks, setAllPerks] = useState<any[]>([]);
const [editingPerk, setEditingPerk] = useState<{ id: string | null, type: PerkType | null }>({ id: null, type: null });
  const [items,setItems] = React.useState([
  ])
  const [address,setAddress] =  useState({})
  const [openSubscriptonCode,setOpenSubscriptionCode] = React.useState(false)
  const [subscriptionCode,setSubscriptionCode] = React.useState("")
  const [localUser,setLocalUser] = useState(null)
  const [perkId,setPerkId] = useState<string| undefined>()
  const [update,setUpdate] = useState(false)
  // function classNames(...classes: (string | undefined | null | false)[]): string {
  //   return classes.filter(Boolean).join(' ');
  // }

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      try {
        const parsedUser: User = JSON.parse(user);
        
        setLocalUser(parsedUser)
        setAddress({ email: parsedUser.email, contact: parsedUser.contact });
      } catch (error) {
        
      }
    }
  }, []);

  useEffect(()=>{
    const stripePayment = async () => {
      
      let orderData = {
        address,
        items,
        amount: 500,
      };
    
      try {
        // let response = await axios.post(`${url}/payment`, orderData); // Corrected URL
        let response = await stripeApi(orderData)
        
        if(response?.data){
          window.location.replace(response?.data.session_url)
          

        }
      } catch (error) {
        console.error("API error:", error);
      }
    };
    if (items.length > 0) {
    stripePayment();
  }
  },[items])
  const copyToClipboard = () => {
    if (localUser?.randomCode) {
      navigator.clipboard.writeText(localUser.randomCode).then(() => {
        alert('Code copied to clipboard!');
      }).catch(err => {
        console.error('Failed to copy: ', err);
      });
    }
  };
const handlePay = (plan:string) => {
  function generateRandomCode(length = 8) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}



  if(plan === "soloYearly"){

    setItems([{
      name:"soloYearly",
      price:essentialYearlyPrice,
      description:"purchasing featrue yearly",
      quantity:1,
      numberOfUsers:1,
      randomCode:generateRandomCode(),
      priceId:"price_1PlUfEP8lnHYI0H4fz8pKbfc",
        productId:"prod_Qck2uSpfcgXW61"
    }])
  }
  if(plan === "soloMonthly"){

    setItems([{
      name:"soloMonthly",
      price:essentialMonthlyPrice,
      description:"purchasing featrue",
      quantity:1,
      numberOfUsers:1,
      randomCode:generateRandomCode(),
      priceId:"price_1PlUYZP8lnHYI0H4UFdYPr3C",
        productId:"prod_Qck2uSpfcgXW61"
    }])
  }
  if(plan === "groupMonthly"){

    setItems([{
      name:"groupMonthly",
      price:performMonthlyPrice,
      description:"purchasing featrue",
      quantity:1,
      numberOfUsers:monthlyUsers,
      randomCode:generateRandomCode(),
      priceId:"price_1Pll9nP8lnHYI0H4LJlb6PLF",
        productId:"prod_Qd1C5AEOCFPIjI"
    }])
  }
  if(plan === "groupYearly"){

    setItems([{
      name:"groupYearly",
      price:performYearlyPrice,
      description:"purchasing featrue",
      quantity:1,
      numberOfUsers:yearlyUsers,
      randomCode:generateRandomCode(),
      priceId:"price_1PllCAP8lnHYI0H4Ayb5neWq",
      productId:"prod_Qd1C5AEOCFPIjI"
    }])
  }
}








const handleUpdate = (perkId:string) => {
setPerkId(perkId)
setUpdate(true)
}

const handlePerkDelete = async (perkId:any) =>{
  
    try {
      // const perkValue = perks[editingPerk.type];
      // await updatetDataById("Perks", {  perk:"update" }, perkId);
    await  deleteDataById("Perks",perkId)
      // setSnackbar({ open: true, message: "Perk updated successfully", severity: 'success' });
      // Refresh perk list
      const res = await fetchData("Perks");
      if (res.success) {
        setAllPerks(res.data);
      }
      // setEditingPerk({ id: null, type: null });
    } catch (error) {
      // setSnackbar({ open: true, message: "Error updating perk", severity: 'error' });
    }
  
}
const handlePerkEdit = async () =>{
  
    try {

      const res = await fetchData("Perks");
      if (res.success) {
        setAllPerks(res.data);
      }
      // setEditingPerk({ id: null, type: null });
    } catch (error) {
      // setSnackbar({ open: true, message: "Error updating perk", severity: 'error' });
    }
  
}
useEffect(()=>{
  handlePerkEdit()
},[])

useEffect(()=>{
 const signleMOnthly = allPerks.filter((e:any)=> e.package_name === "singleMonthly")
 const signleYearly = allPerks.filter((e:any)=> e.package_name === "singleYearly")
 const groupMonthly = allPerks.filter((e:any)=> e.package_name === "groupMonthly")
 const groupYearly = allPerks.filter((e:any)=> e.package_name === "groupYearly")
 
 setSingleMonthly(signleMOnthly)
 setSingleYearlyPerks(signleYearly)
 setGroupMonthly(groupMonthly)
 setGroupYearlyPerks(groupYearly)
},[allPerks])
  return (
    <div className="mt-3">
      <Card extra={"w-full h-full sm:overflow-auto px-8 mt-5"}>
        <header className="relative flex items-center justify-between py-5">
          <div className="text-[26px] font-semibold w-[100%] sm:w-[70%]"></div>

          {/* <button onClick={() => setOpen(true)} className={`flex items-center text-sm hover:cursor-pointer bg-lightPrimary p-3 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10" linear justify-center rounded-lg font-bold transition duration-200`}>
            Add Subscription &nbsp;&nbsp; <BsFillNodePlusFill />
          </button> */}
        </header>

        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-100 w-2xl overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg !bg-white dark:!bg-navy-900 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl">
                    <div className="!bg-white dark:!bg-navy-900 px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title as="h2" className="text-2xl font-semibold leading-6 text-navy-700 dark:text-white">
                          Add a new Subscription Plan
                        </Dialog.Title>
                        <div className="mt-10 flex justify-between items-center w-[100%]">
                          <div className="w-[100%] mr-5">
                            <InputField
                              variant="auth"
                              extra="mb-3"
                              label="Title"
                              placeholder="Premium Plan"
                              id="plan-title"
                              type="text"
                            />
                          </div>
                          <div className="w-[100%]">
                            <InputField
                              variant="auth"
                              extra="mb-3"
                              label="Subtitle"
                              placeholder="There are many variations available"
                              id="plan-subtitle"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="flex justify-between items-center w-[100%]">
                          <div className="w-[100%] mr-5">
                            <InputField
                              variant="auth"
                              extra="mb-3"
                              label="Price"
                              placeholder="49$"
                              id="plan-price"
                              type="text"
                            />
                          </div>
                          <div className="w-[100%] mr-5">
                            <InputField
                              variant="auth"
                              extra="mb-3"
                              label="Plan"
                              placeholder="Monthly / Yearly"
                              id="plan-mo-yr"
                              type="text"
                            />
                          </div>
                          <div className="w-[100%]">
                            <InputField
                              variant="auth"
                              extra="mb-3"
                              label="Tag"
                              placeholder="Most popular"
                              id="plan-tag"
                              type="text"
                            />
                          </div>
                        </div>
                        <label htmlFor="desc" className={`font-poppins text-sm text-navy-700 dark:text-white ml-1.5 font-medium`} >
                          Description
                        </label>
                        <textarea id="desc" rows={5} className="mt-2 flex w-[100%] items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none resize-none" placeholder="Subscription information" />
                      </div>
                    </div>
                    <div className="!bg-white dark:!bg-navy-900 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button onClick={() => setOpen(false)}
                        type="button"
                        className="linear w-[100px] rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200" >
                        Save
                      </button>
                      <button onClick={() => setOpen(false)}
                        type="button"
                        className="flex w-[100px] items-center text-sm hover:cursor-pointer bg-lightPrimary p-3 mr-4 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10 linear justify-center rounded-lg font-bold transition duration-200" >
                        Cancel
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>


        <div className="relative font-inter antialiased mt-3">
          <main className="relative min-h-screen flex flex-col justify-start bg-slate-50 overflow-hidden">
            <div className="w-full max-w-6xl mx-auto px-4 md:px-6">
              {/* Pricing table component */}
              <div>
                {/* Pricing toggle */}
                <div className="flex justify-center max-w-[14rem] m-auto mb-8 lg:mb-16">
                  <div className="relative flex w-full p-1 bg-white dark:bg-slate-900 rounded-full">
                    <span className="absolute inset-0 m-1 pointer-events-none" aria-hidden="true">
                      <span className={`absolute inset-0 w-1/2 bg-indigo-500 rounded-full shadow-sm shadow-indigo-950/10 transform transition-transform duration-150 ease-in-out ${isAnnual ? 'translate-x-0' : 'translate-x-full'}`}>
                      </span>
                    </span>

                    <button className={`relative flex-1 text-sm font-medium h-8 rounded-full focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150 ease-in-out ${isAnnual ? 'text-white' : 'text-slate-500 dark:text-slate-400'}`}
                      onClick={() => setIsAnnual(true)}
                      aria-pressed={isAnnual}>
                      Monthly
                    </button>
                    <button className={`relative flex-1 text-sm font-medium h-8 rounded-full focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150 ease-in-out ${isAnnual ? 'text-slate-500 dark:text-slate-400' : 'text-white'}`}
                      onClick={() => setIsAnnual(false)}
                      aria-pressed={!isAnnual}>
                      Yearly
                      <span className={`${isAnnual ? 'text-indigo-200' : 'text-slate-400 dark:text-slate-500'}`}>-20%</span>
                    </button>
                  </div>
                </div>

                <div className="max-w-sm mx-auto grid gap-6 lg:grid-cols-2 items-start lg:max-w-none">

                  {/* Pricing tab 1 */}
                  <div className="h-full">
                    <div className="relative flex flex-col h-full p-6 rounded-2xl bg-white dark:bg-slate-900 border border-slate-200 dark:border-slate-900 shadow shadow-slate-950/5">
                      <div className="mb-5">
                        <div className="text-slate-900 dark:text-slate-200 font-semibold mb-1">Single</div>
                        <div className="flex items-center justify-between">

                        <div className="inline-flex items-baseline mb-2">
                          <span className="text-slate-900 dark:text-slate-200 font-bold text-3xl">$</span>
                          <span className="text-slate-900 dark:text-slate-200 font-bold text-4xl">{isAnnual ? essentialMonthlyPrice : essentialYearlyPrice}</span>
                          <span className="text-slate-500 font-medium">/{isAnnual ? 'mo' : 'yr'}</span>
                        </div>
                          <GenericModal update={update} setUpdate={setUpdate} perks={perks} setPerks={setPerks} setAllPerks={setAllPerks} singlePerks={singlePerks} setSinglePerks={setSinglePerks} name={"essential"} isAnnual={isAnnual} setEssentialMonthlyPrice={setEssentialMonthlyPrice} essentialMonthlyPrice={essentialMonthlyPrice} setEssentialYearlyPrice={setEssentialYearlyPrice} essentialYearlyPrice={essentialYearlyPrice}/>
                        </div>
                        <div className="text-sm text-slate-500 mb-5">There are many variations available, but the majority have suffered.</div>
                        <a onClick={()=>handlePay(isAnnual?"soloMonthly":"soloYearly")} className="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-indigo-500 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 hover:bg-indigo-600 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150" href={"#0"}>
                        {isAnnual?localUser?.package_name === "soloMonthly" ? "Subscribed":"Purchase Plan":localUser?.package_name === "soloYearly" ? "Subscribed":"Purchase Plan"}
                        </a>
                     
                      </div>
                      {/* <Button >pay</Button> */}
                      
                      <div className="text-slate-900 dark:text-slate-200 font-medium mb-3">Includes:</div>
                      <ul className="text-slate-600 dark:text-slate-400 text-sm space-y-3 grow">
                        {
                        isAnnual?(
                          singleMonthly &&   singleMonthly.length>0 && singleMonthly.map((e:any)=>{
                            return(
                              <li className="flex items-center justify-between gap-5">
                          <div className="flex items-center">
    
                                <svg className="w-3 h-3 fill-emerald-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                </svg>
                                <span>{e.perk}</span>
                                </div>
                                {
                                  localUser.type === "1" && (
                                    <>
                                  <div className="flex items-center gap-2">

                                  <span className="text-gray-700 text-2xl cursor-pointer" onClick={()=>handlePerkDelete(e._id)}><MdDeleteSweep /></span>
                                  <span className="text-[#6366F1] text-xl cursor-pointer" onClick={()=>handleUpdate(e._id)}><FaEdit /></span>
    </div>
                                    </>
                                  )
                                }
                              </li>
      
                                )
                          })
                        ):(    singleYearlyPerks &&   singleYearlyPerks.length>0 && singleYearlyPerks.map((e:any)=>{
                          return(
                            <li className="flex items-center justify-between gap-5">
                        <div className="flex items-center">
  
                              <svg className="w-3 h-3 fill-emerald-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                              </svg>
                              <span>{e.perk}</span>
                              </div>
                              {
                                  localUser.type === "1" && (
                                    <>
                                    <div className="flex items-center gap-2">

                                    <span className="text-gray-700 text-2xl cursor-pointer" onClick={()=>handlePerkDelete(e._id)}><MdDeleteSweep /></span>
                                    <span className="text-[#6366F1] text-xl cursor-pointer" onClick={()=>handleUpdate(e._id)}><FaEdit /></span>
                                    </div>
                                {/* <span></span> */}
          

                                    </>
                                  )
                                }
                            </li>
    
                              )
                        }))}
                        {/* <li className="flex items-center">
                          <svg className="w-3 h-3 fill-emerald-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                          </svg>
                          <span>Consectetur adipiscing elit</span>
                        </li>
                        <li className="flex items-center">
                          <svg className="w-3 h-3 fill-emerald-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                          </svg>
                          <span>Excepteur sint occaecat cupidatat</span>
                        </li>
                        <li className="flex items-center">
                          <svg className="w-3 h-3 fill-emerald-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                          </svg>
                          <span>Officia deserunt mollit anim</span>
                        </li> */}
                      </ul>
                    </div>
                  </div>

                  <div className="h-full">
                    <div className="relative flex flex-col h-full p-6 rounded-2xl bg-white dark:bg-slate-900 border border-slate-200 dark:border-slate-900 shadow shadow-slate-950/5">
                      <div className="absolute top-0 right-0 mr-6 -mt-4">
                        <div className="inline-flex items-center text-xs font-semibold py-1.5 px-3 bg-blue-700 dark:bg-white rounded-full text-white dark:text-black shadow-sm shadow-slate-950/5">Most Popular</div>
                      </div>
                      <div className="mb-5">
                        <div className="text-slate-900 dark:text-slate-200 font-semibold mb-1">Group</div>
                        <div className="flex items-center justify-between">

                        <div className="inline-flex items-baseline mb-2">
                          <span className="text-slate-900 dark:text-slate-200 font-bold text-3xl">$</span>
                          <span className="text-slate-900 dark:text-slate-200 font-bold text-4xl">{isAnnual ? performMonthlyPrice : performYearlyPrice}</span>
                          <span className="text-slate-500 font-medium">/{isAnnual ? 'mo' : 'yr'}</span>
                        </div>
                        <GenericModal setUpdate={setUpdate} update={update} perkId={perkId} setPerkId={setPerkId} perks={perks} setPerks={setPerks} setAllPerks={setAllPerks} setGroupPerks={setGroupPerks} groupPerks={groupPerks}  name={"Perform"} isAnnual={isAnnual} setPerformMonthlyPrice={setPerformMonthlyPrice} performMonthlyPrice={performMonthlyPrice} setPerformYearlyPrice={setPerformYearlyPrice} performYearlyPrice={performYearlyPrice} setNumberOfUsers={setNumberOfUsers} numberOfUsers={numberOfUsers}  setYearlyUsers={setYearlyUsers} yearlyUsers={yearlyUsers} setMonthlyUsers={setMonthlyUsers} monthlyUsers={monthlyUsers}/>
                        </div>
                        <div className="text-sm text-slate-500 mb-5">There are many variations available, but the majority have suffered.</div>
                        <a onClick={()=>handlePay(isAnnual?"groupMonthly":"groupYearly")} className="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-indigo-500 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 hover:bg-indigo-600 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150" href={"#0"}>
                    {isAnnual?localUser?.package_name === "groupMonthly" || localUser?.package_name === "groupMonthlycodeBased" ? "Subscribed":"Purchase Plan":localUser?.package_name === "groupYearly" || localUser?.package_name === "groupYearlycodeBased" ? "Subscribed":"Purchase Plan"}
                          {/* Purchase Plan */}
                        </a>
                        {localUser?.package_name === "groupMonthly" ||  localUser?.package_name === "groupMonthlycodeBased" || localUser?.package_name === "groupYearly" || localUser?.package_name === "groupYearlycodeBased" ?<></>:(
                          <>
                        <div className="flex justify-between mt-5">
                        <h5>Have a code to get subscription?</h5>
                        <SubscriptionCode openSubscriptonCode={openSubscriptonCode} setOpenSubscriptionCode={setOpenSubscriptionCode} subscriptionCode={subscriptionCode} setSubscriptionCode={setSubscriptionCode}/>
                        <Button onClick={()=>setOpenSubscriptionCode(true)} sx={{bgcolor:"#6366F1","&:hover":{bgcolor:"#4F46E5"}}} size='small' variant='contained'>Code</Button>
                        </div>
                          </>
                        ) }
{isAnnual?
localUser?.subscriptionOwner && localUser.package_name === "groupMonthly" && (
  <div>

    <h2 className="mt-3 font-medium">Subscription Code</h2>
  <h2 
className=" text-[#4F46E5] font-medium cursor-pointer" 
onClick={copyToClipboard}
>
{localUser?.subscriptionOwner && localUser.randomCode}
</h2>
</div>
)
:  localUser?.subscriptionOwner && localUser.package_name === "groupYearly" && (
  <div>

    <h2 className="mt-3 font-medium">Subscription Code</h2>
  <h2 
className=" text-[#4F46E5] font-medium cursor-pointer" 
onClick={copyToClipboard}
>
{localUser?.subscriptionOwner && localUser.randomCode}
</h2>
</div>

)}
{/* {localUser?.subscriptionOwner && (
                        <div>

                          <h2 className="mt-3 font-medium">Subscription Code</h2>
                        <h2 
      className=" text-[#4F46E5] font-medium cursor-pointer" 
      onClick={copyToClipboard}
      >
      {localUser?.subscriptionOwner && localUser.randomCode}
    </h2>
      </div>

)} */}
                      </div>
                      {/* <Button >Pay</Button> */}
                      <div className="text-slate-900 dark:text-slate-200 font-medium mb-3">Includes:</div>
                      <ul className="text-slate-600 dark:text-slate-400 text-sm space-y-3 grow">
                      <li className="flex items-center  ">
                      <svg className="w-3 h-3 fill-emerald-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                              <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                            </svg>
                            Number of Users: {isAnnual? monthlyUsers:yearlyUsers}
                        </li>
                        {
                        isAnnual?(
                          groupMonthly &&   groupMonthly.length>0 && groupMonthly.map((e:any)=>{
                            return(
                          <li className="flex items-center justify-between gap-5">
                      <div className="flex items-center">

                            <svg className="w-3 h-3 fill-emerald-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                              <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                            </svg>
                            <span>{e.perk}</span>
                            </div>
                            {
                                  localUser.type === "1" && (
                                    <>
                                             <div className="flex items-center gap-2">

                                             <span className="text-gray-700 text-2xl cursor-pointer" onClick={()=>handlePerkDelete(e._id)}><MdDeleteSweep /></span>
                                             <span className="text-[#6366F1] text-xl cursor-pointer" onClick={()=>handleUpdate(e._id)}><FaEdit /></span>
    </div>
                                    </>
                                  )
                                }
                          </li>
  
                            )
                          })
                        ):(    groupYearlyPerks &&   groupYearlyPerks.length>0 && groupYearlyPerks.map((e:any)=>{
                          return(
                            <li className="flex items-center justify-between gap-5">
                        <div className="flex items-center">
  
                              <svg className="w-3 h-3 fill-emerald-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                              </svg>
                              <span>{e.perk}</span>
                              </div>
                              {
                                  localUser.type === "1" && (
                                    <>
                                                <div className="flex items-center gap-2">

<span className="text-gray-700 text-2xl cursor-pointer" onClick={()=>handlePerkDelete(e._id)}><MdDeleteSweep /></span>
<span className="text-[#6366F1] text-xl cursor-pointer" onClick={()=>handleUpdate(e._id)}><FaEdit /></span>
    </div>
                                    </>
                                  )
                                }
                            </li>
    
                              )
                        }))}
                        {/* <li className="flex items-center">
                          <svg className="w-3 h-3 fill-emerald-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                          </svg>
                          <span>Consectetur adipiscing elit</span>
                        </li>
                        <li className="flex items-center">
                          <svg className="w-3 h-3 fill-emerald-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                          </svg>
                          <span>Excepteur sint occaecat cupidatat</span>
                        </li>
                        <li className="flex items-center">
                          <svg className="w-3 h-3 fill-emerald-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                          </svg>
                          <span>Officia deserunt mollit anim</span>
                        </li> */}
                      </ul>
                    </div>
                  </div>






{/* <PerkManager/> */}

{/* 
                  <div className="h-full">
                    <div className="relative flex flex-col h-full p-6 rounded-2xl bg-white dark:bg-slate-900 border border-slate-200 dark:border-slate-900 shadow shadow-slate-950/5">
                      <div className="mb-5">
                        <div className="text-slate-900 dark:text-slate-200 font-semibold mb-1">Enterprise</div>
                        <div className="inline-flex items-baseline mb-2">
                          <span className="text-slate-900 dark:text-slate-200 font-bold text-3xl">$</span>
                          <span className="text-slate-900 dark:text-slate-200 font-bold text-4xl">{isAnnual ? '120' : '250'}</span>
                          <span className="text-slate-500 font-medium">/{isAnnual ? 'mo' : 'yr'}</span>
                        </div>
                        <div className="text-sm text-slate-500 mb-5">There are many variations available, but the majority have suffered.</div>
                        <a className="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-indigo-500 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 hover:bg-indigo-600 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150" href="#0">
                          Purchase Plan
                        </a>
                      </div>
                      <div className="text-slate-900 dark:text-slate-200 font-medium mb-3">Includes:</div>
                      <ul className="text-slate-600 dark:text-slate-400 text-sm space-y-3 grow">
                        <li className="flex items-center">
                          <svg className="w-3 h-3 fill-emerald-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                          </svg>
                          <span>Unlimited placeholder texts</span>
                        </li>
                        <li className="flex items-center">
                          <svg className="w-3 h-3 fill-emerald-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                          </svg>
                          <span>Consectetur adipiscing elit</span>
                        </li>
                        <li className="flex items-center">
                          <svg className="w-3 h-3 fill-emerald-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                          </svg>
                          <span>Excepteur sint occaecat cupidatat</span>
                        </li>
                        <li className="flex items-center">
                          <svg className="w-3 h-3 fill-emerald-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                          </svg>
                          <span>Officia deserunt mollit anim</span>
                        </li>
                        <li className="flex items-center">
                          <svg className="w-3 h-3 fill-emerald-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                          </svg>
                          <span>Predefined chunks as necessary</span>
                        </li>
                        <li className="flex items-center">
                          <svg className="w-3 h-3 fill-emerald-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                          </svg>
                          <span>Free from repetition</span>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </main>
        </div>

      </Card>



      {/* <div className="mt-3 grid h-full grid-cols-1 gap-5 xl:grid-cols-2 2xl:grid-cols-3"> */}
      <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">
        {/* NFt Banner */}
        {/* <Banner /> */}

        {/* NFt Header */}
        {/* <div className="mb-4 mt-5 flex flex-col justify-between px-4 md:flex-row md:items-center">
          <h4 className="ml-1 text-2xl font-bold text-navy-700 dark:text-white">
            Trending NFTs
          </h4>
          <ul className="mt-4 flex items-center justify-between md:mt-0 md:justify-center md:!gap-5 2xl:!gap-12">
            <li>
              <a
                className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                href=" "
              >
                Art
              </a>
            </li>
            <li>
              <a
                className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                href=" "
              >
                Music
              </a>
            </li>
            <li>
              <a
                className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                href=" "
              >
                Collection
              </a>
            </li>
            <li>
              <a
                className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                href=" "
              >
                <a href=" ">Sports</a>
              </a>
            </li>
          </ul>
        </div> */}

        {/* NFTs trending card */}
        {/* <div className="z-20 grid grid-cols-1 gap-5 md:grid-cols-3">
          <NftCard
            bidders={[avatar1, avatar2, avatar3]}
            title="Abstract Colors"
            author="Esthera Jackson"
            price="0.91"
            image={NFt3}
          />
          <NftCard
            bidders={[avatar1, avatar2, avatar3]}
            title="ETH AI Brain"
            author="Nick Wilson"
            price="0.7"
            image={NFt2}
          />
          <NftCard
            bidders={[avatar1, avatar2, avatar3]}
            title="Mesh Gradients"
            author="Will Smith"
            price="2.91"
            image={NFt4}
          />
        </div> */}

        {/* Recenlty Added setion */}
        {/* <div className="mb-5 mt-5 flex items-center justify-between px-[26px]">
          <h4 className="text-2xl font-bold text-navy-700 dark:text-white">
            Recently Added
          </h4>
        </div> */}

        {/* Recently Add NFTs */}
        {/* <div className="grid grid-cols-1 gap-5 md:grid-cols-3">
          <NftCard
            bidders={[avatar1, avatar2, avatar3]}
            title="Abstract Colors"
            author="Esthera Jackson"
            price="0.91"
            image={NFt4}
          />
          <NftCard
            bidders={[avatar1, avatar2, avatar3]}
            title="ETH AI Brain"
            author="Nick Wilson"
            price="0.7"
            image={NFt5}
          />
          <NftCard
            bidders={[avatar1, avatar2, avatar3]}
            title="Mesh Gradients"
            author="Will Smith"
            price="2.91"
            image={NFt6}
          />
        </div> */}
      </div>

      {/* right side section */}

      {/* <div className="col-span-1 h-full w-full rounded-xl 2xl:col-span-1">
        <TopCreatorTable tableData={tableDataTopCreators} />
        <div className="mb-5" />
        <HistoryCard />
      </div> */}
    </div>
  );
};

export default Marketplace;
