import React, { FC, Fragment } from 'react';
import { DialogProps } from 'models/general-models';
import { Dialog, Transition } from '@headlessui/react'
import InputField from 'components/fields/InputField';
import { FAQ, UpdateFAQ } from 'models/faq-models';
import { insertData, updatetDataById } from 'api';
import { faqs, symptoms } from 'api/collection_names';

const GeneralDialog: FC<DialogProps> = ({ open, dialogFor, onClose, title, isAdd, isUpdate, data, onChange }) => {
    const cancelButtonRef = React.useRef(null)

    const validateFields = () => {
        if (data) {
            for (const key of Object.keys(data) as (keyof UpdateFAQ | keyof FAQ)[]) {
                if (key === '_id') {
                    if ('_id' in data) {
                        const fieldValue = data._id;
                        if (fieldValue === "") {
                            return true;
                        }
                    }
                } else {
                    const fieldValue: string = data[key];
                    if (fieldValue === "") {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    const addData = async () => {
        if (dialogFor === "faq") {
            const res = await insertData(faqs, data);
            if (res.success) {
                alert("FAQ Saved Successfully");
                onClose(false);
            }
        }
    }

    const updateFaq = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            const res = await updatetDataById(faqs, data as UpdateFAQ, data._id);
            if (res.data) {
                alert("FAQ Updated Successfully");
            }
        }
    }

    const updateData = async () => {
        if (dialogFor === "faq") {
            updateFaq().then((res) => {
                onClose(false);
            }).catch((err) => console.log("Error", err));
        }
        else if (dialogFor === "suggestion") {

        }
    }

    return (
        <div>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={onClose}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-100 w-2xl overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg !bg-white dark:!bg-navy-900 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl">
                                    <div className="!bg-white dark:!bg-navy-900 px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                        <div className="sm:flex sm:items-start">
                                            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                <Dialog.Title as="h2" className="text-2xl font-semibold leading-6 text-navy-700 dark:text-white">
                                                    {title}
                                                </Dialog.Title>
                                                <div className='mt-10'>
                                                    <div className="w-[600px]">
                                                        <InputField
                                                            variant="auth"
                                                            extra="mb-3"
                                                            label="Title"
                                                            placeholder="When should i see a doctor?"
                                                            id="faq-title"
                                                            type="text"
                                                            name="title"
                                                            value={data?.title}
                                                            onChange={(e) => { onChange(e); }}
                                                            importantField
                                                        />
                                                    </div>
                                                    <div className="w-[600px]">
                                                        <InputField
                                                            variant="auth"
                                                            extra="mb-3"
                                                            label="Description"
                                                            placeholder="You can easily schedule an appointment..."
                                                            id="faq-description"
                                                            type="text"
                                                            name="description"
                                                            value={data?.description}
                                                            onChange={(e) => { onChange(e); }}
                                                            importantField
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="!bg-white dark:!bg-navy-900 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                        <button onClick={() => {
                                            if (validateFields()) {
                                                alert("Please fill all required fields");
                                                return;
                                            }
                                            if (isAdd) {
                                                addData().then((res) => console.log(res)).then((err) => console.error(err));
                                            }
                                            if (isUpdate) {
                                                updateData().then((res) => console.log(res)).then((err) => console.error(err));
                                            }
                                        }}
                                            type="button"
                                            className="linear w-[100px] rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200" >
                                            Save
                                        </button>
                                        <button onClick={() => onClose(false)}
                                            type="button"
                                            className="flex w-[100px] items-center text-sm hover:cursor-pointer bg-lightPrimary p-3 mr-4 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10 linear justify-center rounded-lg font-bold transition duration-200" >
                                            Cancel
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    );
};

export default GeneralDialog;