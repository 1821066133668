import { fetchData, getUrl, logoutUser } from 'api';
import { getUser, removeToken, saveUser } from 'auth/Authentication';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

const StripeSuccess = () => {
  const navigate  = useNavigate()
    const [status, setStatus] = useState(null);
    const [localUser,setLocalUser] = useState(null)
    const [queryStatus,setQueryStatus] = useState(null)
    const url = getUrl()
    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const sessionId = query.get("session_id");
        const query_Status = query.get("status");
        setQueryStatus(query_Status)
    
        if (sessionId) {
          // Verify the payment result
          axios.post(`${url}verify-payment`, { sessionId })
            .then(response => {
              setStatus(response.data.success ? "Payment successful!" : "Payment failed.");
              handleLogout();
            })
            .catch(error => {
              setStatus("Payment verification failed.");
            });
        } else {
          setStatus("No session ID provided.");
        }
        const handleLogout = async () => {
          // Fetch the local user details
          const local_User = await getUser();  
          
          // Set the local user state (if necessary)
          setLocalUser(local_User);
      
          // Fetch users data
          const users = await fetchData("Users");
      
          if (local_User) {
              // Find the subscribed user from the fetched users
              // const subscribedUser = users.data.filter((e: any) => e.email === local_User.email);
              const subscribedUser = users.data ? users.data.filter((e: any) => e.email === local_User.email) : [];

              
      
              // Ensure localUser is not null and subscribedUser has at least one entry
              if (subscribedUser.length > 0) {
                  const currentUser = local_User ; // Create a copy of localUser
                  
                  // Update the currentUser with the details from subscribedUser
                  currentUser.phone_number = subscribedUser[0]?.phone_number || '';
                  currentUser.package_name = subscribedUser[0]?.package_name || '';
                  currentUser.session_id = subscribedUser[0]?.session_id || '';
                  currentUser.expiryTime = subscribedUser[0]?.expiryTime || '';
                  currentUser.subscription = subscribedUser[0]?.subscription || '';
                  currentUser.subscriptionOwner = subscribedUser[0]?.subscriptionOwner || '';
                  currentUser.randomCode = subscribedUser[0]?.randomCode || '';
      
                  // Store updated user details in localStorage
                  

                  localStorage.setItem("user", JSON.stringify(currentUser));
              } else {
                  
              }
          } else {
              
          }
      };
      
      // Call the handleLogout function
      




      }, []);

  return (
    <>
    <div className="flex items-center justify-center ">
<div className="">
{queryStatus === "success"?(
  <>
   <h1 className='text-5xl mt-20'>Hurrah! Subscription Unlocked </h1>
   <h1 className='text-3xl mt-5'>Now you can add multiple reports</h1></>
):(
  
   <h1 className='text-5xl mt-20'>Subscription Failed </h1>
   
)}
   
</div>
    </div>
    </>
  )
}

export default StripeSuccess