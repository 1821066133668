import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import Page404Image from "assets/img/404/404Page.png";
const Page404 = () => {
    const navigate = useNavigate();

    return (
        <Fragment>
            <div>
                <div className="mt-5">
                    <div className="flex justify-center">
                        <img src={Page404Image} className="w-3/4 sm:w-2/3 h-auto" alt="Doctor" />
                    </div>
                    <div className="flex justify-center">
                        <button className={`linear mt-10 w-[150px] rounded-xl bg-brand-500 py-[12px] text-sm font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
                            onClick={() => {
                                navigate("/admin/wizard");
                            }}>
                            Go to Wizard
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Page404;