import React from 'react';
import InputField from 'components/fields/InputField'
import Card from "components/card";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { Snackbar } from '@mui/material';
import { SnackbarState, UserPasswordChange } from 'models/user-models';
import { passwordChanged, updatetDataById } from 'api';
import CircularProgress from "@mui/material/CircularProgress";
import { getUser } from 'auth/Authentication';
import { useNavigate } from 'react-router-dom';
import AppSnackbar from 'components/snakbar';


type Severity = "success" | "info" | "warning" | "error";

interface StatusState {
    message: string;
    severity: Severity;
}



const ChangePassword = () => {
    const navigate = useNavigate()
    const [localUser,setLocalUser] = React.useState(null)
    const [isExpired,setIsExpired] = React.useState(null)
    const [password, setPassword] = React.useState<UserPasswordChange>({} as UserPasswordChange);
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [isSuccess, setIsSuccess] = React.useState<boolean>(false);
    const [status, setStatus] = React.useState<StatusState>({
        message: "",
        severity: "success"
    });
    const [message, setMessage] = React.useState<String>("")
    const [snackbar, setSnackbar] = React.useState<SnackbarState>({
        open: false,
        message: "",
        severity: "info",
      });

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') { return; }
        setOpen(false);
    };
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword({ ...password, [e.target.name]: e.target.value });
    }
    const handleSubmit = async () => {

        if(isExpired || localUser.package_name === null){
            // localStorage.setItem("user",JSON.stringify())
            
            setSnackbar({ open: true, message: "Your trial has ended. Please renew your subscription.", severity: 'error' });
            await    updatetDataById("Users",{
                package_name: null,
                phone_number:null,
                session_id:null,
                expiryTime:null,
                
            },localUser._id)
            const updatedUser = localUser
      
      updatedUser.package_name = null;
      updatedUser.phone_number =null;
      updatedUser.session_id =null;
      updatedUser.expiryTime =null;
      updatedUser.subscription="inactive";
      updatedUser.subscriptionOwner=false;
      
      
      localStorage.setItem('user', JSON.stringify(updatedUser));
      return
        }



        
        setLoading(true)
        if (password.newPassword !== password.confirmNewPassword) {
            setOpen(true);
            setIsSuccess(false)
            setStatus((prevState) => ({
                message: "New Password and Confirm New Password must be same",
                severity: "info"
            }));
            setLoading(false)
            return
        }
        try {
            const res = await passwordChanged(password);
            if (res && res.message !== "incorrect_password") {
                setIsSuccess(true)
                setLoading(false)
                setOpen(true)
                setStatus((prevState) => ({
                    message: "Password Updated Successfully",
                    severity: "success"
                }));
            }
            else {
                setIsSuccess(false)
                setLoading(false)
                setOpen(true)
                setStatus((prevState) => ({
                    message: "Incorrect Password ",
                    severity: "warning"
                }));
            }

        } catch (e) {
            setLoading(false)
            setIsSuccess(false)
            setOpen(true)
            setStatus((prevState) => ({
                message: "Incorrect Password ",
                severity: "error"
            }));
        }
    }





    React.useEffect(() => {
    
        const fetchUser = async () => {
            const user = await getUser();  // Fetch or retrieve the user explicitly
            
            setLocalUser(user)
            if (user && user.expiryTime) {
                const expirationDate = new Date(user.expiryTime); // Convert to Date
                const currentDate = new Date(); // Current date
    
      
    
                if (currentDate > expirationDate) {
                    
                    setIsExpired(true);
                    // Redirect or notify the user
                } else {
                    
                    setIsExpired(false);
                    // Allow user to continue
                }
            } else {
                
            }
        };
    
        fetchUser();
    }, [navigate]); // Only include `navigate` if necessary
    


    return (
        <div className="mt-3">
            <Card extra={"w-full sm:overflow-auto px-8 mt-5 py-10 m-auto"}>
                <div className="flex flex-wrap justify-center mt-9 ">
                    <div className='w-[55%]'>
                        <InputField
                            variant="auth"
                            extra="mb-3"
                            label="Your Password"
                            placeholder="Password"
                            id="password"
                            type="text"
                            name="currentPassword"
                            value={password.currentPassword}
                            onChange={onChange}
                            importantField
                        />
                    </div> </div>
                <div className="flex flex-wrap    justify-center ">
                    <div className='w-[55%]'>
                        <InputField
                            variant="auth"
                            extra="mb-3"
                            label="New Password"
                            placeholder="New Password"
                            id="password"
                            type="text"
                            name="newPassword"
                            value={password.newPassword}
                            onChange={onChange}
                            importantField
                        />
                    </div> </div>
                <div className="flex flex-wrap    justify-center ">
                    <div className='w-[55%]'>
                        <InputField
                            variant="auth"
                            extra="mb-3"
                            label="Confirm New Password"
                            placeholder="Confirm New Password"
                            id="password"
                            type="text"
                            name="confirmNewPassword"
                            value={password.confirmNewPassword}
                            onChange={onChange}
                            importantField
                        />
                    </div> </div>

                <button onClick={handleSubmit} className={`linear mt-10 w-[50%] self-center rounded-xl bg-brand-500 py-[12px] text-sm font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
                >
                    {loading ? <CircularProgress size={24} color="inherit" /> : "Save changes"}
                </button>
            </Card>
            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity={status.severity}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {status.message}
                </Alert>
            </Snackbar>

            <AppSnackbar
        isOpen={snackbar.open}
        title={snackbar.message}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        severity={snackbar.severity}
      />

        </div>
    )
}

export default ChangePassword