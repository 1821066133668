import React from "react";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import routes from "routes";
import { getUser, isAuthenticated } from "auth/Authentication";
import { handleRouteRestriction } from "utils/general-utils";
import AdminAccess from "../../usefulComponents/adminAccess";
import Page404 from "usefulComponents/404";
// import Footer from "components/footer/Footer";

export default function Admin(props: { [x: string]: any }) {
  const navigate = useNavigate();
  const { ...rest } = props;
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [currentRoute, setCurrentRoute] = React.useState("Main Dashboard");
  const [path, setPath] = React.useState("")
  const user = getUser()

  React.useEffect(() => {
    if (!(isAuthenticated())) {
      navigate("/auth/sign-in");
    }
  }, [])

  React.useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);

  React.useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);

  const getActiveRoute = (routes: RoutesType[]): string | boolean => {
    let activeRoute = "Main Dashboard";

    for (let i = 0; i < routes.length; i++) {
      const { layout, path, name } = routes[i];
      const targetPath = `${layout}/${path.split("/:")[0]}`; // Extracting the path without :id
      setPath(routes[i].path)

      const parts = location.pathname.split("/");
      const wrongPathCheck = parts[parts.length - 1];

      if (routes.findIndex((x) => (x.path === wrongPathCheck)) === -1) {
        navigate("/auth/404");
        break;
      }

      if (window.location.href.indexOf(routes[i].layout + "/" + routes[i].path) !== -1) {
        setCurrentRoute(routes[i].name);
      }

      if (window.location.href.includes(targetPath)) {
        setCurrentRoute(name);
        break; // Exit the loop once the current route is set
      }
    }
    return activeRoute;
  };

  const getActiveNavbar = (routes: RoutesType[]): string | boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };

  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (handleRouteRestriction(user?.type, prop.path) ?
          <Route path={`/${prop.path}`} element={prop.component} key={key} /> :
          <Route path={`/${prop.path}`} element={<AdminAccess />} key={key} />);
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = "ltr";
  return (
    isAuthenticated() && <div className="flex h-full w-full">
      <Sidebar open={open} onClose={() => setOpen(false)} />
      {/* Navbar & Main Content */}
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
        {/* Main Content */}
        <main className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`} >
          {/* Routes */}
          <div className="h-full">
            <Navbar
              onOpenSidenav={() => setOpen(true)}
              brandText={currentRoute}
              path={path}
              secondary={getActiveNavbar(routes)}
              {...rest}
            />
            <div className="pt-5s mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2">
              <Routes>
                {getRoutes(routes)}
                <Route path="/" element={<Navigate to="/admin/dashboard" replace />} />
              </Routes>
            </div>
            <div className="p-3">
              {/* <Footer /> */}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}