import { User } from "models/user-models";

export const isAuthenticated = () => {
    return localStorage.getItem('token') !== null;
};

export const saveToken = (token: string) => {
    // const expirationTime = new Date().getTime() + 10 * 24 * 60 * 60 * 1000; // 10 days in milliseconds
    const expirationTime = new Date().getTime() + 1 * 60 * 1000; // 1 minute in millisecond
    localStorage.setItem('token', token);
    localStorage.setItem('expirationTime', JSON.stringify(expirationTime));
};

export const getToken = () => {
    return localStorage.getItem('token');
};

export const saveUser = (user: User) => {
    localStorage.setItem('user', JSON.stringify(user));
};
export const getUser = () => {
    const userJSON = localStorage.getItem('user');
    return userJSON ? JSON.parse(userJSON) : null;
};

export const removeToken = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
};
