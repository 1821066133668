import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IoIosCloseCircle } from "react-icons/io";
import { TextField } from '@mui/material';
import { fetchData, logoutUser, subscriptionCodeVerification } from 'api';
import { getUser, removeToken } from 'auth/Authentication';
import { useNavigate } from 'react-router-dom';
import AppSnackbar from 'components/snakbar';
import { SnackbarState } from 'models/user-models';


const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius:"5px",
  boxShadow: 24,
  p: 4,
  paddingTop:2
};

interface AlreadyLoggedInModalProps {
    openSubscriptonCode: boolean;
    setOpenSubscriptionCode: React.Dispatch<React.SetStateAction<boolean>>;
  
    subscriptionCode:string;
    setSubscriptionCode:React.Dispatch<React.SetStateAction<string>>

}
const SubscriptionCode: React.FC<AlreadyLoggedInModalProps> = ( {openSubscriptonCode, setOpenSubscriptionCode,setSubscriptionCode,subscriptionCode}) => {
  // const [open, setOpen] = React.useState(false);
  const navigate = useNavigate()
  const [snackbar, setSnackbar] = React.useState<SnackbarState>({
    open: false,
    message: "",
    severity: "info",
  });
  const handleOpen = () => setOpenSubscriptionCode(true);
  const handleClose = () => setOpenSubscriptionCode(false);
const [localUser,setLocalUser] = React.useState(null)
  const handleSubmit = async () => {
    
try {
    
    const response =  await  subscriptionCodeVerification({subscriptionCode:subscriptionCode,email:localUser.email})
    
    if(response.data.subscription){
        handleClose()
        
    


        const handleLogout = async () => {
          // Fetch the local user details
          const local_User = await getUser();  
          
          // Set the local user state (if necessary)
          setLocalUser(local_User);
      
          // Fetch users data
          const users = await fetchData("Users");
      
          if (local_User) {
              // Find the subscribed user from the fetched users
              // const subscribedUser = users.data.filter((e: any) => e.email === local_User.email);
              const subscribedUser = users.data ? users.data.filter((e: any) => e.email === local_User.email) : [];

              
      
              // Ensure localUser is not null and subscribedUser has at least one entry
              if (subscribedUser.length > 0) {
                  const currentUser = local_User ; // Create a copy of localUser


                  currentUser.subscriptionOwnerId=subscribedUser[0]._id;
                                    currentUser.package_name=subscribedUser[0].package_name;
                                    currentUser.randomCode=subscribedUser[0].randomCode;
                                    currentUser.expiryTime=subscribedUser[0].expiryTime
      
                  

                  localStorage.setItem("user", JSON.stringify(currentUser));
                  setSnackbar({ open: true, message: "Congrats Subsciption Unlocked", severity: 'success' });
              } else {
                  
              }
          } else {
              
          }
      };



            // setTimeout(()=>{
                handleLogout()

            // },1000)
    }
    if(!response.data.subscription){
        alert("invalid code")
    }
        handleClose()
} catch (error:any) {
    console.log("subscriptionCode error",error)
    if(error.message.endsWith(400)){

        if(error.response.data.subscription === false){

            if(error.response.data.members ){
                alert("Members are full")
                handleClose()
                return
            }
            alert("Invalid code")
            handleClose()
            
        }
    }
}
  }
  React.useEffect(()=>{
    const user = getUser()
    setLocalUser(user)
  },[])

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={openSubscriptonCode}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
        <Box sx={style}>
<h3 className='font-medium text-lg'>Enter a 8 Digit Code</h3>
        <TextField  sx={{mt:"15px"}} value={subscriptionCode} onChange={(e)=>setSubscriptionCode(e.target.value)}  fullWidth id="outlined-basic" label="Enter a 8-digit code" size='small' variant="outlined"   inputProps={{ maxLength: 8 }} />
        <Button onClick={handleSubmit} fullWidth sx={{bgcolor:"#6366F1",mt:"15px","&:hover":{bgcolor:"#4F46E5"}}} size='large' variant='contained'>Submit</Button>
        </Box>
        </>
      </Modal>
      <AppSnackbar
        isOpen={snackbar.open}
        title={snackbar.message}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        severity={snackbar.severity}
      />
    </div>
  );
}

export default SubscriptionCode;