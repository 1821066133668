import { Box } from "@mui/material";
import { WizardProps, formatReportData } from "models/wizard/wizard-modals";
import React from "react";
import { MdCheck } from "react-icons/md";

const WizardReport: React.FC<WizardProps> = ({ userReport, duration, durationType, complaintRemark, impressionRemark, examinationRemark,subComplaintRemark,data }) => {
    const [textCopied, setTextCopied] = React.useState(false)

    const handleCopyToClipboard = async () => {
        try {
            const report = { ...userReport, duration: duration, durationType: durationType,complaintRemark,subComplaintRemark,impressionRemark,examinationRemark }
            const formattedData = formatReportData(report);
            await navigator.clipboard.writeText(formattedData);
            setTextCopied(true)
            setTimeout(() => {
                setTextCopied(false);
            }, 5000);
        } catch (err) {
            console.error('Failed to copy: ', err);

        }
    };

    const mergeWithoutDuplicates = (array1:any, array2:any) => {
        const set = new Set(array1.map((item:any) => item.description));
        const filteredArray2 = array2.filter((item:any) => !set.has(item.description));
        return [...array1, ...filteredArray2];
    };
    
    const mergedData = mergeWithoutDuplicates(data, userReport.examinations);
    
    return (
        <>
            <h1 className="text-[26px] text-black font-semibold w-[100%] sm:w-[70%] text-left mb-5">Report</h1>
            <hr />

            <div className="mt-3" style={{ height: '80%', overflowY: 'scroll' }}>
                <h1 className="mt-3 text-[20px] text-black font-semibold w-[100%] sm:w-[100%] text-left">Complaint:</h1>
                <p className="text-[14px] text-black w-[100%] sm:w-[100%]">
                    {userReport.compliants?.map((complaint, index) => {
                        return (
                            <React.Fragment key={index}>
                                {index === 0 ? "Patient with History of " : ""}
                                {`${complaint.description} for ${duration} ${durationType}`}

                            </React.Fragment>
                        );
                    })}
                    <br />
                    {complaintRemark && <>
                        <strong>Remarks</strong> : <React.Fragment>
                            {complaintRemark}
                        </React.Fragment>
                    </>}


                </p>
                <p className="text-[14px] text-black w-[100%] sm:w-[100%]">
                    {userReport.subComplaints?.map((sub_complaint, index) => {
                        const isLast = index === userReport.subComplaints.length - 1;
                        const separator = isLast ? '' : (index === userReport.subComplaints.length - 2 ? ' and ' : ', ');
                        return (
                            <React.Fragment key={index}>
                                {index === 0 ? "Patient also has " : ""}
                                {`${sub_complaint.description}`}
                                {separator}
                            </React.Fragment>
                        );
                    })}
  <br />
                    {subComplaintRemark && <>
                        <strong>subComplaintRemark</strong> : <React.Fragment>
                            {subComplaintRemark}
                        </React.Fragment>
                    </>}

                </p>


                {mergedData.length > 0 && (
          <>
            <h1 className="mt-3 text-[20px] text-black font-semibold w-[100%] sm:w-[100%] text-left">
              On Examination:
            </h1>
            <p className="text-[14px] text-black w-[100%] sm:w-[100%]">
              {mergedData.map((item, i) => {
            
                return(

                
                <React.Fragment key={i}>
                    
                    <Box display={"flex"} alignItems={"center"} gap={"5px"}>

                  <h1>{`${(item as any).description || ""} `}</h1>
                {(item as any).value? (
                      <Box display={"flex"} alignItems={"center"} gap={"5px"}>
                      <h5 style={{fontWeight:"bold"}}>{` ${(item as any)?.value?.inputValue} ${(item as any)?.value?.unitValue}`}</h5>
                      
                      </Box>
                ):""} 
                    
                  
                
                    </Box>
                  <Box display={"flex"} alignItems={"center"} gap={"5px"}>
             

                  </Box>
                  {(item as any).scroll_downs && (item as any).scroll_downs.length > 0 && (
                    <>
                      {(item as any).scroll_downs.map((scroll_down:any, index:any) => (
                        <React.Fragment key={index}>
                          {scroll_down}
                          {index < (item as any).scroll_downs.length - 1 ? ", " : ""}
                        </React.Fragment>
                      ))}
                      <br />
                    </>
                  )}
                </React.Fragment>
                )
              })}
              {examinationRemark && (
                <>
                  <strong>Remarks</strong> : <React.Fragment>{examinationRemark}</React.Fragment>
                </>
              )}
            </p>
          </>
        )}

                {userReport.impressions && userReport.impressions.length > 0 && <>
                    <h1 className="mt-3 text-[20px] text-black font-semibold w-[100%] sm:w-[100%] text-left">Impression:</h1>
                    <p className="text-[14px] text-black w-[100%] sm:w-[100%]">
                        {userReport.impressions?.map((impression) => {
                            return (
                                <React.Fragment>
                                    {impression.description}
                                    <br />
                                </React.Fragment>
                            );
                        })}
                      
                        {impressionRemark && <>
                            <strong>Remarks</strong> : <React.Fragment>
                                {impressionRemark}
                            </React.Fragment>
                        </>}
                    </p>
                </>}

                {userReport.treatment_plans && userReport.treatment_plans.length > 0 && <>
                    <h1 className="mt-3 text-[20px] text-black font-semibold w-[100%] sm:w-[100%] text-left">Treatment Plan:</h1>
                    <p className="text-[14px] text-black w-[100%] sm:w-[100%]">
                        {userReport.treatment_plans?.map((treatment) => {
                            return (
                                <React.Fragment>
                                    {treatment.description}
                                    <br />
                                </React.Fragment>
                            );
                        })}</p>
                </>}
            </div>

            <button onClick={handleCopyToClipboard} className={`${textCopied ? "w-fit finishButton items-left" : "w-40 items-center justify-center"} flex  text-sm hover:cursor-pointer bg-lightPrimary p-3 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10" linear rounded-lg font-bold transition duration-200 mr-3 mt-3`}>
                {textCopied ? <>
                    Copied to Clipboard < MdCheck style={{ width: 20, height: "auto" }} className="ml-3" />
                </> : "Copy to Clipboard"}
            </button>


        </>
    );
}

export default WizardReport