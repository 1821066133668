import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IoIosCloseCircle } from "react-icons/io";


const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius:"5px",
  boxShadow: 24,
  p: 4,
  paddingTop:2
};

interface AlreadyLoggedInModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  
  
  setShowOTPInput:React.Dispatch<React.SetStateAction<boolean>>;
  userCredentials:any;
  loginUser:any;
  setUserCredentials:any;
  setPushed:any;
}
const AlreadyLoggedInModal: React.FC<AlreadyLoggedInModalProps> = ({setUserCredentials,loginUser,userCredentials, open, setOpen, setShowOTPInput,setPushed }) => {
  // const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleContinue =() => {
    
    setPushed(true)
    // loginUser()
    setOpen(false)
  }

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
        <Box sx={style}>
        <Box color={"#d85b5b"} sx={{cursor:"pointer","&:hover":{color:"red"}}}   display={"flex"} justifyContent={"end"} >
        <IoIosCloseCircle size={"25px"}  onClick={handleClose}/>
          </Box>
      
          <Typography id="modal-modal-title" variant="h5" component="h2">
           Already Logged In
          </Typography>
          <Box my={4}>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            You are already logged in some other device.
          </Typography>
          <Typography id="modal-modal-description" sx={{  }}>
            Would you like to logout from that device.
          </Typography>
          </Box>
          <Box display={"flex"} justifyContent={"space-between"} mt={"20px"}>
          <Button onClick={handleClose} sx={{borderColor:"#6366F1",color:"#6366F1","&:hover":{borderColor:"#4F46E5",color:"#4F46E5"}}}   variant="outlined">Cancel</Button>
          <Button onClick={handleContinue} sx={{bgcolor:"#6366F1","&:hover":{bgcolor:"#4F46E5"}}} variant="contained">Continue</Button>
</Box>
            
        </Box>
        </>
      </Modal>
    </div>
  );
}

export default AlreadyLoggedInModal;