import React, { useEffect, useState } from 'react'
import Card from "components/card";
import { contactUs, updatetDataById} from 'api';

import { Alert, Snackbar } from '@mui/material';
import { ContactUsResponseModel } from 'models/contactus-models';
import AppSnackbar from 'components/snakbar';
import { SnackbarState } from 'models/user-models';
import { useNavigate } from 'react-router-dom';
import { getUser } from 'auth/Authentication';

const ContactUs = () => {
    const navigate = useNavigate()
    const [open, setOpen] = React.useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [dataObj, setDataObj] = React.useState<ContactUsResponseModel>({
        email: "",
        regarding: '',
        message: '',
    });
    const [errors, setErrors] = React.useState<Partial<ContactUsResponseModel>>({});
    const [snackbar, setSnackbar] = React.useState<SnackbarState>({
        open: false,
        message: '',
        severity: 'info', 
    });
    const [isSubscribed,setIsSubscribed] = useState()
    const [localUser,setLocalUser] = useState(null)
    const [isExpired,setIsExpired] = useState(null)
    const validateFields = () => {
        let isValid = true;
        let errors: Partial<ContactUsResponseModel> = {};

        // Email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!dataObj.email || !emailRegex.test(dataObj.email)) {
            errors.email = 'Please enter a valid email address.';
            isValid = false;
        }

        // Regarding validation
        if (!dataObj.regarding) {
            errors.regarding = 'Please select an option.';
            isValid = false;
        }

        setErrors(errors);
        return isValid;
    };

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };



    const onSubmit = async () => {
        try {


            const user = getUser() 
            const hasTrialExpired = (expirationTimeUTC:any) => {
                const currentTimeUTC = new Date().toISOString(); // Get current time in UTC
                return currentTimeUTC > expirationTimeUTC; // Compare current time with expiration time
              };
              
             
              
            
            if (user.type === "2" && !isChecked && hasTrialExpired(user.trial_expiry) && !user.hasOwnProperty("expiryTime")) {
              // setIsChecked(true); 
                // alert("Your trial has expired. Please switch to the subscription mode.");
                setSnackbar({ open: true, message: "Your trial has expired. Please switch to the subscription mode.", severity: 'error' });
                return;
            }

            if(user.type === "2" && (isExpired || localUser.package_name === null)){
                // localStorage.setItem("user",JSON.stringify())
                
                setSnackbar({ open: true, message: "Your trial has ended. Please renew your subscription.", severity: 'error' });
                await    updatetDataById("Users",{
                    package_name: null,
                    phone_number:null,
                    session_id:null,
                    expiryTime:null,
                    randomCode:null,
                    subscriptionOwnerId:null,
                    subscriptionOwner:null,
                    
                },localUser._id)
                const updatedUser = localUser
          
          updatedUser.package_name = null;
          updatedUser.phone_number =null;
          updatedUser.session_id =null;
          updatedUser.expiryTime =null;
          updatedUser.subscription="inactive";
          updatedUser.subscriptionOwner=false;
          updatedUser.subscriptionOwnerId=null;
          updatedUser.randomCode=null;
          
          
          
          localStorage.setItem('user', JSON.stringify(updatedUser));
          return
            }








//             if(localUser.package_name == null){
//                 alert("Subscription trial has been expired")
//                 return
//             }
//             if(isExpired){
//                 // localStorage.setItem("user",JSON.stringify())
//                 alert("expired")
//                 const updatedUser = localUser

// updatedUser.package_name = null;
// updatedUser.phone_number =null;
// updatedUser.session_id =null;
// updatedUser.expiryTime =null;
// updatedUser.subscription="inactive";
// console.log("updatedUser+++++++",updatedUser)

// localStorage.setItem('user', JSON.stringify(updatedUser));
// return
//             }
            const res = await contactUs(dataObj)

            if (res) {
                
                setOpen(true)
                setDataObj({
                    email: "",
                    regarding: '',
                    message: '',
                })
            }
        }
        catch (error:any) {
            const alreadyLoggedIn = error.message.endsWith("401")
            if(alreadyLoggedIn){
                setSnackbar({ open: true, message: "logged in from some other device", severity: 'error' });
                setTimeout(()=>{
                    navigate('/auth/sign-in'); 
                },3000)
            }
        }
    }


    // useEffect(() => {
    //     const isTokenExpired = () => {
    //         const expirationTime = localStorage.getItem('expirationTime');
    //         if (!expirationTime) return true;
    //         return new Date().getTime() > Number(expirationTime);
    //       };
    //       console.log("isTokenExpired++++++",isTokenExpired())
          
    //       if (isTokenExpired()) {
    //         localStorage.removeItem('expirationTime');
    //      navigate('/auth/sign-in'); 
    //       } 
    //   }, [navigate]);


    useEffect(() => {
        const fetchUser = async () => {
            const user = await getUser();  // Fetch or retrieve the user explicitly
        
            setLocalUser(user)
            if (user && user.expiryTime) {
                const expirationDate = new Date(user.expiryTime); // Convert to Date
                const currentDate = new Date(); // Current date
    
            
    
                if (currentDate > expirationDate) {
                 
                    setIsExpired(true);
                    // Redirect or notify the user
                } else {
                
                    setIsExpired(false);
                    // Allow user to continue
                }
            } else {
                
            }
        };
    
        fetchUser();
    }, [navigate]); // Only include `navigate` if necessary






    return (
        <div className="mt-3">
            <Card extra={"w-full h-full sm:overflow-auto px-8 mt-5 py-5"}>
                <div className="py-12">
                    <div className=" ">
                        <label htmlFor="nameOrEmail" className="font-poppins self-center text-sm text-navy-700 dark:text-white ml-1.5 font-medium w-[7%]">
                            Email *
                        </label>
                        <span className=' w-[93%]'>
                            <input
                                id="nameOrEmail"
                                value={dataObj.email}
                                className=" flex w-[100%] items-center justify-center rounded-md border  bg-white/0 p-3 text-sm outline-none"
                                placeholder="Enter your Username or email"
                                onChange={(e) => setDataObj({ ...dataObj, email: e.target.value })}
                            />
                            {errors.email && <span className="text-red-500 text-sm">{errors.email}</span>}
                        </span>

                    </div>

                    <div className="   mt-4">
                        <label htmlFor="regarding" className="font-poppins text-sm self-center text-navy-700 dark:text-white ml-1.5 font-medium w-[7%]">
                            Regarding *
                        </label>
                        <span className=' w-[93%]'>
                            <select
                                id="regarding"

                                value={dataObj.regarding}
                                className="mt-2 flex w-[100%] items-center rounded-md border justify-center  bg-white/0 p-3 text-sm outline-none"
                                onChange={(e) => setDataObj({ ...dataObj, regarding: e.target.value })}

                            >
                                <option value="">Select an option</option>
                                <option value="General Inquiry">General Inquiry</option>
                                <option value="Technical Issue">Technical Issue</option>
                                <option value="Billing Issue">Billing Issue</option>
                            </select>
                            {errors.regarding && <span className="text-red-500 text-sm">{errors.regarding}</span>}
                        </span>
                    </div>

                    <div className='mt-5'></div>
                    <label htmlFor="desc" className={`font-poppins text-sm text-navy-700 dark:text-white ml-1.5 font-medium`} >
                        Message *
                    </label>
                    <textarea id="desc" value={dataObj.message} rows={6} className="mt-2 flex w-[100%] items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none resize-none" placeholder="Unlimited placeholder tests" onChange={(e) => {
                        setDataObj({ ...dataObj, "message": e.target.value })
                    }} />

                    <button className={`linear mt-6 w-[120px] rounded-xl bg-brand-500 py-[10px] text-sm font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`} onClick={() => {
                        if (!validateFields()) {
                            
                            setSnackbar({ open: true, message: "Please fill all fields", severity: 'error' });
                            return;
                        }
                        onSubmit()
                    }}>
                        Submit
                    </button>

                </div>
            </Card>
            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity="info"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                   Thank you for contacting us.Please check your email !
                </Alert>
            </Snackbar>
            <AppSnackbar
                isOpen={snackbar.open}
                title={snackbar.message}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                severity={snackbar.severity}
            />
        </div>
    )
}

export default ContactUs;