import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import InputField from 'components/fields/InputField';
import Card from 'components/card';
import { SnackbarState, User, UserRegister, UserUpdate } from 'models/user-models';
import { authenticateOtp, fetchData, resendOtp, sendSignUpOtp, updatetDataById } from 'api';
import { users } from 'api/collection_names';
import AppSnackbar from 'components/snakbar';
import OTP from 'components/otp';
import CircularProgress from '@mui/material/CircularProgress';
import { MdArrowBack } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { getUser } from 'auth/Authentication';

const ProfileOverview = () => {
  const navigate = useNavigate()
  const [isChecked, setIsChecked] = useState(false);
  const [localUser,setLocalUser] = useState(null)
      const [isExpired,setIsExpired] = useState(null)
  const [user, setUser] = React.useState<UserUpdate>({} as UserUpdate);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [showOTPInput, setShowOTPInput] = React.useState<boolean>(false);
  const [otp, setOtp] = React.useState<string>('');
  const [snackbarOpen, setSnackbarOpen] = React.useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = React.useState<'success' | 'error'>('success');
  const [snackbar, setSnackbar] = useState<SnackbarState>({
    open: false,
    message: '',
    severity: 'info', 
});
  // React.useEffect(() => {
  //   const user = localStorage.getItem('user');
  //   const admin = JSON.parse(user) as User;
  //   setUser({
  //     address: admin.address,
  //     contact: admin.contact ? admin.contact.toString() : '',
  //     dob: admin.dob,
  //     email: admin.email,
  //     fname: admin.fname,
  //     _id: admin._id ? admin._id.toString() : '',
  //     lname: admin.lname,
  //   });
  // }, []);



  React.useEffect(() => {
    const localUser = localStorage.getItem('user');
    if (localUser) {
      const admin = JSON.parse(localUser) as User;
      fetchData(users).then((res) => {
        if (res?.data) {
          const data = res.data.find((e: any) => e._id === admin._id);
          if (data) {
            setUser({
              address: data.address,
              contact: data.contact ? data.contact.toString() : '',
              dob: new Date(data.dob), 
              email: data.email,
              fname: data.fname,
              _id: data._id.toString(),
              lname: data.lname,
            });
            localStorage.setItem("user",JSON.stringify(data))
          } else {
            console.error('User data not found');
          }
        } else {
          console.error('Error fetching data', res);
        }
      }).catch((error) => {
        console.error('Error fetching user data:', error);
      });
    }
  }, []);

  const handleChange = (date: Date | null) => {
    setUser({ ...user, dob: date });
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const validateFields = () => {
    const { fname, lname, contact,  email, address } = user;
    if (!fname || !lname || !contact ||  !email || !address) {
      setSnackbarMessage('Please fill all the fields');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return false;
    }
    return true;
  };





  const updateAdmin = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    
    if (token) {
      try {
        const res = await updatetDataById(users, user, user._id);
        
        if (res.data) {
          
          
          setLoading(false);
          setSnackbarMessage('User Updated Successfully');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
        } else {
          setLoading(false);
          setSnackbarMessage('Failed to update user');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        }
        
      } catch (error:any) {
        const alreadyLoggedIn = error.message.endsWith("401")
        if(alreadyLoggedIn){
            setSnackbar({ open: true, message: "logged in from some other device", severity: 'error' });
            setTimeout(()=>{
                navigate('/auth/sign-in'); 
            },3000)
        }
    }
    }
  };

  const handleOTPComplete = async (otp: string) => {
    setOtp(otp);
   
  };

  const handleResendOTP = async () => {
    const data = {
      email: user.email,
    };
    try {
      await resendOtp(data);
      setSnackbarMessage('OTP resent successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error resending OTP:', error);
      setSnackbarMessage('Error resending OTP');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleVerifyOTP = async () => {
    try {
      const data = {
        email: user.email,
        otp: otp,
      };
      const res = await authenticateOtp(data);
      if (res?.success) {
        await updateAdmin();
        setShowOTPInput(false); // Hide OTP input after successful verification
      } else {
        setSnackbarMessage('Invalid OTP');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      setSnackbarMessage('Error verifying OTP');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSendOTP = async (email: string) => {
    try {
      await sendSignUpOtp(email);
      setSnackbarMessage('OTP sent successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error sending OTP:', error);
      setSnackbarMessage('Error sending OTP');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSaveChanges =async () => {
    const user = getUser() 
    const hasTrialExpired = (expirationTimeUTC:any) => {
        const currentTimeUTC = new Date().toISOString(); // Get current time in UTC
        return currentTimeUTC > expirationTimeUTC; // Compare current time with expiration time
      };
      
     
      
    
    if (user.type === "2" && !isChecked && hasTrialExpired(user.trial_expiry) && !user.hasOwnProperty("expiryTime")) {
      // setIsChecked(true); 
        // alert("Your trial has expired. Please switch to the subscription mode.");
        setSnackbar({ open: true, message: "Your trial has expired. Please switch to the subscription mode.", severity: 'error' });
        return;
    }

    if(user.type === "2" && (isExpired || localUser.package_name === null)){
        // localStorage.setItem("user",JSON.stringify())
        
        setSnackbar({ open: true, message: "Your trial has ended. Please renew your subscription.", severity: 'error' });
        await    updatetDataById("Users",{
            package_name: null,
            phone_number:null,
            session_id:null,
            expiryTime:null,
            randomCode:null,
            subscriptionOwnerId:null,
            subscriptionOwner:null,
            
        },localUser._id)
        const updatedUser = localUser
  
  updatedUser.package_name = null;
  updatedUser.phone_number =null;
  updatedUser.session_id =null;
  updatedUser.expiryTime =null;
  updatedUser.subscription="inactive";
  updatedUser.subscriptionOwner=false;
  updatedUser.subscriptionOwnerId=null;
  updatedUser.randomCode=null;
  
  
  
  localStorage.setItem('user', JSON.stringify(updatedUser));
  return
    }

    if (!validateFields()) {
      return;
    }

    setLoading(true)
    
    const old_user = JSON.parse(localStorage.getItem('user'));
    if (user.email !== old_user.email) {
      setLoading(false)
      handleSendOTP(user.email);
      setShowOTPInput(true);
    } else {
setTimeout(()=>{
  updateAdmin();
  setLoading(false)
},1000)
    }
  };





  useEffect(() => {
    const fetchUser = async () => {
        const user = await getUser();  // Fetch or retrieve the user explicitly
        
        setLocalUser(user)
        if (user && user.expiryTime) {
            const expirationDate = new Date(user.expiryTime); // Convert to Date
            const currentDate = new Date(); // Current date

  

            if (currentDate > expirationDate) {
                
                setIsExpired(true);
                // Redirect or notify the user
            } else {
                
                setIsExpired(false);
                // Allow user to continue
            }
        } else {
            
        }
    };

    fetchUser();
}, [navigate]); 

  return (
    <div className='mt-3'>
      {showOTPInput ? (
        <div className=''>
          <button onClick={() => setShowOTPInput(false)} className='text-lg dark:text-white cursor-pointer flex self-center' title='Back'>
            <MdArrowBack style={{ margin: 5 }} /> Back
          </button>
          <div className='mt-[10%] w-[30%] m-auto'>
            <OTP title='Verify OTP' onVerifyOTP={handleVerifyOTP} descriptionMessage='Enter OTP to update email!' onComplete={handleOTPComplete} onResendOTP={handleResendOTP} />
            <button
              className='linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200'
              onClick={handleVerifyOTP}
            >
              {loading ? <CircularProgress size={24} color='inherit' /> : 'Verify OTP'}
            </button>
          </div>
        </div>
      ) : (
        <>
          <Card extra={'w-full h-full sm:overflow-auto px-8 mt-5 py-10'}>
            <div className='flex justify-between'>
              <div className='w-[49%]'>
                <InputField
                  variant='auth'
                  extra='mb-3'
                  label='First Name'
                  placeholder='John'
                  id='fname'
                  name='fname'
                  type='text'
                  value={user.fname}
                  onChange={onChange}
                  importantField
                />
              </div>
              <div className='w-[49%]'>
                <InputField
                  variant='auth'
                  extra='mb-3'
                  label='Last Name'
                  placeholder='Doe'
                  id='lastName'
                  type='text'
                  name='lname'
                  value={user.lname}
                  onChange={onChange}
                  importantField
                />
              </div>
            </div>
            <div className='flex flex-wrap justify-between'>
              <div className='w-[49%]'>
                <InputField
                  variant='auth'
                  extra='mb-3'
                  label='Phone Number'
                  placeholder='03158897232'
                  id='phoneNumber'
                  type='text'
                  name='contact'
                  value={user.contact ? user.contact.toString() : ''}
                  onChange={onChange}
                  importantField
                />
              </div>
              <div className='w-[49%] flex flex-col'>
                <label htmlFor='date' className={`font-poppins text-sm text-navy-700 dark:text-white ml-1.5 font-medium`}>
                  Date of birth
                </label>
                <DatePicker
                  className='mt-3 flex h-12 w-[500px] items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none'
                  selected={user.dob}
                  onChange={handleChange}
                  placeholderText='02/16/2024'
                />
              </div>
            </div>
            <InputField
              variant='auth'
              extra='mb-3'
              label='Email Address'
              placeholder='johndoe@gmail.com'
              id='email'
              type='text'
              name='email'
              value={user.email}
              onChange={onChange}
              importantField
            />
            <InputField
              variant='auth'
              extra='mb-3'
              label='Practice Address'
              placeholder='Street 239, House 418 Myung Street, Germany'
              id='address'
              type='text'
              name='address'
              value={user.address}
              onChange={onChange}
              importantField
            />
            <button
              className={`linear mt-10 w-[150px] rounded-xl bg-brand-500 py-[12px] text-sm font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
              onClick={handleSaveChanges}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : "Save changes"}
              
            </button>
          </Card>
          <AppSnackbar
            isOpen={snackbarOpen}
            title={snackbarMessage}
            onClose={() => setSnackbarOpen(false)}
            severity={snackbarSeverity}
          />
               <AppSnackbar
                isOpen={snackbar.open}
                title={snackbar.message}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                severity={snackbar.severity}
            />
        </>
      )}
    </div>
  );
};

export default ProfileOverview;
