
import React, { useState, useRef } from 'react';

interface OTPInputProps {
  title: string
  descriptionMessage: string
  onComplete: (otp: string) => void;
  onResendOTP: () => void;
  onVerifyOTP: () => void;
}

const OTP: React.FC<OTPInputProps> = ({ title, descriptionMessage, onComplete, onResendOTP }) => {
  const [otp, setOTP] = useState<string[]>(new Array(6).fill(''));
  const inputRefs = useRef<(HTMLInputElement | null)[]>(new Array(6).fill(null));

  const handleChange = (index: number, value: string) => {
    const newOTP = [...otp];
    newOTP[index] = value;
    setOTP(newOTP);
    if (value && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }
    if (value && index === 5) {
      onComplete(newOTP.join(''));
    }
  };



  const handleKeyDown = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace' && index > 0 && !otp[index]) {
      inputRefs.current[index - 1]?.focus();
    }
  };
  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const paste = event.clipboardData.getData('text');
    if (paste.length === otp.length) {
      const newOTP = paste.split('');
      setOTP(newOTP);
      inputRefs.current[otp.length - 1]?.focus();
      onComplete(newOTP.join(''));
      event.preventDefault();
    }
  };

  return (
    <div className="flex flex-col items-center">
    <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
      {title}
    </h4>
    <p className="mb-9 ml-1 text-[15px] text-gray-600">
      {descriptionMessage}
    </p>
    <div className="flex justify-center items-center mb-4">
      {otp.map((value, index) => (
        <input
          key={index}
          ref={(ref) => (inputRefs.current[index] = ref)}
          type="text"
          value={value}
          onChange={(e) => handleChange(index, e.target.value)}
          onKeyDown={(e) => handleKeyDown(index, e)}
          onPaste={handlePaste}
          maxLength={1}
          className="border-2 border-gray-300 rounded-lg w-10 h-10 text-center mx-1 text-xl focus:outline-none focus:border-blue-500"
        />
      ))}
    </div>
    <div className="flex justify-center items-center mb-4">
      <button className="mr-4" onClick={onResendOTP}>
        Resend OTP
      </button>
    
    </div>
  </div>
  );
};

export default OTP;