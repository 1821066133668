import React from "react";
import Dropdown from "components/dropdown";
import { AiTwotoneEdit } from "react-icons/ai";
import { BsThreeDots } from "react-icons/bs";
import { AiOutlineShop } from "react-icons/ai";
// import { FiSettings } from "react-icons/fi";
// import { TiLightbulb } from "react-icons/ti";

function CardMenu(props: {
  transparent?: boolean,
  onButtonClick?: () => void,
  setOpenDialog?: React.Dispatch<React.SetStateAction<boolean>>
  onDeleteClick?: () => void,
}) {
  const { transparent, onButtonClick, setOpenDialog,onDeleteClick  } = props;
  const [open, setOpen] = React.useState(false);
  return (
    <Dropdown
      button={
        <button
        style={{ padding: "18px 9px"}}
          onClick={() => setOpen(!open)}
          className={`flex items-center text-xl hover:cursor-pointer ${transparent
            ? "bg-none text-white hover:bg-none active:bg-none"
            : "bg-lightPrimary px-2 py-1 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
            } linear justify-center rounded-lg font-bold transition duration-200 p-10`}
        >
          <BsThreeDots className="h-6 w-6" />
        </button>
      }
      animation={"origin-top-right transition-all duration-300 ease-in-out"}
      classNames={`${transparent ? "top-8" : "top-11"} right-0 w-max`}
      children={
        <div className="z-50 w-max rounded-xl bg-white py-3 px-4 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="hover:text-black flex cursor-pointer items-center gap-2 text-gray-600 hover:font-medium" onClick={() => {
             if (setOpenDialog) {
               setOpenDialog(true);
               onButtonClick();
             }
          }}>
            <span>
              <AiTwotoneEdit />
            </span>
            Edit
          </p>
          <p onClick={() => {
             if (setOpenDialog) {
              //  setOpenDialog(true);
              //  onButtonClick();
              onDeleteClick()
             }
          }} className="hover:text-black mt-2 flex cursor-pointer items-center gap-2 pt-1 text-gray-600 hover:font-medium">
            <span>
              <AiOutlineShop />
            </span>
            Delete
          </p>
        </div>
      }
    />
  );
}

export default CardMenu;
